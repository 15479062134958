import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import store from './store/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";
import AuthLayout from "./layouts/AuthLayout.jsx";
import AdminLayout from "./layouts/AdminLayout.jsx";
import {PrivateRoute} from './PrivateRoute';
// import CetakNota from "./layouts/addon/CetakNota.jsx";

import CetakQrCode from "./layouts/CetakQrCode.jsx";
import Konfirmasi from "./layouts/Konfirmasi.jsx";

import { 
  Result,
  Button
} from 'antd';
import CetakTiket from './layouts/CetakTiket.jsx';

// import { initializeFirebase } from './push-notification';

ReactDOM.render(
  <Provider store={store}>
  	<BrowserRouter>
    	<Switch>
    		<Route path="/auth" component={AuthLayout} />

        <PrivateRoute path="/admin" component={AdminLayout} />
        
        <PrivateRoute exact path="/addon/cetak-qr-code/:id" component={CetakQrCode} />
        <PrivateRoute exact path="/addon/konfirmasi/:id" component={Konfirmasi} />
        <PrivateRoute exact path="/addon/cetak-tiket/:id" component={CetakTiket} />

    		<Route from="/" render={() => <Redirect to="/admin/index"/>}/>{/*live server must off*/}

        <Route path="" render={
            () => 
                <Result
                    status="404"
                    title={
                      <div>
                      404
                      </div>
                    }
                    subTitle={
                      <div>
                        <h1>Maaf, halaman tidak ditemukan</h1><br />
                        <img src={process.env.PUBLIC_URL + "/logo.png"} height="50" /><br /><br />
                        <Link to="/">
                          <Button type="primary">
                            kembali ke halaman utama
                          </Button>
                        </Link>
                      </div>
                    }
                />
        } />
    	</Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// initializeFirebase();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
