import React from "react";
import { Link, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Breadcrumb,
  Card,
  Row,
  Col,
  Avatar,
  Form,
  Input,
  Button,
  Space,
  Select,
  DatePicker,
  message,
  Alert,
  Spin,
} from "antd";
import {
  DiffOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import moment from "moment";

import axios from "axios";
import Config from "../../Config";

import { connect } from "react-redux";
import {
  initHandler,
  ajaxViewHandler,
  ajaxHandler,
  setConfig,
} from "../../store/actions";
import { Helmet } from "react-helmet";

import LocationPicker from "react-location-picker";
import { Editor } from "@tinymce/tinymce-react";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const FormItem = Form.Item;

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 19,
      offset: 5,
    },
    lg: {
      span: 19,
      offset: 5,
    },
  },
};

const configClass = {
  title: "term condition",
  apiUrl: {
    preview: "web/api/artikel/",
  },
  pageUrl: {
    data: "/admin/term-condition/",
  },
  breadCrumb: [
    <span>
      <GlobalOutlined /> Konten
    </span>,
    "term condition",
  ],
};

const config = new Config();

class TermCondition extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      errComponent: false,
      errTitle: "",
      errMsg: "",
      loadingButton: false,

      aksi: "tambah",
      method: "post",
      idLama: "",

      artikelString: "",
      gambar: null,

      preview_gambar: "",

      src: null,
      crop: {
        unit: "%",
        width: 100,
        aspect: 16 / 9,
      },
      gambar_jadi: null,
      type_gambar: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.initHandler();
  }

  componentDidMount() {
    this.preview();
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
      this.setState({
        type_gambar: e.target.files[0].type,
      });
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
      // this.setState({
      // 	gambar_jadi: croppedImageUrl
      // })

      console.log(this.state.gambar_jadi);
    }
  }

  getCroppedImg(image, crop, fileName) {
    var canvas = document.createElement("canvas");
    canvas.setAttribute("id", "gbr-jadi");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    var that = this;

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          that.setState({
            gambar_jadi: blob,
          });
          resolve(this.fileUrl);
        },
        this.state.type_gambar,
        1
      );
    });
  }

  async preview() {
    message.loading({
      content: "Menampilkan data sebelumnya...",
      duration: 0,
      key: "toast",
    });

    this.props
      .ajaxViewHandler("get", configClass.apiUrl.preview+"?kategori=3")
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (data.results.length > 0) {
            this.formRef.current.setFieldsValue(data.results[0]);
            for (var key in data.results[0]) {
              const dateRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4}$/;
              const dateTimeRegex =
                /^[0-9]{2}\-[0-9]{2}\-[0-9]{4} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
              if (dateRegex.test(data.results[0][key])) {
                //jika format tanggal
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data.results[0][key], "DD-MM-YYYY"),
                  //[key]: moment(data[key], 'DD-MM-YYYY')
                });
              } else if (dateTimeRegex.test(data.results[0][key])) {
                //jika format tanggal dan jam
                this.formRef.current.setFieldsValue({
                  [key + "_picker"]: moment(data.results[0][key], "DD-MM-YYYY HH:mm:ss"),
                });
              } /*else{
					    	this.formRef.current.setFieldsValue({
					    		[key]: data[key]
					    	});
					    }*/

              if (key == "gambar") {
                this.setState({
                  preview_gambar: data.results[0][key],
                });
              } else if (key == "content") {
                this.setState({
                  artikelString: data.results[0][key],
                });
                this.formRef.current.setFieldsValue({
                  content_editor: data.results[0][key],
                });
              }
            }
            this.setState({
              aksi: "edit",
              method: "put",
              idLama: data.results[0].id + "/",
            });
          }
          message.destroy();
        } else {
          const errTitle = "error preview data lama";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  handleEditorChange = (content, editor) => {
    this.setState({
      artikelString: content,
    });
  };

  setGambar = (e) => {
    this.setState({
      gambar: e.target.files[0],
    });
  };

  handleSubmit = (values) => {
    var datas = new FormData();
    for (var key in values) {
      if (values[key] == undefined) {
        datas.append(key, "");
      } else {
        //datas.append(key, (values[key]).toString().toUpperCase());
        datas.append(key, values[key].toString());
      }
    }
    if (this.state.gambar_jadi != null) {
      datas.append(
        "gambar",
        this.state.gambar_jadi,
        this.state.gambar_jadi.name
      );
    }
    datas.append("content", this.state.artikelString);
    datas.append("kategori", 3);
    this.postData(datas);
  };

  async postData(datas) {
    message.loading({ content: "Memproses...", key: "toast" });
    this.setState({
      loadingButton: true,
    });

    this.props
      .ajaxHandler(
        this.state.method,
        configClass.apiUrl.preview + this.state.idLama,
        datas,
        true,
        true
      )
      .then(() => {
        this.setState({
          loadingButton: false,
        });
        if (this.props.isSuccess) {
          message.success({
            content: "berhasil menyimpan data",
            duration: 2,
            key: "toast",
          });
          this.preview();
        } else {
          const errTitle = "gagal menyimpan data";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
        }
      })
      .catch((response) => {
        console.log("gagal_post", response);
        this.setState({
          loadingButton: false,
        });
        message.error({
          content: "kesalahan pengiriman data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  generateSelect(stateName, url, key, value) {
    console.log("tes", url);
    return this.state["loading" + stateName] ? (
      <img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
    ) : (
      <Select
        showSearch
        placeholder="pilih"
        optionFilterProp="children"
        onSearch={(val) => {
          const rule = {
            destination: {
              stateProgressName: "loading" + stateName + "_searching",
              endPoint: url + "?search=" + val,
              stateDestination: "list" + stateName,
            },
            nextDestination: null,
          };
          this.getMasterList(rule);
        }}
        onPopupScroll={(e) => {
          e.persist();
          let target = e.target;
          if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (
              this.state["list" + stateName].next != undefined &&
              this.state["list" + stateName].next != null
            ) {
              const rule = {
                destination: {
                  stateProgressName: "loading" + stateName + "Paging",
                  endPoint: this.state["list" + stateName].next,
                  stateDestination: "list" + stateName,
                },
                nextDestination: null,
              };
              this.getMasterList(rule);
            }
          }
        }}
      >
        {this.state["loading_" + stateName + "searching"] && (
          <Select.Option value="">
            <Spin size="small" />
          </Select.Option>
        )}
        {this.state["list" + stateName].count != undefined
          ? this.state["list" + stateName].results.map((item) => (
              <Select.Option value={item[key]}>
                {item[key] + " - " + item[value] + ""}
              </Select.Option>
            ))
          : this.state["list" + stateName].map((item) => (
              <Select.Option value={item[key]}>
                {item[key] + " - " + item[value] + ""}
              </Select.Option>
            ))}
        {this.state["list" + stateName] != undefined &&
          this.state["list" + stateName].next != undefined && (
            <Select.Option value="">
              <Spin size="small" />
            </Select.Option>
          )}
      </Select>
    );
  }

  render() {
    // if(this.props.isSuperadmin == false){
    // 	return <Redirect to="/admin/forbidden" />
    // }
    // if (this.props.isRedirect) {
    //   return <Redirect to={configClass.pageUrl.data} />;
    // }

    const { crop, croppedImageUrl, src } = this.state;

    return (
      <div>
        <Helmet>
          <title>
            {configClass.title + " | " + config.get_site_info().name}
          </title>
        </Helmet>
        <Breadcrumb>
          {configClass.breadCrumb.map((item) => (
            <Breadcrumb.Item>{item}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div className="card">
          <Spin
            size="large"
            spinning={this.state.loadingButton}
            tip={
              <span style={{ color: "#000" }}>
                sedang memproses, mohon menunggu...
              </span>
            }
          >
            <Form
              layout="vertical"
              name="fdata"
              onFinish={this.handleSubmit}
              size="small"
              ref={this.formRef}
            >
              {/*data dasar*/}
              <Row className="isi">
                <Col lg={24} sm={24} xs={24}>
                  <Card
                    className="konten"
                    title={
                      <span className="konten-judul">
                        {configClass.title}
                        {this.state.loadingButton && (
                          <img
                            src={process.env.PUBLIC_URL + "/loading_crop.gif"}
                            height="50"
                          />
                        )}
                      </span>
                    }
                  >
                    {this.state.errMsg != "" && (
                      <Alert
                        message={this.state.errTitle}
                        description={JSON.stringify(this.state.errMsg)}
                        type="error"
                        showIcon
                        style={{
                          marginBottom: 10,
                        }}
                      />
                    )}
                    <Row justify="space-between">
                      <Col lg={24} sm={24} xs={24}>
                        <Form.Item
                          label="JUDUL"
                          name="judul"
                          rules={[
                            {
                              required: true,
                              message: "harus diisi",
                            },
                          ]}
                        >
                          <Input
                            style={{ textTransform: "none" }}
                            placeholder="judul"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    
                    <Row justify="space-between">
                      <Col lg={24} sm={24} xs={24}>
                        <Form.Item label="GAMBAR" name="gambar_picker">
                          <Input
                            accept="image/png, image/jpeg"
                            type="file"
                            placeholder="pilih gambar"
                            // onChange={this.setGambar}
                            onChange={this.onSelectFile}
                          />
                        </Form.Item>

                        {this.state.aksi == "edit" &&
                          this.state.preview_gambar != null && (
                            <img src={this.state.preview_gambar} width="100" />
                          )}
                      </Col>
                      {src && (
                        <Col lg={24} style={{ border: "1px solid #ccc" }}>
                          <ReactCrop
                            src={src}
                            crop={crop}
                            ruleOfThirds
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                          />
                        </Col>
                      )}
                    </Row>

                    <Row justify="space-between">
                      <Col lg={24} sm={24} xs={24}>
                        <Form.Item
                          label="KONTEN"
                          name="content_editor"
                          rules={[
                            {
                              required: true,
                              message: "harus diisi",
                            },
                          ]}
                        >
                          <Editor
                            //initialValue="<p>This is the initial content of the editor</p>"
                            apiKey="8x2j9jm48a5i0twky98gt5cdzb9q1d6xl9wreep58ab8ogr7"
                            init={{
                              height: 500,
                              menubar: true,
                              plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount",
                              ],
                              toolbar:
                                "undo redo | formatselect | bold italic backcolor | \
															             alignleft aligncenter alignright alignjustify | \
															             bullist numlist outdent indent | removeformat | help",
                              //toolbar2: "print preview media | forecolor backcolor emoticons",
                              image_advtab: true,
                              file_picker_callback: function (
                                callback,
                                value,
                                meta
                              ) {
                                if (meta.filetype == "image") {
                                  document.getElementById("upload").click();
                                  document
                                    .getElementById("upload")
                                    .addEventListener("change", function () {
                                      var file = this.files[0];
                                      var reader = new FileReader();
                                      reader.onload = function (e) {
                                        callback(e.target.result, {
                                          alt: "",
                                        });
                                      };
                                      reader.readAsDataURL(file);
                                    });
                                }
                              },
                            }}
                            onEditorChange={this.handleEditorChange}
                          />
                        </Form.Item>
                        <input
                          name="image"
                          type="file"
                          id="upload"
                          class="hidden"
                          onchange=""
                          style={{ display: "none" }}
                        />
                      </Col>
                    </Row>
                    <Row justify="end">
                      <Col style={{ padding: 5 }}>
                        <Button
                          key="btn-simpan"
                          loading={this.state.loadingButton}
                          type="primary"
                          htmlType="submit"
                          disabled={this.state.errComponent ? true : false}
                          size="small"
                        >
                          {!this.state.loadingButton && <CheckCircleOutlined />}
                          Simpan
                        </Button>
                      </Col>
                      <Col style={{ padding: 5 }}>
                        <Link to={configClass.pageUrl.data}>
                          <Button
                            type="danger"
                            key="btn-cancel"
                            disabled={this.state.loadingButton ? true : false}
                            size="small"
                          >
                            <CloseCircleOutlined /> Cancel
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  initHandler,
  setConfig,
  ajaxViewHandler,
  ajaxHandler,
})(TermCondition);
