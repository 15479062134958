import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  Layout,
  Menu,
  Drawer,
  Result,
  Button,
  message,
  notification,
} from "antd";
import { enquireScreen, unenquireScreen } from "enquire-js";
import "antd/dist/antd.css";
import "../assets/style.css";

import "react-perfect-scrollbar/dist/css/styles.css";

import { connect } from "react-redux";
import { setConfig, ajaxViewHandler } from "../store/actions";

import axios from "axios";
import Config from "../Config";

import OneSignal from "react-onesignal";

import Cookies from "js-cookie";
import Sider from "../components/AdminSider";
import Header from "../components/AdminHeader";
import Footer from "../components/AdminFooter";

import Home from "../views/Home";
import Forbidden from "../views/Forbidden";

import DataKategori from "../views/kategori/DataKategori";
import FormKategori from "../views/kategori/FormKategori";

import DataTransaksi from "../views/transaksi/DataTransaksi";
import DataWithdraw from "../views/withdraw/DataWithdraw";
import DataMitraUsaha from "../views/mitrausaha/DataMitraUsaha";
import DataPerusahaan from "../views/mitrausaha/DataPerusahaan";
import FormBagiHasil from "../views/pengaturan/FormBagiHasil";
import FormPengaturanEmail from "../views/pengaturan/FormPengaturanEmail";
import FormPengaturanTelegram from "../views/pengaturan/FormPengaturanTelegram";
import FormPengaturanPoint from "../views/pengaturan/FormPengaturanPoint";
import FormPengaturanFee from "../views/pengaturan/FormPengaturanFee";

import DataArtikel from "../views/web/DataArtikel";
import FormArtikel from "../views/web/FormArtikel";
import TermCondition from "../views/web/TermCondition";
import TentangPlesiran from "../views/web/TentangPlesiran";

import DataSlider from "../views/slider/DataSlider";
import FormSlider from "../views/slider/FormSlider";

import LaporanPendaftaranUsaha from "../views/laporan/LaporanPendaftaranUsaha";
import LaporanTransaksi from "../views/laporan/LaporanTransaksi";
import LaporanDetailTransaksi from "../views/laporan/LaporanDetailTransaksi";
import LaporanWithdraw from "../views/laporan/LaporanWithdraw";

import DataLog from "../views/admin/DataLog";
import DataAdmin from "../views/admin/DataAdmin";
import FormAdmin from "../views/admin/FormAdmin";

import DataPembayaran from "../views/payment/DataPembayaran";
import FormPembayaran from "../views/payment/FormPembayaran";
import FormBuatTiket from "../views/mitrausaha/FormBuatTiket";

// import { askForPermissioToReceiveNotifications, logoutFirebase } from '../push-notification';

const { Content } = Layout;

const configClass = {
  apiUrl: {
    userProfile: "auth/api/user/info/",
  },
};

class AdminLayout extends React.Component {
  config;
  audioRef = React.createRef();

  constructor(props) {
    super(props);
    this.config = new Config();
  }

  handleCloseDrawer = () => {
    this.props.setConfig({ collapsed: true });
    // console.log('drawer_close', this.props.collapsed);
  };

  componentDidMount() {
    // notification.open({
    // 				    message: e.heading,
    // 				    description: e.content,
    // 				    icon:
    // 					    <img
    // 					    	src={this.config.get_site_info().logo}
    // 					    	height={30}
    // 					    />
    // 				    ,
    // 				});

    // if (!("Notification" in window)) {
    //     alert("browser tidak support notifikasi, notifikasi chat tidak akan aktif di browser ini");
    // }else if(Notification.permission !== "denied"){
    // 	Notification.requestPermission().then(function (permission) {
    //       // If the user accepts, let's create a notification
    //       if (permission === "granted") {
    //         //var notification = new Notification("notifikasi berhasil diaktifkan!");
    //       }
    //     });
    // }
    // this.connect();

    this.enquireHandler = enquireScreen((mobile) => {
      const { isMobile } = this.props;
      if (isMobile !== mobile) {
        this.props.setConfig({
          isMobile: mobile,
        });
        if (mobile) {
          this.props.setConfig({
            collapsed: false,
          });
        }
      }
    });

    this.getProfile();

    var that = this;

    OneSignal.init(
      {
        appId: "bca8fb44-4924-4d8c-87c2-fc5e85909f86",
        notifyButton: {
          enable: true,
        },
        // allowLocalhostAsSecureOrigin: true,
        safari_web_id:
          "web.onesignal.auto.2e21fe47-8329-4413-bae9-ecef4da3342d",
      },
      OneSignal.sendTag("admin_plesiran", "admin_plesiran", (tagsSent) => {
        // Callback called when tag has finished sending
        console.log("admin_plesiran TAG SENT", tagsSent);
      })
    ).then(() => {
      OneSignal.showSlidedownPrompt().then(() => {});
    });

    //    OneSignal.on('notificationDisplay', function(event) {
    //   console.warn('OneSignal notification displayed:', event);
    // });

    //    window.addEventListener('notificationDisplay', (event) => {
    //     alert('OS event')
    //   });

    OneSignal.on("notificationDisplay", function (e) {

    	that.props.setConfig({
    		latestNofif: JSON.stringify(e)
    	});

      if (Notification.permission === "granted") {
        // If it's okay let's create a notification
        // this.audioRef.current.play();
        let notification = new Notification(e.heading, {
          body: e.content,
          icon: process.env.PUBLIC_URL + "/logo.png"
        });
      }

      // console.log('msg', e);
      notification.open({
        message: e.heading,
        description: e.content,
        icon: <img src={process.env.PUBLIC_URL + "/logo.png"} height={30} />
      });
    });
  }

  componentWillUnmount() {
    unenquireScreen(this.enquireHandler);
  }

  async getProfile() {
    this.props
      .ajaxViewHandler("get", configClass.apiUrl.userProfile)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.responseCode == 401) {
          Cookies.remove(this.config.get_cookie_name());
          this.props.setConfig({
            isLogin: false,
            username: "",
            nama: "",
            id: "",
            pp: "",
            alamat: "",
            no_hp: "",
            layanan_id: [],
            layanan_str: [],
          });
        } else {
          if (data != undefined) {
            this.props.setConfig({
              username: data.username,
              isLogin: true,
              isSuperadmin: data.is_super_admin,
              id: data.id,
              nama: data.nama,
              access: data.access,
              // pp: "",
            });

            // askForPermissioToReceiveNotifications(data[0].tempatTugas);

            // if(!this.props.switchable){
            // 	// this.props.setConfig({
            // 	// 	isSuperadmin: data[0].is_superuser
            // 	// });
            // }
          }
        }
      })
      .catch((response) => {
        console.log("gagal_getprofile", response);
        message.error({
          content: "gagal request data profile, coba lagi",
          key: "toast",
        });
      });
  }

  render() {
    if (!this.props.isLogin) {
      return <Redirect to="/auth" />;
    }
    return (
      <Layout>
        {this.props.isMobile ? (
          <Drawer
            closable={false}
            onClose={this.handleCloseDrawer}
            //visible={!this.props.collapsed}
            visible={!this.props.collapsed}
            placement="top"
            width={200}
          >
            <Sider />
          </Drawer>
        ) : (
          <Sider />
        )}
        <div className="container" style={{ maxWidth: "4000px" }}>
          <Header />
          <Content className="content">
            <Switch>
              <Route path="/admin" component={Home} exact />
              <Route path="/admin/forbidden" component={Forbidden} exact />
              <Route path="/admin/index" component={Home} />

              <Route path="/admin/kategori" exact component={DataKategori} />
              <Route
                path="/admin/kategori/form/:id?"
                component={FormKategori}
              />

              <Route path="/admin/transaksi" exact component={DataTransaksi} />
              <Route path="/admin/withdraw" exact component={DataWithdraw} />
              <Route
                path="/admin/mitra-usaha"
                exact
                component={DataMitraUsaha}
              />
              <Route
                path="/admin/perusahaan"
                exact
                component={DataPerusahaan}
              />
              <Route path="/admin/generate-tiket/:id" component={FormBuatTiket} />
              <Route path="/admin/bagi-hasil" exact component={FormBagiHasil} />
              <Route path="/admin/pengaturan-email" exact component={FormPengaturanEmail} />
              <Route path="/admin/pengaturan-telegram" exact component={FormPengaturanTelegram} />
              <Route path="/admin/pengaturan-point" exact component={FormPengaturanPoint} />
              <Route path="/admin/pengaturan-fee" exact component={FormPengaturanFee} />

              <Route path="/admin/slider" exact component={DataSlider} />
              <Route path="/admin/slider/form/:id?" component={FormSlider} />
              <Route path="/admin/artikel" exact component={DataArtikel} />
              <Route path="/admin/artikel/form/:id?" component={FormArtikel} />
              <Route path="/admin/term-condition" exact component={TermCondition} />
              <Route path="/admin/tentang-plesiran" exact component={TentangPlesiran} />

              <Route
                path="/admin/laporan/pendaftaran-usaha"
                exact
                component={LaporanPendaftaranUsaha}
              />
              <Route
                path="/admin/laporan/transaksi"
                exact
                component={LaporanTransaksi}
              />
              <Route
                path="/admin/laporan/detail-transaksi"
                exact
                component={LaporanDetailTransaksi}
              />
              <Route
                path="/admin/laporan/withdraw"
                exact
                component={LaporanWithdraw}
              />

              <Route path="/admin/log" exact component={DataLog} />
              <Route path="/admin/admin" exact component={DataAdmin} />
              <Route path="/admin/admin/form/:id?" component={FormAdmin} />

              <Route
                path="/admin/pembayaran"
                exact
                component={DataPembayaran}
              />
              <Route
                path="/admin/pembayaran/form/:id?"
                component={FormPembayaran}
              />

              <Route
                path=""
                render={() => (
                  <Result
                    status="404"
                    title="404"
                    subTitle="Maaf, halaman tidak ditemukan."
                  />
                )}
              />
            </Switch>
          </Content>
          {/*<Footer />*/}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  setConfig,
  ajaxViewHandler,
})(AdminLayout);
