import React from 'react';
import { Link, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Avatar,
	Form, 
	Input,
	Button,
	Space,
	Select,
	DatePicker,
	message,
	Alert,
	Spin,
	Tabs,
} from 'antd';
import {
  DiffOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ClusterOutlined,
} from '@ant-design/icons';
import moment from 'moment';

import axios from 'axios';
import Config from '../../Config';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";
import {Helmet} from "react-helmet";

const FormItem = Form.Item

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 19,
      offset: 5,
    },
    lg: {
      span: 19,
      offset: 5,
    }
  },
};

const configClass = {
	title: 'pengaturan bagi hasil',
	apiUrl: {
		preview: "pengaturan/api/pengaturan/?tipe=3",
	},
	breadCrumb: [
		(
			<span>
				<DiffOutlined /> Master
			</span>
		),
		'pengaturan bagi hasil'
	]
}

const config = new Config();

class FormPengaturanTelegram extends React.Component {
	formRef = React.createRef();

	constructor(props){
		super(props);

		this.state = {
			errComponent: false,
			errTitle: '',
			errMsg: '',
			loadingButton: false,

			aksi: 'tambah',
			method: 'post',
			idLama: '',
		}
	}

	UNSAFE_componentWillMount(){
		this.props.initHandler();
	}

	componentDidMount(){
		this.preview();
	}

	async preview(id) {
		message.loading({ content: 'Menampilkan data sebelumnya...', duration: 0, key: 'toast' });

		this.props.ajaxViewHandler('get', configClass.apiUrl.preview)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				if(data.results.length > 0){
					// this.formRef.current.setFieldsValue(data.results[0]);
					// this.setState({
	    //     	aksi: 'edit',
	    //     	method: 'put',
	    //     	idLama: data.results[0].id+'/'
	    //     });
	    		this.formRef.current.setFieldsValue(data.results[0].config);
				}else{
					this.loadDefaultTemplate();
					this.setState({
	        		aksi: 'tambah',
	        		method: 'post',
	        		idLama: ""
	        });
				}
				message.destroy();   
	   }else{
	     const errTitle = 'error preview data lama';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	handleSubmit = (values) =>{
		// var params = new FormData();
		// for ( var key in values ) {
		// 	if(key == 'gudang'){
		// 		params.append(key, (values[key]).join());
		// 	}else{
		// 		params.append(key, (values[key]).toString());
		// 	}
	 //    }

	  // params.append("tipe", "1");
	  // params.append("config", JSON.stringify({

	  // }));

	  var params = {
		    "tipe": "3",
		    "config": {
		    	"token": values.token, 
		    	"chat_id": values.chat_id, 
		    	"transaksi": values.transaksi,
		    	"pembayaran": values.pembayaran
		    }
		}


		this.postData(params);
	}

	loadDefaultTemplate(){
		const transaksi = "transaksi baru @kode\r\nsilahkan konfirmasi dengan link dibawah ini\r\n@link";
		const pembayaran = "konfirmasi pembayaran transaksi @kode\r\nsilahkan konfirmasi dengan link dibawah ini\r\n@link";

		this.formRef.current.setFieldsValue({
      transaksi: transaksi,
      pembayaran: pembayaran
    });
	}

	async postData(datas) {
		message.loading({ content: 'Memproses...', key: 'toast' });
		this.setState({
			errMsg: ""
		});

		this.props.ajaxHandler("post", configClass.apiUrl.preview, datas, false, true)
		.then(() =>{
			this.setState({
				loadingButton: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil menyimpan data', duration: 2, key: 'toast' });
				// this.setState({
	   //      aksi: 'edit',
	   //      method: 'put',
	   //      idLama: this.props.responseMsg.id+'/'
	   //    });
			}else{
				const errTitle = 'gagal menyimpan data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_post', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	render() {
		// if(this.props.isSuperadmin == false){
		// 	return <Redirect to="/admin/forbidden" />
		// }
		// if(this.props.isRedirect){
		// 	return <Redirect to={configClass.pageUrl.data} />
		// }

		return(
			<div>
				<Helmet>
					<title>{configClass.title+" | "+config.get_site_info().name}</title>
				</Helmet>
				<Breadcrumb>
					{configClass.breadCrumb.map(item => (
						<Breadcrumb.Item>
							{item}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
				<div className="card">
					<Row className="isi">
					  <Col lg={24} sm={24} xs={24}>
					    <Card
					     className="konten"
						  >
							  <Form 
						layout="vertical"
						name="fdata"
					    onFinish={this.handleSubmit}
					    size="small"
					    ref={this.formRef}
					>
						{/*data dasar*/}
						<Row className="isi">
					       	<Col lg={24} sm={24} xs={24}>
					        	
						        		{(this.state.errMsg!='' &&
								        	<Alert
										      message={this.state.errTitle}
										      description={JSON.stringify(this.state.errMsg)}
										      type="error"
										      showIcon
										      style={{
										      	marginBottom: 10
										      }}
										    />
										)}
					                	
										
										<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="Token"
										            name="token"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            <Input
										            	style={{textTransform: 'none'}}
										            />
										        </Form.Item>
										    </Col>
										</Row>
										<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="Chat ID"
										            name="chat_id"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            <Input
										            	style={{textTransform: 'none'}}
										            />
										        </Form.Item>
										    </Col>
										</Row>
										<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="Isi Pesan Jika Ada Transaksi Baru"
										            name="transaksi"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            <Input.TextArea
										            	style={{textTransform: 'none', height: 100}}
										            />
										        </Form.Item>
										    </Col>
										</Row>
										<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="Isi Pesan Jika Ada Konfirmasi Pembayaran Baru"
										            name="pembayaran"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            <Input.TextArea
										            	style={{textTransform: 'none', height: 100}}
										            />
										        </Form.Item>
										    </Col>
										</Row>
										
										<Row justify="end">
									        <Col style={{padding: 5}}>
									        	<Button 
													key="btn-simpan"
													loading={this.state.loadingButton}
													type="primary" 
													htmlType="submit"
													disabled={this.state.errComponent?true:false}
													size="small"
												>
													{(!this.state.loadingButton && <CheckCircleOutlined />)}
													Simpan
												</Button>
											</Col>
											<Col style={{padding: 5}}>
													<Button 
														type="danger"
														key="btn-cancel"
														disabled={this.state.loadingButton?true:false}
													    size="small"
													    onClick={() => this.preview()}
													>
														<CloseCircleOutlined /> Cancel
													</Button>
										    </Col>
										    <Col style={{ padding: 5 }}>
		                      <Button
		                      	className="btn-default"
		                      	htmlType="button"
		                        // type="default"
		                        key="btn-default"
		                        disabled={this.state.loadingButton ? true : false}
		                        size="small"
		                        onClick={() => this.loadDefaultTemplate()}
		                      >
		                        Load Default Template
		                      </Button>
		                    </Col>
										</Row>

						        
					        </Col>
					    </Row>
					</Form>
						 	</Card>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler
		}
	)(FormPengaturanTelegram);