import React, { PureComponent } from 'react'
import { Redirect, Link } from "react-router-dom";
import { 
  Button, 
  Row, 
  Col,
  Input, 
  Layout, 
  Form, 
  Alert,
  Modal,
  message,
  Switch 
} from 'antd';
import '@ant-design/compatible/assets/index.css'

import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Config from '../Config';
import {Helmet} from "react-helmet";

import { connect } from 'react-redux';
import { 
  setConfig, 
  ajaxHandlerNoAuth, 
  ajaxViewHandler 
} from "../store/actions";

import axios from 'axios';
import Cookies from 'js-cookie';
import QRCode from 'qrcode.react';

import 'antd/dist/antd.css';
import '../assets/loginStyle.css';

const configClass = {
  apiUrl: {
    cekLogin: "api/token/"
  }
}

class AuthLayout extends PureComponent {
  config;
  timeoutGetQr;
  timeOutPushQr;

  constructor(props){
    super(props);
    this.config = new Config();
    this.state = {
      msg: '',
      loading: false,
      loadingRedirect: false,
      logo: this.config.get_site_info().logo
    }
  }

  componentDidMount(){
    // alert(JSON.stringify(this.props.location.state.from.pathname));

  }

  handleSubmit = (values) => {
    // values.preventDefault();
    // if(values.username == "admin" && values.password == "admin"){
    //   Cookies.set(this.config.get_cookie_name(), 'Bearer testing', { expires: 1 });
    //   this.setState({
    //     loading: false,
    //     loadingRedirect: true
    //   });
    //   setTimeout(() => {
    //       this.props.setConfig({
    //         isLogin: true
    //       });
    //   }, 3000);
    // }else{
    //   this.setState({
    //      msg: "username atau password salah"
    //   });
    // }
    values = {...values, level: 1}
    this.postLogin(values);
  }

  // doLoginPrototype(){
  //   Cookies.set(this.config.get_cookie_name(), 'Bearer testing', { expires: 1 });
  //   this.setState({
  //         loading: false,
  //         loadingRedirect: true
  //   });
  //   setTimeout(() => {
  //         this.props.setConfig({
  //           isLogin: true
  //         });
  //   }, 3000);
  // }

  async postLogin(datas) {
    //message.loading({ content: 'Memproses...', duration: 2, key: 'toast' });
    this.setState({
        loading: true
    });

    this.props.ajaxHandlerNoAuth('post', configClass.apiUrl.cekLogin, datas, false, true, "login")
    .then(() =>{
      this.setState({
        loading: false
      });
      if(this.props.isSuccess){
        Cookies.set(this.config.get_cookie_name(), 'Bearer '+this.props.responseMsg.access, { expires: 1 });
        this.setState({
          loading: false,
          loadingRedirect: true
        });
        const data = this.props.responseMsg;
        setTimeout(() => {
          this.props.setConfig({
            isLogin: true,
            isSuperadmin: data.is_super_admin,
            id: data.id
          });
        }, 3000);
      }else{
        this.setState({
          msg: this.props.responseMsg.access != undefined ? this.props.responseMsg.access : this.props.responseMsg
        });
      }
    }).catch((response) => {
      console.log('gagal_post', response);
      this.setState({
          loading: false
        });
      message.error({ content: 'kesalahan pengiriman data, code: '+response.responseCode+'coba lagi', duration: 2, key: 'toast' });
    });
  }

  pilihMode = e => {
    //console.log('radio3 checked', e.target.value);
    var mode = e?'prod':'dev';
    // localStorage.setItem('mode', theme);
    // this.props.setConfig({
    //   mode: theme
    // });

    Cookies.set("mode", mode);
    window.location.reload();
  };

  render() {
    if(this.props.isLogin){
      return <Redirect to={this.props.location != undefined && this.props.location.state != undefined && this.props.location.state.from != undefined ? this.props.location.state.from.pathname : "/"} />
    }
    
    return (
      <div className="root-login">
        <Helmet>
          <title>login | {this.config.get_site_info().name}</title>
        </Helmet>
        <Layout>
            {(this.state.loadingRedirect)?(
              <div className="splash">
                <div className="eclipse"></div>
                <div className="logo-splash">
                  <img alt="logo" src={this.state.logo} className="logo-splash" height={300} />
                  {/*<img src={process.env.PUBLIC_URL + "/icon.svg"} alt="logo" className="logo-splash" width="120" height="120" />*/}
                  <div className="title-splash">{"please wait a momment..."}</div>
                <div className="loading-splash">
                  <img src={process.env.PUBLIC_URL + "/loading.gif"} alt="loading" width="200" />
                </div>
                </div>
                
                <div className="eclipse-bawah"></div>
              </div>
            ):(
              <div className="form">
                <div className="logo-login" style={{marginBottom: 10}}>
                  <Link to="/">
                    {/*<img alt="logo" src={this.state.logo} />*/}
                    <span style={{fontSize: 28}}>LOGIN</span>
                  </Link>
                </div>
                {/*
                <div className="theme-switcher">
                  <Switch size="small" defaultChecked={Cookies.get("mode") == "prod"?true:false} onChange={this.pilihMode} />
                  <span style={{marginLeft: 5}} className="label-dark-mode">production mode</span>
                </div>
                */}
                {this.state.msg!='' &&
                  <Row justify="center" style={{marginBottom: 10}}>
                    <Col span="24">
                      <Alert message={this.state.msg.detail != undefined ? this.state.msg.detail : JSON.stringify(this.state.msg)} type="error" showIcon />
                    </Col>
                  </Row>
                }
                <Form 
                  name="login"
                  layout="vertical"
                  onFinish={this.handleSubmit}
                >
                  <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'username harus diisi!' }]}
                  >
                      <Input
                        placeholder="Username"
                        style={{textTransform: 'none'}}
                      />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'password harus diisi!' }]}
                  >
                    <Input
                      type="password"
                      placeholder="Password"
                      style={{textTransform: 'none'}}
                    />
                  </Form.Item>
                  <Row>
                    <Col span={24}>
                      <Button
                        className="btn-primary"
                        loading={this.state.loading}
                        htmlType="submit"
                        block
                      >
                        Login
                      </Button>
                    </Col>
                  </Row>
                  <div style={{textAlign: 'center', marginTop: 10}}>
                      &copy; 2022 {this.config.get_site_info().name}
                  </div>
                </Form>
              </div>
            )}
        </Layout>
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return state.configReducers
}

export default connect(mapStateToProps, 
                        {
                          setConfig,
                          ajaxHandlerNoAuth,
                          ajaxViewHandler
                        }
                  )(AuthLayout);
