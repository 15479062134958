import React from 'react';
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import Highlighter from 'react-highlight-words';
import { Link } from "react-router-dom";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert,
	Divider,
	Select,
	Tag,
	Form,
	Spin
} from 'antd';
import {
  TeamOutlined,
  SearchOutlined,
  PlusOutlined,
  FormOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  DiffOutlined,
  RedoOutlined,
  ClusterOutlined,
  GlobalOutlined, 
  CommentOutlined,
  LikeOutlined,
  ShareAltOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  QrcodeOutlined
} from '@ant-design/icons';

import moment from "moment";

import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";

import Config from '../../Config';
import {Helmet} from "react-helmet";

const configClass = {
	title: 'data perusahaan',
	apiUrl: {
		data: 'mitra/api/usaha/',
	},
	pageUrl: {
		form: '/admin/forum/form/'
	},
	breadCrumb: [
		(
			<span>
				<CheckCircleOutlined /> Approvement
			</span>
		),
		'data perusahaan'
	]

}

const config = new Config();

class DataPerusahaan extends React.Component {
	formRefKeterangan = React.createRef();

	constructor(props){
		super(props);
		this.state = {
			errMsg: '',
			loadingData: false,
			pagination: {pageSize: 10},
			searchText: '',
			searchColumn: '',
			dataSource: [],
			columns: [
				{
					title: 'STATUS',
					width: 150,
					dataIndex: 'status',
					key: 'status',
					fixed: 'left',
					render: (value, row, index) => {
						return (
							<div>
								{(value == 1 &&
									<Tag color="warning">pengajuan</Tag>
								)}
								{(value == 2 &&
									<Tag color="success">diterima</Tag>
								)}
								{(value == 3 &&
									<>
										<Tag color="error">
											ditolak
										</Tag>
										<div style={{fontSize: 12, background: "#F8656C", padding: 3, marginTop: 2, color: "#fff"}}>
											{row.keterangan}
										</div>
									</>
								)}
							</div>
						)
					},
				},
				{
					title: 'ID',
					width: 90,
					dataIndex: 'id',
					key: 'id',
				},
				{
					title: 'CETAK QR',
					width: 130,
					dataIndex: 'id',
					key: 'qr',
					align: 'center',
					render: (value, row, index) => {
						return(
								<div>
									{(row.status == 2 &&
											<>
												<br />
												<Link
													to={"/addon/cetak-qr-code/"+value}
													target="_blank"
												>
													<Button 
														type="default" 
														icon={<QrcodeOutlined />} 
													>
														Cetak QR
													</Button>
												</Link>
											</>
									)}
								</div>
						)
					}
				},
				{
					title: 'BUAT TIKET',
					width: 140,
					dataIndex: 'id',
					key: 'tiket',
					align: 'center',
					render: (value, row, index) => {
						return(
								<div>
									{(row.status == 2 &&
											<>
												<br />
												<Link
													to={"/admin/generate-tiket/"+value}
												>
													<Button 
														style={{
															background: "#0AB0EE", 
															color: "#fff",
															borderRadius: 10
														}}
														type="default" 
														icon={<QrcodeOutlined />} 
													>
														Buat Tiket
													</Button>
												</Link>
											</>
									)}
								</div>
						)
					}
				},
				{
					title: 'NAMA',
					width: 150,
					dataIndex: 'nama',
					key: 'nama',
				},
				{
					title: 'JENIS',
					width: 150,
					dataIndex: 'jenis',
					key: 'jenis',
					render: (value, row, index) => {
						return(
							<div>
								{value == 1 && "Wisata"}
								{value == 2 && "Transportasi"}
								{value == 3 && "Kuliner"}
								{value == 4 && "Paket Wisata"}
								{value == 5 && "Penginapan"}
								{value == 6 && "UMKM"}
								{value == 7 && "Event"}
							</div>
						)
					},
				},
				{
					title: 'NO TELPON',
					width: 150,
					dataIndex: 'no_telpon',
					key: 'no_telpon',
				},
				{
					title: 'NIB',
					width: 120,
					dataIndex: 'nib',
					key: 'nib',
					render: (value, row, index) => {
						return value != null && 
							<LazyLoadImage
								key={"nib"+row.id}
						    alt={"nib"}
						    effect="blur"
						    src={value} 
						    height={50} 
						    placeholder={
						    	<Spin />
						    }
						    onClick={() => {
									this.setState({
										image_focus_url: value,
										visible_image_focus: true,
										focus_title: "NIB: "+row.nama
									})
								}}
								style={{cursor: "pointer"}}
						  />
							// <img 
							// 	style={{cursor: "pointer"}}
							// 	src={value} 
							// 	height={50} 
							// 	onClick={() => {
							// 		this.setState({
							// 			image_focus_url: value,
							// 			visible_image_focus: true,
							// 			focus_title: "NIB: "+row.nama
							// 		})
							// 	}}
							// />
					},
				},
				{
					title: 'SIUP',
					width: 120,
					dataIndex: 'siup',
					key: 'siup',
					render: (value, row, index) => {
						return value != null && 
							<LazyLoadImage
								key={"siup"+row.id}
						    alt={"siup"}
						    effect="blur"
						    src={value} 
						    height={50} 
						    placeholder={
						    	<Spin />
						    }
						    onClick={() => {
									this.setState({
										image_focus_url: value,
										visible_image_focus: true,
										focus_title: "SIUP: "+row.nama
									})
								}}
								style={{cursor: "pointer"}}
						  />
							// <img 
							// 	style={{cursor: "pointer"}}
							// 	src={value} 
							// 	height={50} 
							// 	onClick={() => {
							// 		this.setState({
							// 			image_focus_url: value,
							// 			visible_image_focus: true,
							// 			focus_title: "SIUP: "+row.nama
							// 		})
							// 	}}
							// />
					},
				},
				{
					title: 'NPWP',
					width: 120,
					dataIndex: 'npwp_pemilik',
					key: 'npwp_pemilik',
					render: (value, row, index) => {
						return value != null && 
							<LazyLoadImage
								key={"npwp"+row.id}
						    alt={"npwp"}
						    effect="blur"
						    src={value} 
						    height={50} 
						    placeholder={
						    	<Spin />
						    }
						    onClick={() => {
									this.setState({
										image_focus_url: value,
										visible_image_focus: true,
										focus_title: "NPWP: "+row.nama
									})
								}}
								style={{cursor: "pointer"}}
						  />
							// <img 
							// 	style={{cursor: "pointer"}}
							// 	src={value} 
							// 	height={50} 
							// 	onClick={() => {
							// 		this.setState({
							// 			image_focus_url: value,
							// 			visible_image_focus: true,
							// 			focus_title: "NPWP: "+row.nama
							// 		})
							// 	}}
							// />
					},
				},
				{
					title: 'KTP',
					width: 120,
					dataIndex: 'ktp_pemilik',
					key: 'ktp_pemilik',
					render: (value, row, index) => {
						return value != null && 
							<LazyLoadImage
								key={"ktp"+row.id}
						    alt={"ktp"}
						    effect="blur"
						    src={value} 
						    height={50} 
						    placeholder={
						    	<Spin />
						    }
						    onClick={() => {
									this.setState({
										image_focus_url: value,
										visible_image_focus: true,
										focus_title: "KTP: "+row.nama
									})
								}}
								style={{cursor: "pointer"}}
						  />
							// <img 
							// 	style={{cursor: "pointer"}}
							// 	src={value} 
							// 	height={50} 
							// 	onClick={() => {
							// 		this.setState({
							// 			image_focus_url: value,
							// 			visible_image_focus: true,
							// 			focus_title: "KTP: "+row.nama
							// 		})
							// 	}}
							// />
					},
				},
				{
					title: 'FOTO SELFIE',
					width: 120,
					dataIndex: 'foto_selfie',
					key: 'foto_selfie',
					render: (value, row, index) => {
						return value != null && 
							<LazyLoadImage
								key={"foto_selfie"+row.id}
						    alt={"foto selfie"}
						    effect="blur"
						    src={value} 
						    height={50} 
						    placeholder={
						    	<Spin />
						    }
						    onClick={() => {
									this.setState({
										image_focus_url: value,
										visible_image_focus: true,
										focus_title: "FOTO SELFIE: "+row.nama
									})
								}}
								style={{cursor: "pointer"}}
						  />
							// <img 
							// 	style={{cursor: "pointer"}}
							// 	src={value} 
							// 	height={50} 
							// 	onClick={() => {
							// 		this.setState({
							// 			image_focus_url: value,
							// 			visible_image_focus: true,
							// 			focus_title: "FOTO SELFIE: "+row.nama
							// 		})
							// 	}}
							// />
					},
				},
				{
					title: 'APPROVED AT',
					width: 150,
					dataIndex: 'total_bayar',
					key: 'total_bayar',
					render: (value, row, index) => {
						return moment(value).format("DD-MM-YYYY HH:mm")
					},
				},
				{
					title: 'APPROVED BY',
					width: 150,
					dataIndex: 'approved_by',
					key: 'approved_by',
				},
				{
					title: 'AKSI',
					width: 120,
					dataIndex: 'id',
					key: 'aksi',
					fixed: 'right',
					render: (value, row, index) => {
						return (
							<div>
								{row.status == 2
									?
										<Tag color="success">
											sudah diterima
										</Tag>
									:
										<Select
											key={"aksi"+value}
											style={{width: '100%'}}
											key={"aksi-"+value}
											onChange={(val) => this.prosesUpdate(value, val)}
											placeholder={row.status_nama}
											value={row.status}
										>
											<Select.Option value={1}>pengajuan</Select.Option>
											<Select.Option value={2}>diterima</Select.Option>
											<Select.Option value={3}>ditolak</Select.Option>
										</Select>
								}
							</div>
						)
					},
				}
			],
			visible: false,
			loadingModal: false,
			dataketerangan: null,
			errTitleModal: '',
			dataDetail: null,
			idDipilih: null,
			loadingButton: false,

			visible_image_focus: false,
			image_focus_url: null,
			focus_title: "",
		}
	}

	UNSAFE_componentWillMount(){
		this.props.initHandler();
	}

	componentDidMount(){
		this.getData();
	}

	componentDidUpdate(prevProps) {
		// console.log('prevProps', prevProps);
		// console.log('props', this.props);
		//console.log('proper', prevProps);
	    if (prevProps.latestNofif !== this.props.latestNofif) {
	    	this.getData();
	    }
	}

	async showModal(detail){
		this.setState({
			loadingModal: true,
			errTitleModal: '',
			errMsgModal: '',
			visible: true,
			dataketerangan: null,
		}, () =>{
			this.props.ajaxViewHandler('get', configClass.apiUrl.data+detail)
				.then(() =>{
					const data = this.props.responseMsg;
					if(this.props.isSuccess){
						this.setState({
							dataketerangan: data.keterangan,
							dataDetail: detail,
							idDipilih: detail,
						});
			    }else{
			      const errTitle = 'error get data';
			      this.setState({
			        errTitleModal: errTitle,
			        errMsgModal: this.props.responseMsg
			      });
			      message.error({ content: errTitle, duration: 2, key: 'toast' });
			    }
			    this.setState({loadingModal: false});
				}).catch((response) => {
					message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
					this.setState({
						loadingModal: false,
						errTitleModal: "unknown error",
			      errMsgModal: "failed unknown error get data"
					});
			});
		});
	}

	setVisible(val){
		this.setState({
			visible: val
		});
	}

	handleSubmitKeterangan = (values) =>{
		var datas = new FormData();
		datas.append("status", "3");
		datas.append("keterangan", values.keterangan);

		this.setState({
			loadingButton: true,
		});

		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler('post', configClass.apiUrl.data+this.state.idDipilih+'/approved/', datas, true, true)
			.then(() =>{
				if(this.props.isSuccess){
					message.success({ content: 'berhasil mengubah status', key: 'toast' });
					this.getData();
				}else{
					const errTitle = 'gagal mengubah status'
					message.error({ content: errTitle, key: 'toast' });
					this.setState({
						errTitle: errTitle,
						errMsg: this.props.responseMsg
					});
				}
				this.setState({
					loadingButton: false,
				});
		}).catch((response) => {
				console.log('gagal_post', response);
				message.error({ content: 'kesalahan mengubah status data, coba lagi', key: 'toast' });
				this.setState({
					loadingButton: false,
				});
		});
	}

	async prosesUpdate(id, statusValue){
		if(statusValue == "3"){
			this.showModal(id)
		}else{
			var datas = new FormData();
			datas.append("status", statusValue);

			message.loading({ content: 'Memproses...', key: 'toast' });

			this.props.ajaxHandler('post', configClass.apiUrl.data+id+'/approved/', datas, true, true)
			.then(() =>{
				if(this.props.isSuccess){
					message.success({ content: 'berhasil mengubah status', key: 'toast' });
					this.getData();
				}else{
					const errTitle = 'gagal mengubah status'
					message.error({ content: errTitle, key: 'toast' });
					this.setState({
						errTitle: errTitle,
						errMsg: this.props.responseMsg
					});
				}
			}).catch((response) => {
				console.log('gagal_post', response);
				message.error({ content: 'kesalahan mengubah status data, coba lagi', key: 'toast' });
			});
		}
	}

	showDeleteConfirm(id) {
	  Modal.confirm({
	    title: 'Konfirmasi Hapus?',
	    icon: <ExclamationCircleOutlined />,
	    content: 'yakin menghapus data?',
	    okText: 'Ya',
	    okType: 'danger',
	    cancelText: 'Tidak',
	    onOk: () => this.prosesHapus(id),
	    onCancel() {
	      //console.log('Cancel');
	    },
	  });
	}

	prosesHapus(id) {
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler('delete', configClass.apiUrl.data+id+'/')
		.then(() =>{
			this.setState({
				loadingButton: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil menghapus data', duration: 2, key: 'toast' });
				this.getData();
			}else{
				const errTitle = 'gagal menghapus data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_delete', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	getColumnSearchProps = dataIndex => ({
	    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
	      <div style={{ padding: 8 }}>
	        <Input
	          ref={node => {
	            this.searchInput = node;
	          }}
	          placeholder={`Search ${dataIndex}`}
	          value={selectedKeys[0]}
	          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
	          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
	          style={{ width: 188, marginBottom: 8, display: 'block' }}
	        />
	        <Button
	          type="primary"
	          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
	          icon={<SearchOutlined />}
	          size="small"
	          style={{ width: 90, marginRight: 8 }}
	        >
	          Search
	        </Button>
	        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
	          Reset
	        </Button>
	      </div>
	    ),
	    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	    onFilter: (value, record) =>
	      record[dataIndex]
	        .toString()
	        .toLowerCase()
	        .includes(value.toLowerCase()),
	    onFilterDropdownVisibleChange: visible => {
	      if (visible) {
	        setTimeout(() => this.searchInput.select());
	      }
	    },
	    render: text =>
	      this.state.searchedColumn === dataIndex ? (
	        <Highlighter
	          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
	          searchWords={[this.state.searchText]}
	          autoEscape
	          textToHighlight={text.toString()}
	        />
	      ) : (
	        text
	      ),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
	    confirm();
	    this.setState({
	      searchText: selectedKeys[0],
	      searchedColumn: dataIndex,
	    });
	};

	handleReset = clearFilters => {
	    clearFilters();
	    this.setState({ searchText: '' });
	};

	async getData(limit=this.state.pagination.pageSize, offset=0, search=this.state.searchText){
		this.setState({loadingData: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.data+'?limit='+limit+'&offset='+offset+'&search='+search)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				const pagination = { ...this.state.pagination };
				pagination.total = data.count;
				this.setState({
					dataSource: data.results,
					pagination
				});
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({loadingData: false});
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingData: false});
		});
	}

	handleTableChange = async (pagination, filters, sorter) =>{
    	const newPagination = { ...this.state.pagination };
    	newPagination.pageSize = pagination.pageSize;
		this.setState({
			loadingData: true,
			pagination
		});
		if(pagination.current==1){
			this.getData(pagination.pageSize);
		}else{
			this.getData(pagination.pageSize, (pagination.current - 1)*pagination.pageSize);
		}

		
	}

	render() {
		// if(this.props.isSuperadmin == false){
		// 	return <Redirect to="/admin/forbidden" />
		// }
		return(
			<div>
				<Helmet>
					<title>{configClass.title+" | "+config.get_site_info().name}</title>
				</Helmet>
				<Breadcrumb>
					{configClass.breadCrumb.map(item => (
						<Breadcrumb.Item>
							{item}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
				<div className="card">
					<Row className="isi">
				       	<Col span={24}>
				        	<Card
				        		className="konten"
					            title={
					            	<Row>
					            		<Col lg={{ span: 20 }} xs={{ span:24 }}>
							            	<span className="konten-judul">
							            		{configClass.title}
							            		{(this.state.loadingData &&
									        		<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="30" alt="loading" />
									        	)}
							            	</span>
							            </Col>
						            	<Col lg={{ span: 4 }} sm={{ span: 24 }} xs={{ span:24 }} style={{textAlign: 'right'}}>
						            		<Button 
										        	loading={this.state.loadingButton}
										        	className="btn-refresh"
										        	size="small"
										        	onClick={() => this.getData()} 
										        >
										          <RedoOutlined />
										    </Button>
										    {/*
						            		<Link to={configClass.pageUrl.form}>
							            		<Button 
										        	loading={this.state.loadingButton}
										        	className="btn-primary"
										        	size="small" 
										        >
										          <PlusOutlined /> Tambah
										        </Button>
										    </Link>
										  */}
						            	</Col>
						            </Row>
					            }
					        >
					        	{(this.state.errMsg!='' &&
						        	<Alert
								      message={this.state.errTitle}
								      description={JSON.stringify(this.state.errMsg)}
								      type="error"
								      showIcon
								      style={{
								      	marginBottom: 10
								      }}
								    />
								)}
					        	<Table 
					        		title={() => 
					        			<Row>
					        				<Col lg={8} sm={24} xs={24}><span className="konten-judul">Total {this.state.pagination.total} record</span></Col>
											<Col lg={{span: 8, offset: 8}} sm={24} xs={24}>
												<Input.Search 
													size="small"
													placeholder="ketikkan pencarian" 
													onSearch={value => {
														this.setState({
															searchText: value
														}, () =>{
																this.getData();
														});
													}} 
													allowClear
												/>										
											</Col>
										</Row>					        			
					        		}
					        		bordered
					        		columns={this.state.columns} 
					        		dataSource={this.state.dataSource} 
					        		loading={this.state.loadingData} 
					        		scroll={{ x: 240, y: 450 }}
					        		size="small"
					        		pagination={this.state.pagination}
					        		onChange={this.handleTableChange}
					        	/>
					        </Card>
				        </Col>
				    </Row>
				</div>

				<Modal
	        title="Alasan Penolakan"
	        centered
	        visible={this.state.visible}
	        // onOk={() => this.setVisible(false)}
	        onCancel={() => this.setVisible(false)}
	        width={1000}
	        footer={[]}
	      >
	      	{(this.state.errTitleModal!='' &&
						        	<Alert
								      message={this.state.errTitleModal}
								      description={JSON.stringify(this.state.errMsgModal)}
								      type="error"
								      showIcon
								      style={{
								      	marginBottom: 10
								      }}
								    />
					)}
					<Row>
						<Col span={24}>
							<Form 
								layout="vertical"
								name="fketerangan"
							 	onFinish={this.handleSubmitKeterangan}
							  size="small"
							  ref={this.formRefKeterangan}
							>
								<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="ALASAN PENOLAKAN"
										            name="keterangan"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            <Input
										            	style={{textTransform: 'none'}}
										                placeholder="input alasan penolakan"
										            />
										        </Form.Item>
										    </Col>
								</Row>
								<Row justify="end">
									        <Col style={{padding: 5}}>
									        	<Button 
													key="btn-simpan"
													loading={this.state.loadingButton}
													type="primary" 
													htmlType="submit"
													disabled={this.state.errComponent?true:false}
													size="small"
												>
													{(!this.state.loadingButton && <CheckCircleOutlined />)}
													Simpan
												</Button>
											</Col>
											<Col style={{padding: 5}}>
												
													<Button 
														type="danger"
														key="btn-cancel"
														disabled={this.state.loadingButton?true:false}
													    size="small"
													  onClick={() => this.setState({visible: false})}
													>
														<CloseCircleOutlined /> Cancel
													</Button>
												
										    </Col>
										</Row>
							</Form>
						</Col>
					</Row>

	      	
	      </Modal>


	      <Modal
		        centered
		        visible={this.state.visible_image_focus}
		        // onOk={() => this.setVisible(false)}
		        onCancel={() => {
		        	this.setState({
		        		visible_image_focus: false,
		        		image_focus_url: null,
		        		focus_title: "",
		        	});
		        }}
		        width={700}
		        footer={[]}
		     >
		      	<div style={{textAlign: "center"}}>
		      		<h1>{this.state.focus_title}</h1>
		      		<br />
		      		<img src={this.state.image_focus_url} style={{width: "100%"}} />
		      	</div>
		     </Modal>

			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler
		}
	)(DataPerusahaan);