import React from 'react';
import { Link } from "react-router-dom";
import { 
  Layout, 
  Menu, 
  Avatar,
  Popover, 
  Badge, 
  List,
  Dropdown,
  Switch 
} from 'antd';
import{
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BellOutlined, 
  RightOutlined,
  LogoutOutlined,
  SettingOutlined
} from '@ant-design/icons';
import Cookies from 'js-cookie';
import moment from 'moment';

import { connect } from 'react-redux';
import { 
  setConfig,
  initHandler,
  ajaxViewHandler,
} from "../store/actions";

import Config from '../Config';

// import { logoutFirebase } from '../push-notification';

const { SubMenu } = Menu;
const { Header } = Layout;

class AdminHeader extends React.Component {
  config;

  constructor(props){
    super(props);
    this.config = new Config();
  }

  UNSAFE_componentWillMount(){
    this.props.initHandler();
    // navigator.serviceWorker.removeEventListener("message", (message) => {});
  }

  componentDidMount(){
    // navigator.serviceWorker.addEventListener("message", (message) => {
    //     console.log('refreshing_new_data');
    // });
  }

  hide = () => {
    this.setState({
      visiblePopMenu: false,
    });
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  toggle = () => {
    this.props.setConfig({
      collapsed: !this.props.collapsed
    });
  }

  prosesLogout = () => {
    // e.preventDefault();
      Cookies.remove(this.config.get_cookie_name());
      //Cookies.remove('aBdi_d3s4uth_temp');
      this.props.setConfig({
        isLogin: false,
        username: '',
        nama: '',
        id: '',
        pp: '',
        alamat: '',
        no_hp: '',
        layanan_id: [],
        layanan_str: [],
      });
      // logoutFirebase(this.props.desa);
    
  };

  pilihMode = e => {
    //console.log('radio3 checked', e.target.value);
    var mode = e?'prod':'dev';
    // localStorage.setItem('mode', theme);
    // this.props.setConfig({
    //   mode: theme
    // });

    Cookies.set("mode", mode);
    window.location.reload();
  };

  render() {
    return (
      <Header className={this.props.collapsed?'header collapsed':'header'}>
        {React.createElement(this.props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: 'trigger',
          onClick: this.toggle,
        })}
        {/*
        <div className="theme-switcher">
          <Switch size="small" defaultChecked={Cookies.get("mode") == "prod"?true:false} onChange={this.pilihMode} />
          <span style={{marginLeft: 5}} className="label-dark-mode">production mode</span>
        </div>
        */}

        <div className="rightContainer">
            {(this.props.jmlNotif>0 &&
              <div className="notifications">
                <Link to="#">
                    <Badge
                      title="surat mandiri"
                      count={this.props.jmlNotif}
                      offset={[8, 0]}
                      className="notifIconButton"
                    >
                      <BellOutlined className="iconFont" />
                    </Badge>
                </Link>
              </div>
            )}
            <Dropdown 
              overlay={
                <Menu>
                  <Menu.Item key="Logout" onClick={this.prosesLogout}>
                    <LogoutOutlined /> Logout
                  </Menu.Item>
                </Menu>
              }
            >
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                <div>
                 <span className="menu-atas">{this.props.nama!=null&&this.props.nama!=''?this.props.nama:this.props.username}</span>
                 <Avatar style={{ marginRight: 20 }} size="large" src={this.props.pp != '' && this.props.pp != null ? this.props.pp : process.env.PUBLIC_URL + "/avatar.png"} />
                </div>
              </a>
            </Dropdown>
            {/*<Menu key="user" mode="horizontal" onClick={this.handleMenuAtas}>
                <SubMenu
                  className="menu-atas"
                  title={
                    <div>
                      <span className="menu-atas">{this.props.nama!=null&&this.props.nama!=''?this.props.nama:this.props.username}</span>
                        <Avatar style={{ marginLeft: 8 }} src={this.props.pp} />
                    </div>
                  }
                >
                    
                    <Menu.Item key="Logout">
                      <LogoutOutlined /> Logout
                    </Menu.Item>
                  </SubMenu>
            </Menu>*/}
        </div>
      </Header>
    )
  }
}

const mapStateToProps = function(state) {
  return state.configReducers
}

export default connect(mapStateToProps, 
            {
              initHandler,
              setConfig,
              ajaxViewHandler,
            })(AdminHeader);