import React from 'react';
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import Highlighter from 'react-highlight-words';
import { Link } from "react-router-dom";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Form, 
	Input,
	Button,
	Table,
	Select,
	Modal,
	message,
	DatePicker,
	Spin,
	Alert,
	Tag
} from 'antd';
import {
  TeamOutlined,
  SearchOutlined,
  PlusOutlined,
  FormOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  DiffOutlined,
  RedoOutlined,
  ReadOutlined,
  FileExcelOutlined,
  EyeOutlined,
  LaptopOutlined,
  SplitCellsOutlined
} from '@ant-design/icons';

import axios from 'axios';
import moment from "moment";



import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
	ajaxDownloadHandler
} from "../../store/actions";

import Config from '../../Config';
import {Helmet} from "react-helmet";

const configClass = {
	title: 'laporan detail transaksi',
	apiUrl: {
		data: 'transaksi/api/detail/orders/',
		provinsi: "master/api/provinsi/",
		kabupaten: "master/api/kabupaten/",
	},
	pageUrl: {
		form: '/admin/perusahaan/form/'
	},
	breadCrumb: [
		(
			<span>
				<ReadOutlined /> laporan
			</span>
		),
		'detail transaksi'
	]

}

const config = new Config();

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 23 },
  },
};

class LaporanDetailTransaksi extends React.Component {
	formRef = React.createRef();

	constructor(props){
		super(props);
		this.state = {
			errMsg: '',
			loadingData: false,
			pagination: {pageSize: 25},
			searchText: '',
			searchColumn: '',
			dataSource: [],

			loadingProvinsi: false,
			listProvinsi: [],
			loadingKabupaten: false,
			listKabupaten: [],
			loadingKecamatan: false,
			listKecamatan: [],

			filterData: '',

			columns: [
				{
					title: 'AKSI',
					width: 200,
					dataIndex: 'qr',
					key: 'qr',
					fixed: 'left',
					align: 'center',
					render: (value, row, index) => {
						return(
							<div>
								<Tag color="processing" style={{fontSize: 12, marginBottom: 5}}>
									{"tgl: "+moment(row.created_at).format('DD-MM-YYYY HH-mm')}
								</Tag>
								<br />
								<a target="_blank" href={"https://plesiran.co.id/payment/"+value}> 
									<Button
										type="primary"
										title="qr bayar"
									>
										<LaptopOutlined />
										
									</Button>
								</a>
								<a target="_blank" href={"https://plesiran.co.id/e-tiket/"+value}> 
									<Button
										style={{marginLeft: 10}}
										type="danger"
										title="qr checkin"
									>
										<SplitCellsOutlined />
										
									</Button>
								</a>
								{
									row.is_offline
									?
										<Tag color="error" style={{marginLeft: 10}}>Offline</Tag>
									:
										<Tag color="success" style={{marginLeft: 10}}>Online</Tag>
								}
							</div>
						)
					}
				},
				{
					title: 'EMAIL',
					width: 150,
					dataIndex: 'email',
					key: 'email',
					render: (value, row, index) => {
						return (
							<>
								{row.customer}
								<br />
								<Tag color="processing" style={{fontSize: 12}}>
									{value}
								</Tag>
							</>
						)
					}
				},
				{
					title: 'NAMA PRODUK',
					width: 150,
					dataIndex: 'produk_nama',
					key: 'produk_nama',
				},
				{
					title: '@HARGA',
					width: 120,
					dataIndex: 'harga_awal',
					key: 'harga_awal',
					render: (value, row, index) => {
						return (
							<div>
								{
									value > row.harga_akhir
									?
										<>
											{row.harga.harga_akhir.toLocaleString('ID')}
											<del>
												{row.harga_awal.toLocaleString('ID')}
											</del>
										</>
									:
										(value == 0 ? row.harga_akhir.toLocaleString('ID') : value.toLocaleString('ID'))
								}
							</div>
						)
						value.toLocaleString('ID')
					}
				},
				// {
				// 	title: 'HARGA AKHIR',
				// 	width: 150,
				// 	dataIndex: 'harga_akhir',
				// 	key: 'harga_akhir',
				// 	render: (value, row, index) => {
				// 		return value.toLocaleString('ID')
				// 	}
				// },
				{
					title: 'QTY',
					width: 80,
					dataIndex: 'quantity',
					key: 'quantity',
				},
				{
					title: 'SUB TOTAL',
					width: 150,
					dataIndex: 'harga_akhir',
					key: 'sub_total',
					render: (value, row, index) => {
						return (value*row.quantity).toLocaleString('ID')
					}
				},

				{
					title: 'KODE',
					width: 150,
					dataIndex: 'kode',
					key: 'kode',
				},
				{
					title: 'ID',
					width: 90,
					dataIndex: 'id',
					key: 'id',
				},
				// {
				// 	title: 'CUSTOMER',
				// 	width: 150,
				// 	dataIndex: 'customer',
				// 	key: 'customer',
				// },
				// {
				// 	title: 'CHECKIN OLEH',
				// 	width: 120,
				// 	dataIndex: 'checkin_by',
				// 	key: 'checkin_by',
				// 	render: (value, row, index) => {
				// 		return value != undefined && value != null ? value :	"-"
							
				// 	}
				// },
				{
					title: 'STATUS CHECKIN',
					width: 150,
					dataIndex: 'is_checkin',
					key: 'is_checkin',
					fixed: "right",
					render: (value, row, index) => {
						return(
							row.is_checkin != undefined ?
							(
								value
								?
									<Tag color="success">
										sudah checkin
										<br />
										{"at: "+moment(row.checkin_at).format("DD-MM-YYYY: HH:mm")}
										{
											row.checkin_by_nama != undefined && row.checkin_by_nama != null 
											? 
												<>
													<br />
													{"by: "+row.checkin_by_nama }
												</>
											:	""
										}
									</Tag>
								:
									<Tag color="error">belum checkin</Tag>
							)
							:
								"menunggu field API"
						)
					}
				},
				{
					title: 'STATUS BAYAR',
					width: 150,
					dataIndex: 'is_paid',
					key: 'is_paid',
					fixed: 'right',
					render: (value, row, index) => {
						return(
							row.is_paid != undefined ?
							(
								value
								?
									<Tag color="success">
										sudah bayar<br />
										{"at: "+moment(row.paid_on).format("DD-MM-YYYY: HH:mm")}
										{
											row.paid_by_nama != undefined && row.paid_by_nama != null 
											? 
												<>
													<br />
													{"by: "+row.paid_by_nama }
												</>
											:	""
										}
									</Tag>
								:
									<Tag color="error">belum bayar</Tag>
							)
							:
								"menunggu field API"
						)
					}
				}
			]
		}
	}

	UNSAFE_componentWillMount(){
		this.props.initHandler();
	}

	componentDidMount(){
		this.getData();

		this.loadMasterList();
	}

	loadMasterList(){
		const rule = [
			{
				destination: {
					stateProgressName: 'loadingProvinsi',
					endPoint: configClass.apiUrl.provinsi,
					stateDestination: 'listProvinsi',
				},
				nextDestination: null
			}
		];

		this.setState({
			errComponent: false
		});

		rule.map((item, index) => {
			if(index == rule.length-1){
				this.getMasterList(item, true);
			}else{
				this.getMasterList(item);
			}
		});
	}

	async getMasterList(rule, isFinal=false) {
		var destination = rule.destination;
		var nextDestination = rule.nextDestination;
		this.setState({[destination.stateProgressName]: true});
		this.props.ajaxViewHandler('get', destination.endPoint)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				if(data.count != undefined){
					let newState = null;
					if(this.state[destination.stateDestination].length == 0){
						newState = data;
					}else{
						if(destination.stateProgressName.includes('_searching')){
							newState = data
						}else{
							const oldState = this.state[destination.stateDestination];
							newState = {
								count: data.count,
								next: data.next,
								previous: data.previous,
								results: [...oldState.results, ...data.results]
							};
						}
					}
					this.setState({
		            	[destination.stateDestination]: newState
		            });
				}else if(data.results != undefined){
	        		this.setState({
		            	[destination.stateDestination]: data.results
		            });
	        	}else{
	        		if(Array.isArray(data)){
			            this.setState({
			            	[destination.stateDestination]: data
			            })
			        }else{
			        	this.setState({
			            	[destination.stateDestination]: [data]
			            })
			        }
		        }
		        if(isFinal){
		        	if(this.props.match.params.id != undefined){
						//this.preview(this.props.match.params.id);
					}
		        }
		    }else{
		    	this.setState({
	        		errComponent: true
	        	});
		    }
		    this.setState({[destination.stateProgressName]: false});
		}).catch((response) => {
			//console.log('gagal_get_master', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({
				errTitle: 'gagal request data master',
				errMsg: response
			});
			this.setState({[destination.stateProgressName]: false});
		});
        //next function
        if(nextDestination != null){
        	const next = {
        		destination: {
	        		stateProgressName: nextDestination.stateProgressName,
	        		endPoint: nextDestination.endPoint,
	        		stateDestination: nextDestination.stateDestination,
	        	},
        		nextDestination: nextDestination.next

        	}
        	this.getMasterList(next);
        }
	}

	showDeleteConfirm(id) {
	  Modal.confirm({
	    title: 'Konfirmasi Hapus?',
	    icon: <ExclamationCircleOutlined />,
	    content: 'yakin menghapus data?',
	    okText: 'Ya',
	    okType: 'danger',
	    cancelText: 'Tidak',
	    onOk: () => this.prosesHapus(id),
	    onCancel() {
	      //console.log('Cancel');
	    },
	  });
	}

	prosesHapus(id) {
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler('delete', configClass.apiUrl.data+id+'/')
		.then(() =>{
			this.setState({
				loadingButton: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil menghapus data', duration: 2, key: 'toast' });
				this.getData();
			}else{
				const errTitle = 'gagal menghapus data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_delete', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	getColumnSearchProps = dataIndex => ({
	    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
	      <div style={{ padding: 8 }}>
	        <Input
	          ref={node => {
	            this.searchInput = node;
	          }}
	          placeholder={`Search ${dataIndex}`}
	          value={selectedKeys[0]}
	          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
	          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
	          style={{ width: 188, marginBottom: 8, display: 'block' }}
	        />
	        <Button
	          type="primary"
	          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
	          icon={<SearchOutlined />}
	          size="small"
	          style={{ width: 90, marginRight: 8 }}
	        >
	          Search
	        </Button>
	        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
	          Reset
	        </Button>
	      </div>
	    ),
	    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	    onFilter: (value, record) =>
	      record[dataIndex]
	        .toString()
	        .toLowerCase()
	        .includes(value.toLowerCase()),
	    onFilterDropdownVisibleChange: visible => {
	      if (visible) {
	        setTimeout(() => this.searchInput.select());
	      }
	    },
	    render: text =>
	      this.state.searchedColumn === dataIndex ? (
	        <Highlighter
	          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
	          searchWords={[this.state.searchText]}
	          autoEscape
	          textToHighlight={text.toString()}
	        />
	      ) : (
	        text
	      ),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
	    confirm();
	    this.setState({
	      searchText: selectedKeys[0],
	      searchedColumn: dataIndex,
	    });
	};

	handleReset = clearFilters => {
	    clearFilters();
	    this.setState({ searchText: '' });
	};

	handleSubmit = (values) =>{
		var datas = '';
		for ( var key in values ) {
			if(values[key] != undefined){
				if(datas == ''){
					datas = key+'='+values[key];
				}else{
					datas += '&'+key+'='+values[key];
				}
			}
		}
		this.setState({
			filterData: datas
		});

		this.getData();
	}

	async getData(limit=this.state.pagination.pageSize, offset=0, search=this.state.searchText){
		this.setState({loadingData: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.data+'?limit='+limit+'&offset='+offset+'&search='+search+'&'+this.state.filterData)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				const pagination = { ...this.state.pagination };
				pagination.total = data.count;
				this.setState({
					dataSource: data.results,
					pagination
				});
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({loadingData: false});
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingData: false});
		});
	}

	async postDataWithDownload() {
	    message.loading({ content: 'Memproses...', duration: 0, key: 'toast' });
	    this.setState({
	        loadingData: true,
	        errTitle: '',
	        errMsg: '',
	        successMsg: '',
	    });


	    this.props.ajaxDownloadHandler('get', configClass.apiUrl.data+'?'+this.state.filterData+'&export=true')
	    .then(() =>{
	      if(this.props.isSuccess){
	        this.setState({
	            loadingData: false,
	            errTitle: '',
	            errMsg: '',
	            successMsg: 'proses berhasil',
	        });
	      //   var a = document.createElement('a');
	      // var url = window.URL.createObjectURL(this.props.responseMsg);
	      // a.href = url;
	      // a.download = this.state.title;//'myfile.pdf';
	      // document.body.append(a);
	      // a.click();
	      // a.remove();
	      // window.URL.revokeObjectURL(url);
	         const url = window.URL.createObjectURL(new Blob([this.props.responseMsg]));
	         const link = document.createElement('a');
	         link.href = url;
	         // link.setAttribute(this.state.title); //or any other extension
	         // link.download = this.state.title;
	         // link.download = this.getFileNameFromUrl(this.state.media);
	         link.download = 'ples_lap_trx'+moment().format("DD-MM-YYYY_HH-mm-ss")+'.xlsx';
	         document.body.appendChild(link);
	         link.click();
	      	 message.success({ content: 'File berhasil diunduh', duration: 2, key: 'toast' });
	      }else{
	        this.setState({
	          msg: this.props.responseMsg.detail != undefined ? this.props.responseMsg.detail : this.props.responseMsg,
	          loadingData: false,
	          errTitle: 'error',
	          errMsg: 'gagal export',
	          successMsg: '',
	        });
	      }
	    }).catch((response) => {
	      console.log('gagal_post', response);
	      this.setState({
	          loadingData: false
	      });
	      message.error({ content: 'kesalahan pengiriman data, code: '+response.responseCode+'coba lagi', duration: 2, key: 'toast' });
	    });
	 }

	handleTableChange = async (pagination, filters, sorter) =>{
    	const newPagination = { ...this.state.pagination };
    	newPagination.pageSize = pagination.pageSize;
		this.setState({
			loadingData: true,
			pagination
		});
		if(pagination.current==1){
			this.getData(pagination.pageSize);
		}else{
			this.getData(pagination.pageSize, (pagination.current - 1)*pagination.pageSize);
		}

		
	}

	generatePovinsi(stateName, url, key, value, disabled=false){
		return (
			this.state['loading'+stateName]?(
				<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
			):(
				<Select
					allowClear
					disabled={disabled}
					showSearch
					placeholder={disabled?"Pilih Provinsi (Fitur Tambahan)":"Pilih Provinsi"}
					optionFilterProp="children"
					onSelect={this.pilihProvinsi}
					onSearch={ val => {
						const rule = {
							destination: {
								stateProgressName: 'loading'+stateName+'_searching',
								endPoint: url+'?search='+val,
								stateDestination: 'list'+stateName
							},
							nextDestination: null
						}
						this.getMasterList(rule);
					}}
					onPopupScroll={ e => {
						e.persist(); 
						let target = e.target;
						if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
							if(this.state['list'+stateName].next != undefined && this.state['list'+stateName].next != null){
								const rule = {
									destination: {
										stateProgressName: 'loading'+stateName+'Paging',
										endPoint: this.state['list'+stateName].next,
										stateDestination: 'list'+stateName
									},
									nextDestination: null
								}
								this.getMasterList(rule);
							}
						}
					}}
				>
					{(this.state['loading_'+stateName+'searching'] &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
					{this.state['list'+stateName].count != undefined ? (
						this.state['list'+stateName].results.map((item) => (
							<Select.Option value={item[key]}>{item[value]}</Select.Option>
						))
					) : (
						this.state['list'+stateName].map((item) => (
							<Select.Option value={item[key]}>{item[value]}</Select.Option>
						))
					)}
					{(this.state['list'+stateName] != undefined && this.state['list'+stateName].next != undefined &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
				</Select>
			)
		);
	}

	render() {
		// if(this.props.isSuperadmin == false){
		// 	return <Redirect to="/admin/forbidden" />
		// }
		return(
			<div>
				<Helmet>
					<title>{configClass.title+" | "+config.get_site_info().name}</title>
				</Helmet>
				<Breadcrumb>
					{configClass.breadCrumb.map(item => (
						<Breadcrumb.Item>
							{item}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
				<div className="card">
					<Row className="isi">
				       	<Col span={24}>
				        	<Card
				        		className="konten"
					            title={
					            	<Row>
					            		<Col lg={{ span: 20 }} xs={{ span:24 }}>
							            	<span className="konten-judul">
							            		{configClass.title}
							            		{(this.state.loadingData &&
									        		<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="30" alt="loading" />
									        	)}
							            	</span>
							            </Col>
						            	<Col lg={{ span: 4 }} sm={{ span: 24 }} xs={{ span:24 }} style={{textAlign: 'right'}}>
						            		
						            	</Col>
						            </Row>
					            }
					        >
					        	{(this.state.errMsg!='' &&
						        	<Alert
								      message={this.state.errTitle}
								      description={JSON.stringify(this.state.errMsg)}
								      type="error"
								      showIcon
								      style={{
								      	marginBottom: 10
								      }}
								    />
								)}

					        	<Form 
											layout="horizontal"
											name="fdata"
										    onFinish={this.handleSubmit}
										    size="small"
										    ref={this.formRef}
										    {...formItemLayout}
										>
											<Row>
												<Col md={12} sm={12} xs={24}>
													<Form.Item name="tanggal_mulai_picker">
												        <DatePicker 
														    placeholder="Dari Tgl (dd-mm-yyyy)" 
														    format="DD-MM-YYYY"
														    style={{width: '100%'}}
														    onChange={(date, dateString) =>{
														        this.formRef.current.setFieldsValue({
																	tanggal_mulai: dateString,
																});
														    }}
														/>
													</Form.Item>
													<Form.Item
														name="tanggal_mulai"
														style={{
															display: 'none'
														}}
													>
														<Input type="hidden" />
												    </Form.Item>
												</Col>
												<Col  md={12} sm={12} xs={24}>
												    <Form.Item name="tanggal_akhir_picker">
												        <DatePicker 
														    placeholder="Sampai Tgl (dd-mm-yyyy)" 
														    format="DD-MM-YYYY"
														    style={{width: '100%'}}
														    onChange={(date, dateString) =>{
														        this.formRef.current.setFieldsValue({
																	tanggal_akhir: dateString,
																});
														    }}
														/>
													</Form.Item>
													<Form.Item
														name="tanggal_akhir"
														style={{
															display: 'none'
														}}
													>
														<Input type="hidden" />
												    </Form.Item>
												</Col>

												<Col md={12} sm={12} xs={24}>
													<Form.Item name="checkin_mulai_picker">
												        <DatePicker 
														    placeholder="Dari Tgl Checkin (dd-mm-yyyy)" 
														    format="DD-MM-YYYY"
														    style={{width: '100%'}}
														    onChange={(date, dateString) =>{
														        this.formRef.current.setFieldsValue({
																	checkin_mulai: dateString,
																});
														    }}
														/>
													</Form.Item>
													<Form.Item
														name="checkin_mulai"
														style={{
															display: 'none'
														}}
													>
														<Input type="hidden" />
												    </Form.Item>
												</Col>
												<Col  md={12} sm={12} xs={24}>
												    <Form.Item name="checkin_akhir_picker">
												        <DatePicker 
														    placeholder="Sampai Tgl Checkin (dd-mm-yyyy)" 
														    format="DD-MM-YYYY"
														    style={{width: '100%'}}
														    onChange={(date, dateString) =>{
														        this.formRef.current.setFieldsValue({
																	checkin_akhir: dateString,
																});
														    }}
														/>
													</Form.Item>
													<Form.Item
														name="checkin_akhir"
														style={{
															display: 'none'
														}}
													>
														<Input type="hidden" />
												    </Form.Item>
												</Col>

												<Col md={12} sm={12} xs={24}>
												    <Form.Item name="provinsi">
												        {this.generatePovinsi('Provinsi', configClass.apiUrl.provinsi, 'id', 'name')}
													</Form.Item>
												</Col>
												<Col md={12} sm={12} xs={24}>
												    <Form.Item name="kabupaten">
														<Select
															allowClear
															showSearch
															placeholder={'Pilih Kabupaten'}
															optionFilterProp="children"
															onSelect={this.pilihKabupaten}
														>
															{(this.state.loadingKabupaten &&
																<Select.Option value=""><Spin size="small" /></Select.Option>
															)}
															{this.state.loadingKabupaten.count != undefined ? (
																this.state.listKabupaten.results.map((item) => (
																	<Select.Option value={item.id}>{item.name}</Select.Option>
																))
															) : (
																this.state.listKabupaten.map((item) => (
																	<Select.Option value={item.id}>{item.name}</Select.Option>
																))
															)}
														</Select>
													</Form.Item>
											</Col>
											
											<Col md={12} sm={12} xs={24}>
												 <Form.Item name="jenis_usaha">
														<Select
															allowClear
															showSearch
															placeholder={'Pilih Jenis Usaha'}
															optionFilterProp="children"
														>
															<Select.Option value={1}>Wisata</Select.Option>
															<Select.Option value={2}>Transportasi</Select.Option>
															<Select.Option value={3}>Kuliner</Select.Option>
															<Select.Option value={4}>Paket Wisata</Select.Option>
															<Select.Option value={5}>Penginapan</Select.Option>
															<Select.Option value={6}>UMKM</Select.Option>
															<Select.Option value={7}>Event</Select.Option>
														</Select>
													</Form.Item>
												</Col>
												<Col md={12} sm={12} xs={24}>
												    <Form.Item name="is_offline">
														<Select
															allowClear
															showSearch
															placeholder={'Pilih Jenis Transaksi'}
															optionFilterProp="children"
														>
															<Select.Option value={false}>Online</Select.Option>
															<Select.Option value={true}>Offline</Select.Option>
														</Select>
													</Form.Item>
												</Col>
												<Col md={12} sm={12} xs={24}>
												 <Form.Item name="is_paid">
														<Select
															allowClear
															showSearch
															placeholder={'Pilih Status Bayar'}
															optionFilterProp="children"
														>
															<Select.Option value={true}>Sudah Bayar</Select.Option>
															<Select.Option value={false}>Belum Bayar</Select.Option>
														</Select>
													</Form.Item>
												</Col>
											</Row>
											<Row style={{marginBottom: 20}} justify="space-between">
												<Col md={12} sm={24} xs={24}>
													<Row>
														<Col span={12}>
															<Button
																style={{width: '100%'}}
																key="btn-view"
																loading={this.state.loadingData}
																type="primary"
																htmlType="submit"
																size="small"
															>
																{(!this.state.loadingData && <EyeOutlined />)}
																	View Filter
															</Button>
														</Col>
														<Col span={12} style={{paddingLeft: 10}}>
															<Button
																style={{width: '100%'}}
																key="btn-view"
																loading={this.state.loadingData}
																type="danger"
																htmlType="button"
																size="small"
																onClick={() => this.postDataWithDownload()}
															>
																{(!this.state.loadingData && <FileExcelOutlined />)}
																	Export
															</Button>
														</Col>
													</Row>
												</Col>
											</Row>
										</Form>


					        	<Table 
					        		title={() => 
					        			<Row>
					        				<Col lg={8} sm={24} xs={24}><span className="konten-judul">Total {this.state.pagination.total} record</span></Col>
											<Col lg={{span: 8, offset: 8}} sm={24} xs={24}>
												
												<Input.Search 
													size="small"
													placeholder="ketikkan pencarian email" 
													onSearch={value => {
														this.setState({
															searchText: value
														}, () =>{
																this.getData();
														});
													}} 
													allowClear
												/>
												
											</Col>
										</Row>					        			
					        		}
					        		bordered
					        		columns={this.state.columns} 
					        		dataSource={this.state.dataSource} 
					        		loading={this.state.loadingData} 
					        		scroll={{ x: 240, y: 450 }}
					        		size="small"
					        		pagination={
					        			this.state.pagination
					        			// {
					        			// 	showSizeChanger: true,
					        			// 	pageSizeOptions: ['30', '50', '100', '500', '1000', '3000', '5000', '10000', '30000', '50000', '100000', '500000']
					        			// }
					        		}
					        		onChange={this.handleTableChange}
					        	/>
					        </Card>
				        </Col>
				    </Row>
				</div>
			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler,
			ajaxDownloadHandler
		}
	)(LaporanDetailTransaksi);