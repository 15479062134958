import React from 'react';
import { Redirect, Link } from "react-router-dom";
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../store/actions";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert,
	Select,
	Tooltip,
	Spin,
	Form,
	Skeleton,
	Tabs
} from 'antd';
import { StarOutlined, RightOutlined, CheckCircleOutlined, CloseCircleOutlined, ReloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import moment from 'moment';
import QRCode from "qrcode.react";

import Config from '../Config';
import {Helmet} from "react-helmet";
import queryString from 'query-string';

import "../assets/konfirmasi.css";
import TimeAgo from 'javascript-time-ago'
import id from 'javascript-time-ago/locale/id.json'
import ReactTimeAgo from 'react-time-ago'


TimeAgo.addDefaultLocale(id)
TimeAgo.addLocale(id)


const configClass = {
	title: 'konfrimasi transaksi / pembayaran',
	apiUrl: {
		data: 'transaksi/api/transaksi/'
	},

}

class Konfirmasi extends React.Component {
	config;
	
	constructor(props){
	    super(props);
	    this.config = new Config();
	    this.state = {
	    	loading: true,
		    data: null,

		    not_found: false,

		    visible_image_focus: false,
			image_focus_url: null,
			focus_title: "",

			errTitle: "",
			errMsg: "",

			modal_alert: false,
			isSukses: false,

			visible: false,
			loadingModal: false,
			dataketerangan: null,
			errTitleModal: '',
			dataDetail: null,
			idDipilih: null,
			loadingButton: false,
			visibleDetail: false,
			dataDetailTransaksi: null,

			
	    }

	    // this.preview(this.props.match.params.id);
	    
	}

	componentDidMount(){
		// var that = this;
		// setTimeout(function(){
		// 	that.getData(that.props.match.params.id);
		// }, 3000);
		this.getData();
	}

	prosesKonfirmasi(status){
		const id = this.props.match.params.id;

		if(status == 2){
			const statusText = status == 2 ? "Diterima" : "Digagalkan";
			 Modal.confirm({
		    title: 'Konfirmasi '+statusText+' ?',
		    icon: <ExclamationCircleOutlined />,
		    content: 'yakin merubah status transaksi / pembayaran menjadi '+statusText,
		    okText: 'Ya',
		    okType: 'danger',
		    cancelText: 'Tidak',
		    onOk: () => this.prosesUpdate(status),
		    onCancel() {
		      //console.log('Cancel');
		    },
		  });
		}else{
			this.showModal()
		}
	}

	setVisible(val){
		this.setState({
			visible: val
		});
	}

	

	async prosesUpdate(statusValue){
		const id = this.props.match.params.id;

			var datas = new FormData();
			datas.append("status", statusValue);

			message.loading({ content: 'Memproses...', key: 'toast' });

			this.props.ajaxHandler('post', configClass.apiUrl.data+id+'/approved/', datas, true, true)
			.then(() =>{
				if(this.props.isSuccess){
					message.success({ content: 'berhasil mengubah status', key: 'toast' });
					this.getData();
				}else{
					const errTitle = 'gagal mengubah status'
					message.error({ content: errTitle, key: 'toast' });
					this.setState({
						errTitle: errTitle,
						errMsg: this.props.responseMsg
					});
				}
			}).catch((response) => {
				console.log('gagal_post', response);
				message.error({ content: 'kesalahan mengubah status data, coba lagi', key: 'toast' });
			});
		
	}

	async getData() {
	    this.setState({ 
	    	loading: true,  
	    	errTitle: "",
			errMsg: "",
	   	});
	    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

	    this.props
	      .ajaxViewHandler("get", configClass.apiUrl.data + this.props.match.params.id + "/")
	      .then(() => {
	        const data = this.props.responseMsg;
	        if (this.props.isSuccess) {
	          if(data == null){
	            this.setState({
	              not_found: true
	            });
	          }else{
	            this.setState({
	              data: data,
	            });
	            if(data.status == 2 || data.status == 3){
	            	this.setState({
		              isSukses: data.status == 2 ? true : false,
		              modal_alert: true
		            });
	            }
	          }
	        } else {
	          if(this.props.responseCode == 401){
	          	Cookies.remove(this.config.get_cookie_name());
	          	this.props.setConfig({
	          		isLogin: false
	          	});
	          }else{
	          	const errTitle = "error get data";
		          this.setState({
		            errTitle: errTitle,
		            errMsg: this.props.responseMsg,
		          });
		          message.error({ content: errTitle, duration: 2, key: "toast" });
	          }
	        }
	        this.setState({ loading: false });
	      })
	      .catch((response) => {
	        console.log("gagal_get_preview", response);
	        message.error({
	          content: "gagal request data, coba lagi",
	          duration: 2,
	          key: "toast",
	        });
	        this.setState({ loading: false });
	      });
	  }

	  handleSubmitKeterangan = (values) =>{
		var datas = new FormData();
		datas.append("status", "3");
		datas.append("catatan", values.keterangan);

		this.setState({
			loadingButton: true,
		});

		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler('post', configClass.apiUrl.data+this.props.match.params.id+'/approved/', datas, true, true)
			.then(() =>{
				if(this.props.isSuccess){
					this.setVisible(false);
					message.success({ content: 'berhasil mengubah status', key: 'toast' });

					this.getData();
				}else{
					const errTitle = 'gagal mengubah status'
					message.error({ content: errTitle, key: 'toast' });
					this.setState({
						errTitle: errTitle,
						errMsg: this.props.responseMsg
					});
				}
				this.setState({
					loadingButton: false,
				});
		}).catch((response) => {
				console.log('gagal_post', response);
				message.error({ content: 'kesalahan mengubah status data, coba lagi', key: 'toast' });
				this.setState({
					loadingButton: false,
				});
		});
	}

	render() {		
		var total_bayar = 0;
		if(this.props.isLogin == false){
	      return <Redirect to={"/auth"} />
	    }

    	return (
			<div>
		        <Row justify="center" style={{padding: 30}}>
		          <Col span={24} className="judul" style={{textAlign: "center", fontSize: 20, fontWeight: "bold"}}>
		            KONFIRMASI TRANSAKSI / PEMBAYARAN
		            {(this.state.loading &&
		            	<Skeleton active={true} paragraph={false} rows={2} />
		            )}

		            {(this.state.data != null &&
		            	<>
		            		<br />
		            		{this.state.data.kode}
		            		
		            		<div style={{fontStyle: "italic", fontSize: 14, color: "blue"}}>
		            			<ReactTimeAgo date={this.state.data.created_at} locale="en-US"/>
		            		</div>
		            	</>
		            )}
		          </Col>
		          <Col span={24}>
		          	{(this.state.errTitle != "" &&
		          		<Alert
							message={this.state.errTitle}
							description={JSON.stringify(this.state.errMsg)}
							type="error"
							showIcon
							style={{
								marginBottom: 10
							}}
							closable
						/>
		          	)}
			          <Tabs type="card" style={{ marginTop: 20 }}>
		                <Tabs.TabPane 
		                  tab="Data Transaksi"
		                  key="data"
		                >
		                	{(this.state.loading &&
					            	<Skeleton active={true} />
					            )}
		                	{(this.state.data != null &&
			                	<Row>
			                		

			                		<Col span={8} className="titik-dua">Customer</Col>
			                		<Col span={16}>{this.state.data.customer}</Col>

			                		<Col span={8} className="titik-dua">Total</Col>
			                		<Col span={16}>{"Rp. "+this.state.data.harga_unique.toLocaleString('id')}</Col>

			                		<Col span={8} className="titik-dua">Pembayaran</Col>
			                		<Col span={16}>
			                			<img src={this.state.data.payment_detail.icon+"?tr=w-100"} height={30} /><br />
			                			{this.state.data.payment_detail.nomor}<br />
			                			{this.state.data.payment_detail.nama}<br />
			                			{this.state.data.payment_detail.nama_acount}
			                			{(this.state.data.bukti != null && this.state.data.bukti != "" &&
			                				<>
			                					<br />
			                					<span style={{fontWeight: "bold"}}>
			                						Bukti Transfer
			                					</span>
			                					<br />
			                					<LazyLoadImage
											      			height={100}
																			key={"detail-gbr"}
																	    alt={"gbr"}
																	    effect="blur"
																	src={this.state.data.bukti+"?tr=w-100"} 
																	    placeholder={
																	    	<Spin />
																	    }
																	    onClick={() => {
														this.setState({
															image_focus_url: this.state.data.bukti,
															visible_image_focus: true,
															focus_title: "BUKTI TRANSFER"
														})
													}}
																/>
			                					
			                				</>
			                			)}
			                		</Col>
			                		{/*(this.state.data.bukti != null && this.state.data.bukti != "" &&
			                			<>
				                			<Col span={8} className="titik-dua">Bukti Transfer</Col>
				                			<Col span={16}>
				                				<img src={this.state.data.bukti+"?tr=w-100"} height={100} />
				                			</Col>
				                		</>
			                		)*/}

			                	</Row>
		                	)}
		               </Tabs.TabPane>
		               <Tabs.TabPane 
		                  tab="Detail Transaksi"
		                  key="detail"
		                >
		                	{(this.state.loading &&
					            	<Skeleton active={true} />
					            )}
					            {(this.state.data != null &&
					            	<>
					            {this.state.data.details.map((item, idx) => (
				                (total_bayar += (item.harga_akhir * item.quantity)) &&
				                <>

				                  <Alert
				                    style={{marginTop: 5}} 
				                    message={
				                      <Row style={{fontStyle: "italic"}}>
				                        <Col span={10}>{item.produk_nama != null ? item.produk_nama : "no produk name"}</Col>
				                        <Col span={7} style={{paddingLeft: 5, paddingRight: 5}}>{parseFloat(item.harga_akhir).toLocaleString('ID')+" x "+parseFloat(item.quantity)}</Col>
				                        <Col span={7}>{"= "+parseFloat(item.quantity * item.harga_akhir).toLocaleString("ID")}</Col>
				                      </Row>
				                    }
				                    type="error" 
				                  />
				                </>
				              ))}
				              <Alert
                    style={{marginTop: 5}} 
				                    message={
				                      <Row style={{fontStyle: "italic"}}>
				                        <Col span={24}>{"Total: Rp. "+total_bayar.toLocaleString('ID')}</Col>
				                        <Col span={24}>{"Kode Unik: Rp. "+this.state.data.unique_number.toLocaleString('ID')}</Col>
				                        <Col span={24}>{"Grand Total: Rp. "+this.state.data.harga_unique.toLocaleString('ID')}</Col>
				                        
				                        </Row>
				                    }
				                    type="success" 
				              />
				              </>
				              )}
		               </Tabs.TabPane>
		             </Tabs>
	             </Col>
	             {(this.state.data != null &&
		             <Col span={24} className="panel-tombol-aksi-pembayaran">
		             	<Row>
	                          <Col style={{ padding: 5 }} span={8}>
	                            <Button
	                              key="btn-reload"
	                              loading={this.state.loadingButton}
	                              type="default"
	                              htmlType="button"
	                              disabled={this.state.errComponent ? true : false}

	                              onClick={() => this.getData()}
	                              size="large"
	                              style={{width: "100%", background: '#01ADEE', color: '#fff'}}
	                            >
	                              {!this.state.loadingButton && <ReloadOutlined />}
	                            </Button>
	                          </Col>
	                          <Col style={{ padding: 5 }} span={8}>
	                            <Button
	                              key="btn-simpan"
	                              loading={this.state.loadingButton}
	                              type="default"
	                              htmlType="button"
	                              disabled={this.state.errComponent ? true : false}

	                              onClick={() => {
	                              	this.prosesKonfirmasi(2)
	                              }}
	                              size="large"
	                              style={{width: "100%", background: '#05B625', color: '#fff'}}
	                            >
	                              {!this.state.loadingButton && <CheckCircleOutlined />}
	                            </Button>
	                          </Col>
	                          <Col style={{ padding: 5 }} span={8}>
	                            <Button
	                                type="danger"
	                                key="btn-cancel"
	                                disabled={this.state.loadingButton ? true : false}
	                              
	                                onClick={() => {
		                              	this.setVisible(true)
		                              }}
	                                size="large"
	                              
	                                style={{width: "100%"}}
	                            >
	                            	{!this.state.loadingButton && <CloseCircleOutlined />}
	                            </Button>                      
	                          </Col>
	                    </Row>
		             </Col>
		            )}
		        </Row>

		        <Modal
			        centered
			        visible={this.state.visible_image_focus}
			        // onOk={() => this.setVisible(false)}
			        onCancel={() => {
			        	this.setState({
			        		visible_image_focus: false,
			        		image_focus_url: null,
			        		focus_title: "",
			        	});
			        }}
			        width={"100%"}
			        footer={[]}
			     >
			      	<div style={{textAlign: "center"}}>
			      		<h1>{this.state.focus_title}</h1>
			      		<br />
			      		<LazyLoadImage
			      			width={"100%"}
											key={"detail-gbr"}
									    alt={"gbr"}
									    // effect="blur"
									    src={this.state.image_focus_url}
									    placeholder={
									    	<Spin />
									    }
								/>
			      		{/*<img src={this.state.image_focus_url} style={{width: "100%"}} />*/}
			      	</div>
			     </Modal>

			     <Modal
			        centered
			        visible={this.state.modal_alert}
			        // onOk={() => this.setVisible(false)}
			        // onCancel={() => {
			        // 	this.setState({
			        // 		modal_alert: false
			        // 	});
			        // }}
			        width={"80%"}
			        footer={[]}
			        maskClosable={false}
			        closable={false}
			     >
			      	
			      		<Alert
							message={this.state.isSukses ? "sudah dikonfirmasi" : "sudah dibatalkan"}
							description={this.state.isSukses ? "data transaksi / pembayaran tersebut sudah dikonfirmasi" : "data transaksi / pembayaran sudah dibatalkan"}
							type={this.state.isSukses ? "success" : "error"}
							showIcon
						/>			      		
			      	
			     </Modal>


			     <Modal
	        title="Alasan Penolakan"
	        centered
	        visible={this.state.visible}
	        // onOk={() => this.setVisible(false)}
	        onCancel={() => this.setVisible(false)}
	        width={"90%"}
	        footer={[]}
	      >
	      	{(this.state.errTitleModal!='' &&
						        	<Alert
								      message={this.state.errTitleModal}
								      description={JSON.stringify(this.state.errMsgModal)}
								      type="error"
								      showIcon
								      style={{
								      	marginBottom: 10
								      }}
								    />
					)}
					<Row>
						<Col span={24}>
							<Form 
								layout="vertical"
								name="fketerangan"
							 	onFinish={this.handleSubmitKeterangan}
							  size="small"
							>
								<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="ALASAN PEMBATALAN"
										            name="keterangan"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            <Input
										            	style={{textTransform: 'none'}}
										                placeholder="input alasan pembatalan"
										            />
										        </Form.Item>
										    </Col>
								</Row>
								<Row justify="end">
									        <Col style={{padding: 5}}>
									        	<Button 
													key="btn-simpan"
													loading={this.state.loadingButton}
													type="primary" 
													htmlType="submit"
													disabled={this.state.errComponent?true:false}
													size="small"
												>
													{(!this.state.loadingButton && <CheckCircleOutlined />)}
													Simpan
												</Button>
											</Col>
											<Col style={{padding: 5}}>
												
													<Button 
														type="danger"
														key="btn-cancel"
														disabled={this.state.loadingButton?true:false}
													    size="small"
													  onClick={() => this.setState({visible: false})}
													>
														<CloseCircleOutlined /> Cancel
													</Button>
												
										    </Col>
										</Row>
							</Form>
						</Col>
					</Row>

	      	
	      </Modal>


		      </div>

    	)
    }

}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler,
			setConfig
		}
	)(Konfirmasi);