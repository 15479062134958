import React from 'react';
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import Highlighter from 'react-highlight-words';
import { Link } from "react-router-dom";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert,
	Steps,
	Tabs,
	Skeleton,
	Tag
} from 'antd';
import {
  TeamOutlined,
  SearchOutlined,
  PlusOutlined,
  FormOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  DiffOutlined,
  RedoOutlined,
  UserOutlined,
  EyeOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';

import axios from 'axios';
import moment from "moment";

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";

import Config from '../../Config';
import {Helmet} from "react-helmet";

const configClass = {
	title: 'data log activity',
	apiUrl: {
		data: 'auth/api/user/log/'
	},
	pageUrl: {
		form: '/admin/admin/form/'
	},
	breadCrumb: [
		(
			<span>
				<UserOutlined /> administrator
			</span>
		),
		'log activity'
	]

}

// const config = new Config();

class DataLog extends React.Component {
	config;

	constructor(props){
		super(props);
		this.config = new Config();

		this.state = {
			errMsg: '',
			loadingData: false,
			pagination: {pageSize: 25},
			searchText: '',
			searchColumn: '',
			dataSource: [],
			columns: [
				{
					title: 'NAMA',
					width: 150,
					dataIndex: 'user_nama',
					key: 'user_nama'
				},
				{
					title: 'AKTIFITAS',
					width: 150,
					dataIndex: 'action',
					key: 'action'
				},
				{
					title: 'DETAIL AKTIFITAS',
					width: 120,
					dataIndex: 'content',
					key: 'detail_aktifitas',
					align: 'center',
					render: (value, row, index) =>{
						return(
							<Button title="data" className="btn-data" size="small" onClick={() => this.showDetail("DETAIL AKTIFITAS", row)} shape="circle">
								<EyeOutlined />
							</Button>
						)
					}
				},
				{
					title: 'TANGGAL',
					width: 180,
					dataIndex: 'created_at',
					key: 'created_at',
					render: (value, row, index) => {
						return moment(value).format("DD-MM-YYYY HH-mm-ss")
					},
				}

			],

			visibleModal: false,
			dataModal: null,

			loadingDetail: false,
			dataDetail: [],
			errTitleDetail: '',
			errMsgDetail: '',
		}
	}

	UNSAFE_componentWillMount(){
		this.props.initHandler();
	}

	componentDidMount(){
		this.getData();
	}

	showDeleteConfirm(id) {
	  Modal.confirm({
	    title: 'Konfirmasi Hapus?',
	    icon: <ExclamationCircleOutlined />,
	    content: 'yakin menghapus data?',
	    okText: 'Ya',
	    okType: 'danger',
	    cancelText: 'Tidak',
	    onOk: () => this.prosesHapus(id),
	    onCancel() {
	      //console.log('Cancel');
	    },
	  });
	}

	prosesHapus(id) {
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler('delete', configClass.apiUrl.data+id+'/')
		.then(() =>{
			this.setState({
				loadingButton: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil menghapus data', duration: 2, key: 'toast' });
				this.getData();
			}else{
				const errTitle = 'gagal menghapus data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_delete', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	showDetail = (judul, dataModal, is_flat=false) =>{
		if(is_flat == false){
			this.setState({
				visibleModal: true,
				dataModal: {
					is_flat: is_flat,
					judul: judul,
					konten: dataModal != null ? dataModal.content[0].fields : {data: "no detail data"},
				},
			}, () =>{
				this.getDataDetail(dataModal.reference_id, dataModal.app)
			});
		}else{
			this.setState({
				visibleModal: true,
				dataModal: {
					is_flat: is_flat,
					judul: judul,
					konten: {
						kolom: judul,
						value: JSON.stringify(dataModal.content)
					},
				},
			}, () =>{
				this.getDataDetail(dataModal.reference_id, dataModal.app)
			});
		}
	};

	getColumnSearchProps = dataIndex => ({
	    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
	      <div style={{ padding: 8 }}>
	        <Input
	          ref={node => {
	            this.searchInput = node;
	          }}
	          placeholder={`Search ${dataIndex}`}
	          value={selectedKeys[0]}
	          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
	          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
	          style={{ width: 188, marginBottom: 8, display: 'block' }}
	        />
	        <Button
	          type="primary"
	          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
	          icon={<SearchOutlined />}
	          size="small"
	          style={{ width: 90, marginRight: 8 }}
	        >
	          Search
	        </Button>
	        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
	          Reset
	        </Button>
	      </div>
	    ),
	    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	    onFilter: (value, record) =>
	      record[dataIndex]
	        .toString()
	        .toLowerCase()
	        .includes(value.toLowerCase()),
	    onFilterDropdownVisibleChange: visible => {
	      if (visible) {
	        setTimeout(() => this.searchInput.select());
	      }
	    },
	    render: text =>
	      this.state.searchedColumn === dataIndex ? (
	        <Highlighter
	          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
	          searchWords={[this.state.searchText]}
	          autoEscape
	          textToHighlight={text.toString()}
	        />
	      ) : (
	        text
	      ),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
	    confirm();
	    this.setState({
	      searchText: selectedKeys[0],
	      searchedColumn: dataIndex,
	    });
	};

	handleReset = clearFilters => {
	    clearFilters();
	    this.setState({ searchText: '' });
	};

	async getData(limit=this.state.pagination.pageSize, offset=0, search=this.state.searchText){
		this.setState({loadingData: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.data+'?limit='+limit+'&offset='+offset+'&search='+search)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				const pagination = { ...this.state.pagination };
				pagination.total = data.count;
				this.setState({
					dataSource: data.results,
					pagination
				});
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({loadingData: false});
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingData: false});
		});
	}

	handleTableChange = async (pagination, filters, sorter) =>{
    	const newPagination = { ...this.state.pagination };
    	newPagination.pageSize = pagination.pageSize;
		this.setState({
			loadingData: true,
			pagination
		});
		if(pagination.current==1){
			this.getData(pagination.pageSize);
		}else{
			this.getData(pagination.pageSize, (pagination.current - 1)*pagination.pageSize);
		}

		
	}

	async getDataDetail(reference_id, app){
		this.setState({
			loadingDetail: true,
			dataDetail: [],
			errDetail: '',
		});

		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.data+'?reference_id='+reference_id+'&app='+app+'&limit=10000')
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				this.setState({
					loadingDetail: false,
					dataDetail: data.results
				});
			}else{
	      const errTitle = 'error get data detail';
	      this.setState({
	        errTitleDetail: errTitle,
	        errMsgDetail: JSON.stringify(this.props.responseMsg),
	        loadingDetail: false,
	      });
	    }
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			// message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({
	      errTitleDetail: "gagal api detail",
	      errMsgDetail: JSON.stringify(response),
	      loadingDetail: false,
	    });
		});
	}

	getKamusStatusTransaksi(status){
		var result = "";
		if(status == 1){
			result = "Pending";
		}else if(status == 2){
			result = "Diterima"
		}else if(status == 3){
			result = "Dibatalkan"
		}
		return result;
	}

	render() {
		// if(this.props.isSuperadmin == false){
		// 	return <Redirect to="/admin/forbidden" />
		// }
		return(
			<div>
				<Helmet>
					<title>{configClass.title+" | "+this.config.get_site_info().name}</title>
				</Helmet>
				<Breadcrumb>
					{configClass.breadCrumb.map(item => (
						<Breadcrumb.Item>
							{item}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
				<div className="card">
					<Row className="isi">
				       	<Col span={24}>
				        	<Card
				        		className="konten"
					            title={
					            	<Row>
					            		<Col lg={{ span: 20 }} xs={{ span:24 }}>
							            	<span className="konten-judul">
							            		{configClass.title}
							            		{(this.state.loadingData &&
									        		<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="30" alt="loading" />
									        	)}
							            	</span>
							            </Col>
						            	<Col lg={{ span: 4 }} sm={{ span: 24 }} xs={{ span:24 }} style={{textAlign: 'right'}}>
						            		<Button 
										        	loading={this.state.loadingButton}
										        	className="btn-refresh"
										        	size="small"
										        	onClick={() => this.getData()} 
										        >
												          <RedoOutlined />
												    </Button>						            		
						            	</Col>
						            </Row>
					            }
					        >
					        	{(this.state.errMsg!='' &&
						        	<Alert
								      message={this.state.errTitle}
								      description={JSON.stringify(this.state.errMsg)}
								      type="error"
								      showIcon
								      style={{
								      	marginBottom: 10
								      }}
								    />
								)}
					        	<Table 
					        		title={() => 
					        			<Row>
					        				<Col lg={8} sm={24} xs={24}><span className="konten-judul">Total {this.state.pagination.total} record</span></Col>
											<Col lg={{span: 8, offset: 8}} sm={24} xs={24}>
												<Input.Search 
													size="small"
													placeholder="ketikkan pencarian" 
													onSearch={value => {
														this.setState({
															searchText: value
														}, () =>{
																this.getData();
														});
													}} 
													allowClear
												/>										
											</Col>
										</Row>					        			
					        		}
					        		bordered
					        		columns={this.state.columns} 
					        		dataSource={this.state.dataSource} 
					        		loading={this.state.loadingData} 
					        		scroll={{ x: 240, y: 450 }}
					        		size="small"
					        		pagination={this.state.pagination}
					        		onChange={this.handleTableChange}
					        	/>
					        </Card>
				        </Col>
				    </Row>
				</div>

				<Modal
	        title={this.state.dataModal != null ? this.state.dataModal.judul : ""}
	        centered
	        visible={this.state.visibleModal}
	        // onOk={() => this.setVisible(false)}
	        onCancel={() => this.setState({visibleModal: false})}
	        width={700}
	        footer={[]}
	      >
	      	
					{(this.state.errTitleDetail!='' &&
						<Alert
							message={this.state.errTitleDetail}
							description={this.state.errMsgDetail}
							type="error"
							showIcon
							closable
							style={{
								marginBottom: 10
							}}
						/>
					)}
					<Tabs type="card">
				    <Tabs.TabPane tab="Log Terakhir" key="log_terakhir">
				      <Row>
								<Col span={24}>
									{this.state.dataModal != null && this.state.dataModal.is_flat == false && Object.entries(this.state.dataModal.konten).map(([key,value]) =>(
											typeof value != 'object' &&
						          	<Row style={{borderBottom: "1px solid #F0F2F6"}}>
						          		<Col span={12}>{key}</Col>
						          		<Col span={12}>
						          			{": "+(this.config.isTime(value) ? moment(value).format("DD-MM-YYYY HH-mm-ss") : value)}
						          		</Col>
						          	</Row>
					          ))}
{/*
									{(this.state.dataModal != null && this.state.dataModal.is_flat == true &&
											<Row style={{borderBottom: "1px solid #F0F2F6"}}>
						          		<Col span={12}>{this.state.dataModal.konten.kolom}</Col>
						          		<Col span={12}>
						          			{this.state.dataModal.konten.value}
						          		</Col>
						          </Row>
					         )}*/}
								</Col>
							</Row>
				    </Tabs.TabPane>
				    <Tabs.TabPane tab="Detail Riwayat Log" key="log_riwayat">
				    			<Alert
							      message="Petunjuk"
							      description={
							      	<ul>
							      		<li>riwayat ditampilkan dari yang terbaru - terlama</li>
							      		<li>
							      			kode pewarnaan
							      			<ul>
								      			<li><Tag color="#87d068">data terbaru</Tag></li>
								      			<li><Tag color="#f50">data sebelumnya</Tag></li>
								      		</ul>
							      		</li>
							      	</ul>
							      }
							      type="info"
							      showIcon
							      style={{marginBottom: 10}}
							    />
				      
									{(this.state.loadingDetail == true &&
										<Skeleton />
									)}
									{(this.state.dataDetail.length > 0 &&
										<Steps progressDot current={0} direction="vertical">
											{this.state.dataDetail.map((item, idx) => (
												<Steps.Step 
													title={
														<>
															{item.user_nama+" "+item.action+" : "+moment(item.created_at).format("DD-MM-YYYY HH-mm-ss")}
														</>
													} 
													description={
														<>
															{item.content[0].fields != null && Object.entries(item.content[0].fields).map(([key,value]) =>(
																typeof value != 'object' &&
											          	<Row style={{borderBottom: "1px solid #F0F2F6"}}>
											          		<Col span={12}>{key}</Col>
											          		<Col span={12}>
											          			{idx +1 === this.state.dataDetail.length
											          				?
											          					(this.config.isTime(value) ? ": "+moment(value).format("DD-MM-YYYY HH-mm-ss") : ": "+(key == "status" ? this.getKamusStatusTransaksi(value) : value))
											          				:
											          					value == this.state.dataDetail[idx+1].content[0].fields[key]
													          				?
													          					(this.config.isTime(value) ? ": "+moment(value).format("DD-MM-YYYY HH-mm-ss") : ": "+(key == "status" ? this.getKamusStatusTransaksi(value) : value))
													          				:
													          					<>
													          						<Tag color="#87d068">
														          						{(this.config.isTime(value) ? ": "+moment(value).format("DD-MM-YYYY HH-mm-ss") : ": "+(key == "status" ? this.getKamusStatusTransaksi(value) : value))}
														          					</Tag>
														          					<Tag color="#f50">
														          						{(this.config.isTime(this.state.dataDetail[idx+1].content[0].fields[key]) 
														          							? 
														          								": "+moment(this.state.dataDetail[idx+1].content[0].fields[key]).format("DD-MM-YYYY HH-mm-ss") 
														          							: 
														          								": "+(key == "status" ? this.getKamusStatusTransaksi(this.state.dataDetail[idx+1].content[0].fields[key]) : this.state.dataDetail[idx+1].content[0].fields[key]))
														          						}
														          					</Tag>
													          					</>
													          			
											          			}
											          		</Col>
											          	</Row>
										          ))}
														</>
													} 
												/>
											))}
								    </Steps>
							    )}
							
				    </Tabs.TabPane>
				  </Tabs>
					
					<Row justify="end">
						<Col style={{padding: 5}}>
							<Button 
								type="danger"
								key="btn-cancel"
								size="small"
								onClick={() => this.setState({visibleModal: false})}
							>
								<CloseCircleOutlined /> Tutup
							</Button>
						</Col>
					</Row>

	      	
	      </Modal>


			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler
		}
	)(DataLog);