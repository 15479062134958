import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import axios from 'axios';
import {axiosMiddleware, multiClientMiddleware} from 'redux-axios-middleware';
import Config from '../Config';

import Cookies from 'js-cookie';

const conf = new Config();

const client = axios.create({ //all axios can be used, shown in axios documentation
  baseURL: conf.get_server_url(),
  responseType: 'json'
});

const authClient = axios.create({ //all axios can be used, shown in axios documentation
  baseURL: conf.get_server_url(),
  responseType: 'json'
});

authClient.interceptors.request.use(
    config => {
      if (!config.headers.Authorization) {
        config.headers.Authorization = `${Cookies.get(conf.get_cookie_name())}`;
      }

      // alert(JSON.stringify(config))

      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );

export default createStore(
	rootReducer,
	applyMiddleware(
		multiClientMiddleware(
			{
				default: {
					client: authClient
				},
				no_auth: {
					client: client
				}
			}
		)
	)
);
