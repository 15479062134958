import React from 'react';
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import Highlighter from 'react-highlight-words';
import { Link } from "react-router-dom";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert,
	Divider,
	Select,
	Tag
} from 'antd';
import {
  TeamOutlined,
  SearchOutlined,
  PlusOutlined,
  FormOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  DiffOutlined,
  RedoOutlined,
  ClusterOutlined,
  GlobalOutlined, 
  CommentOutlined,
  LikeOutlined,
  ShareAltOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';

import moment from "moment";

import axios from 'axios';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";

import Config from '../../Config';
import {Helmet} from "react-helmet";

const configClass = {
	title: 'data mitra usaha',
	apiUrl: {
		data: 'auth/api/register/',
	},
	pageUrl: {
		form: '/admin/forum/form/'
	},
	breadCrumb: [
		(
			<span>
				<CheckCircleOutlined /> Approvement
			</span>
		),
		'data mitra usaha'
	]

}

const config = new Config();

class DataMitraUsaha extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			errMsg: '',
			loadingData: false,
			pagination: {pageSize: 25},
			searchText: '',
			searchColumn: '',
			dataSource: [],
			columns: [
				{
					title: 'STATUS',
					width: 120,
					dataIndex: 'status',
					key: 'status',
					fixed: 'left',
					render: (value, row, index) => {
						return (
							<div>
								{(value == 1 &&
									<Tag color="warning">pengajuan</Tag>
								)}
								{(value == 2 &&
									<Tag color="processing">proses</Tag>
								)}
								{(value == 3 &&
									<Tag color="success">berhasil</Tag>
								)}
								{(value == 4 &&
									<Tag color="error">gagal</Tag>
								)}
							</div>
						)
					},
				},
				{
					title: 'ID',
					width: 90,
					dataIndex: 'id',
					key: 'id',
				},
				{
					title: 'NAMA',
					width: 150,
					dataIndex: 'nama',
					key: 'nama',
				},
				{
					title: 'EMAIL',
					width: 150,
					dataIndex: 'email',
					key: 'email',
				},
				{
					title: 'USERNAME',
					width: 150,
					dataIndex: 'username',
					key: 'username',
				},
				{
					title: 'NO HP',
					width: 150,
					dataIndex: 'no_hp',
					key: 'no_hp',
				},
				{
					title: 'FOTO KTP',
					width: 120,
					dataIndex: 'ktp',
					key: 'ktp',
					render: (value, row, index) => {
						return value != null && <a href={value} target="_blank"><img src={value} height={50} /></a>
					},
				},
				{
					title: 'FOTO SELFIE',
					width: 120,
					dataIndex: 'selfie',
					key: 'selfie',
					render: (value, row, index) => {
						return value != null && <a href={value} target="_blank"><img src={value} height={50} /></a>
					},
				},
				{
					title: 'SELFIE KTP',
					width: 120,
					dataIndex: 'selfie_ktp',
					key: 'selfie_ktp',
					render: (value, row, index) => {
						return value != null && <a href={value} target="_blank"><img src={value} height={50} /></a>
					},
				},
				{
					title: 'CREATED',
					width: 150,
					dataIndex: 'created',
					key: 'created',
					render: (value, row, index) => {
						return moment(value).format("DD-MM-YYYY")
					},
				},
				{
					title: 'APPROVED AT',
					width: 150,
					dataIndex: 'total_bayar',
					key: 'total_bayar',
					render: (value, row, index) => {
						return moment(value).format("DD-MM-YYYY HH:mm")
					},
				},
				{
					title: 'APPROVED BY',
					width: 150,
					dataIndex: 'approved_by',
					key: 'approved_by',
				},
				{
					title: 'AKSI',
					width: 120,
					dataIndex: 'id',
					key: 'aksi',
					fixed: 'right',
					render: (value, row, index) => {
						return (
							<div>
								
									<Select
										style={{width: '100%'}}
										key={"aksi-"+value}
										onChange={(val) => this.prosesUpdate(value, val)}
										placeholder={row.status_nama}
									>
										<Select.Option value="1">pengajuan</Select.Option>
										<Select.Option value="2">diproses</Select.Option>
										<Select.Option value="3">berhasil</Select.Option>
										<Select.Option value="4">gagal</Select.Option>
									</Select>
								
								{/*{(row.status==2 && 
									<Select
										style={{width: '100%'}}
										key={"aksi-"+value}
										onChange={(val) => this.prosesUpdate(value, val)}
										placeholder={row.status_nama}
									>
										<Select.Option value="1">pengajuan</Select.Option>
										<Select.Option value="3">berhasil</Select.Option>
										<Select.Option value="4">gagal</Select.Option>
									</Select>
								)}
								{(row.status==3 && 
									<Select
										style={{width: '100%'}}
										key={"aksi-"+value}
										onChange={(val) => this.prosesUpdate(value, val)}
										placeholder={row.status_nama}
									>
										<Select.Option value="1">pengajuan</Select.Option>
										<Select.Option value="2">diproses</Select.Option>
										<Select.Option value="4">gagal</Select.Option>
									</Select>
								)}
								{(row.status==4 && 
									<Select
										style={{width: '100%'}}
										key={"aksi-"+value}
										onChange={(val) => this.prosesUpdate(value, val)}
										placeholder={row.status_nama}
									>
										<Select.Option value="1">pengajuan</Select.Option>
										<Select.Option value="2">diproses</Select.Option>
										<Select.Option value="3">berhasil</Select.Option>
									</Select>
								)}*/}
								
							</div>
						)
					},
				}
			],
			visible: false,
			loadingModal: false,
			dataKomentar: null,
			errTitleModal: '',
			dataDetail: null,
		}
	}

	UNSAFE_componentWillMount(){
		this.props.initHandler();
	}

	componentDidMount(){
		this.getData();
	}

	async showModal(detail){
		this.setState({
			loadingModal: true,
			errTitleModal: '',
			errMsgModal: '',
			visible: true,
			dataKomentar: null,
		}, () =>{
			this.props.ajaxViewHandler('get', configClass.apiUrl.komentar+'?komentar='+detail.id)
				.then(() =>{
					const data = this.props.responseMsg;
					if(this.props.isSuccess){
						this.setState({
							dataKomentar: data,
							dataDetail: detail,
						});
			    }else{
			      const errTitle = 'error get data';
			      this.setState({
			        errTitleModal: errTitle,
			        errMsgModal: this.props.responseMsg
			      });
			      message.error({ content: errTitle, duration: 2, key: 'toast' });
			    }
			    this.setState({loadingModal: false});
				}).catch((response) => {
					message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
					this.setState({
						loadingModal: false,
						errTitleModal: "unknown error",
			      errMsgModal: "failed unknown error get data"
					});
			});
		});
	}

	setVisible(val){
		this.setState({
			visible: val
		});
	}

	async prosesUpdate(id, statusValue){
		var datas = new FormData();
		datas.append("status", statusValue);

		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler('post', configClass.apiUrl.data+id+'/proses/', datas, true, true)
		.then(() =>{
			if(this.props.isSuccess){
				message.success({ content: 'berhasil mengubah status', key: 'toast' });
				this.getData();
			}else{
				const errTitle = 'gagal mengubah status'
				message.error({ content: errTitle, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_post', response);
			message.error({ content: 'kesalahan mengubah status data, coba lagi', key: 'toast' });
		});
	}

	showDeleteConfirm(id) {
	  Modal.confirm({
	    title: 'Konfirmasi Hapus?',
	    icon: <ExclamationCircleOutlined />,
	    content: 'yakin menghapus data?',
	    okText: 'Ya',
	    okType: 'danger',
	    cancelText: 'Tidak',
	    onOk: () => this.prosesHapus(id),
	    onCancel() {
	      //console.log('Cancel');
	    },
	  });
	}

	prosesHapus(id) {
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler('delete', configClass.apiUrl.data+id+'/')
		.then(() =>{
			this.setState({
				loadingButton: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil menghapus data', duration: 2, key: 'toast' });
				this.getData();
			}else{
				const errTitle = 'gagal menghapus data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_delete', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	getColumnSearchProps = dataIndex => ({
	    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
	      <div style={{ padding: 8 }}>
	        <Input
	          ref={node => {
	            this.searchInput = node;
	          }}
	          placeholder={`Search ${dataIndex}`}
	          value={selectedKeys[0]}
	          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
	          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
	          style={{ width: 188, marginBottom: 8, display: 'block' }}
	        />
	        <Button
	          type="primary"
	          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
	          icon={<SearchOutlined />}
	          size="small"
	          style={{ width: 90, marginRight: 8 }}
	        >
	          Search
	        </Button>
	        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
	          Reset
	        </Button>
	      </div>
	    ),
	    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	    onFilter: (value, record) =>
	      record[dataIndex]
	        .toString()
	        .toLowerCase()
	        .includes(value.toLowerCase()),
	    onFilterDropdownVisibleChange: visible => {
	      if (visible) {
	        setTimeout(() => this.searchInput.select());
	      }
	    },
	    render: text =>
	      this.state.searchedColumn === dataIndex ? (
	        <Highlighter
	          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
	          searchWords={[this.state.searchText]}
	          autoEscape
	          textToHighlight={text.toString()}
	        />
	      ) : (
	        text
	      ),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
	    confirm();
	    this.setState({
	      searchText: selectedKeys[0],
	      searchedColumn: dataIndex,
	    });
	};

	handleReset = clearFilters => {
	    clearFilters();
	    this.setState({ searchText: '' });
	};

	async getData(limit=this.state.pagination.pageSize, offset=0, search=this.state.searchText){
		this.setState({loadingData: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.data+'?limit='+limit+'&offset='+offset+'&search='+search)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				const pagination = { ...this.state.pagination };
				pagination.total = data.count;
				this.setState({
					dataSource: data.results,
					pagination
				});
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({loadingData: false});
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingData: false});
		});
	}

	handleTableChange = async (pagination, filters, sorter) =>{
    	const newPagination = { ...this.state.pagination };
    	newPagination.pageSize = pagination.pageSize;
		this.setState({
			loadingData: true,
			pagination
		});
		if(pagination.current==1){
			this.getData(pagination.pageSize);
		}else{
			this.getData(pagination.pageSize, (pagination.current - 1)*pagination.pageSize);
		}

		
	}

	render() {
		// if(this.props.isSuperadmin == false){
		// 	return <Redirect to="/admin/forbidden" />
		// }
		return(
			<div>
				<Helmet>
					<title>{configClass.title+" | "+config.get_site_info().name}</title>
				</Helmet>
				<Breadcrumb>
					{configClass.breadCrumb.map(item => (
						<Breadcrumb.Item>
							{item}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
				<div className="card">
					<Row className="isi">
				       	<Col span={24}>
				        	<Card
				        		className="konten"
					            title={
					            	<Row>
					            		<Col lg={{ span: 20 }} xs={{ span:24 }}>
							            	<span className="konten-judul">
							            		{configClass.title}
							            		{(this.state.loadingData &&
									        		<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="30" alt="loading" />
									        	)}
							            	</span>
							            </Col>
						            	<Col lg={{ span: 4 }} sm={{ span: 24 }} xs={{ span:24 }} style={{textAlign: 'right'}}>
						            		<Button 
										        	loading={this.state.loadingButton}
										        	className="btn-refresh"
										        	size="small"
										        	onClick={() => this.getData()} 
										        >
										          <RedoOutlined />
										    </Button>
										    {/*
						            		<Link to={configClass.pageUrl.form}>
							            		<Button 
										        	loading={this.state.loadingButton}
										        	className="btn-primary"
										        	size="small" 
										        >
										          <PlusOutlined /> Tambah
										        </Button>
										    </Link>
										  */}
						            	</Col>
						            </Row>
					            }
					        >
					        	{(this.state.errMsg!='' &&
						        	<Alert
								      message={this.state.errTitle}
								      description={JSON.stringify(this.state.errMsg)}
								      type="error"
								      showIcon
								      style={{
								      	marginBottom: 10
								      }}
								    />
								)}
					        	<Table 
					        		title={() => 
					        			<Row>
					        				<Col lg={8} sm={24} xs={24}><span className="konten-judul">Total {this.state.pagination.total} record</span></Col>
											<Col lg={{span: 8, offset: 8}} sm={24} xs={24}>
												<Input.Search 
													size="small"
													placeholder="ketikkan pencarian" 
													onSearch={value => {
														this.setState({
															searchText: value
														}, () =>{
																this.getData();
														});
													}} 
													allowClear
												/>										
											</Col>
										</Row>					        			
					        		}
					        		bordered
					        		columns={this.state.columns} 
					        		dataSource={this.state.dataSource} 
					        		loading={this.state.loadingData} 
					        		scroll={{ x: 240, y: 450 }}
					        		size="small"
					        		pagination={this.state.pagination}
					        		onChange={this.handleTableChange}
					        	/>
					        </Card>
				        </Col>
				    </Row>
				</div>

				<Modal
	        title="Komentar"
	        centered
	        visible={this.state.visible}
	        // onOk={() => this.setVisible(false)}
	        onCancel={() => this.setVisible(false)}
	        width={1000}
	        footer={[]}
	      >
	      	{(this.state.errTitleModal!='' &&
						        	<Alert
								      message={this.state.errTitleModal}
								      description={JSON.stringify(this.state.errMsgModal)}
								      type="error"
								      showIcon
								      style={{
								      	marginBottom: 10
								      }}
								    />
					)}

	      	{(this.state.dataDetail !=null &&
	      		<Row>
	      			<Col span={4}>Jenis Tanaman</Col>
	      			<Col span={20}>{this.state.dataDetail.jenis_tanaman_nama}</Col>
	      			<Col span={4}>Konten</Col>
	      			<Col span={20} dangerouslySetInnerHTML={{__html: this.state.dataDetail.content}} />
	      			<Col span={24}>
	      				<b>{this.state.dataDetail.count_comment+" KOMENTAR"}</b>
	      			</Col>
	      		</Row>
	      	)}
	        {this.state.dataKomentar != null && this.state.dataKomentar.results.map((item, idx) => (
	        	<>
		        	<Divider orientation="left" orientationMargin="0">{item.user_nama != undefined ? item.user_nama : 'Nama User'}</Divider>
					    <p>
					    	<i>{moment(item.created_at).format("DD-MM-YYYY HH:mm:ss")}</i>
					    	<br />
					      {item.komentar}
					    </p>
				    </>
	        ))}
	      </Modal>

			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler
		}
	)(DataMitraUsaha);