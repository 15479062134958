import React from "react";
import { Link, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Breadcrumb,
  Card,
  Row,
  Col,
  Avatar,
  Form,
  Input,
  Button,
  Space,
  Select,
  DatePicker,
  message,
  Alert,
  Spin,
  Tabs,
  Collapse,
  Panel,
} from "antd";
import {
  DiffOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ClusterOutlined,
} from "@ant-design/icons";
import moment from "moment";

import axios from "axios";
import Config from "../../Config";

import { connect } from "react-redux";
import {
  initHandler,
  ajaxViewHandler,
  ajaxHandler,
  setConfig,
} from "../../store/actions";
import { Helmet } from "react-helmet";
import { Editor } from "@tinymce/tinymce-react";

const FormItem = Form.Item;

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 19,
      offset: 5,
    },
    lg: {
      span: 19,
      offset: 5,
    },
  },
};

const configClass = {
  title: "pengaturan email",
  apiUrl: {
    preview: "pengaturan/api/pengaturan/?tipe=2",
  },
  breadCrumb: [
    <span>
      <DiffOutlined /> Master
    </span>,
    "pengaturan email",
  ],
};

const config = new Config();

class FormPengaturanEmail extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      errComponent: false,
      errTitle: "",
      errMsg: "",
      loadingButton: false,

      aksi: "tambah",
      method: "post",
      idLama: "",

      register: "",
      forget_password: "",
      orders_offline: "",
      verify_forget: "",
      etiket: "",
    };
  }

  UNSAFE_componentWillMount() {
    this.props.initHandler();
  }

  componentDidMount() {
    this.preview();
  }

  loadDefaultTemplate() {
  	const teks_register = "<div style='background: white;height:100px;'>" +
        '<img src="' +
        config.get_domain_admin()+"logo.png" +
        '" height="100" align="left" />' +
        '<img src="' +
        config.get_domain_admin()+"logo_teks.png" +
        '" height="100" align="left" />' +
        "<div style=\"clear: both\"></div>"+
        "</div>" +
        "<div style='clear: both'>" +
        "<p>satu langkah lagi untuk registrasi akun plesiran anda. silahkan klik link berikut ini untuk aktivasi akun Plesiran anda<br />" +
        "link aktivasi :<br /><br />" +
        '<a href="@link" target="_blank">' +
        '<button type="button" style="background: #01ADEE; color: #fff;border-radius: 10px;border: none;padding: 10px;">' +
        "klik untuk aktivasi" +
        "</button>" +
        "</a>" +
        "</p>" +
        "<p style='margin-top: 30px;font-weight: bold'>jika anda tidak merasa melakukan regsitrasi menggunakan email tersebut mohon abaikan pesan ini.</p>" +
        "<p>email ini dikirim otomatis, anda tidak perlu menjawabnya.</p>" +
        "</div>";
    const teks_forget_password = "<div style='background: white;height:100px;'>" +
        '<img src="' +
        config.get_domain_admin()+"logo.png" +
        '" height="100" align="left" />' +
        '<img src="' +
        config.get_domain_admin()+"logo_teks.png" +
        '" height="100" align="left" />' +
        "</div>" +
        "<div style='clear: both'>" +
        "<p>anda melakukan permintaan lupa password akun Plesiran. Silahkan klik link berikut ini untuk proses reset password anda<br />link reset password : <br /><br />" +
        '<a href="@link" target="_blank">' +
        '<button type="button" style="background: #01ADEE; color: #fff;border-radius: 10px;border: none;padding: 10px;">' +
        "klik untuk reset password" +
        "</button>" +
        "</a>" +
        "<p style='margin-top: 30px;font-weight: bold'>jika anda tidak merasa melakukan reset password menggunakan email tersebut mohon abaikan pesan ini.</p>" +
        "<p>email ini dikirim otomatis, anda tidak perlu menjawabnya.</p>" +
        "</div>";
    const teks_verify_forget = "<div style='background: white;height:100px;'>" +
        '<img src="' +
        config.get_domain_admin()+"logo.png" +
        '" height="100" align="left" />' +
        '<img src="' +
        config.get_domain_admin()+"logo_teks.png" +
        '" height="100" align="left" />' +
        "</div>" +
        "<div style='clear: both'>" +
        "<p>Password akun Plesiran anda berhasil di reset, berikut adalah biodata akun terbaru anda.<br />" +
        '<table border="0">' +
        "<tr>" +
        "<td>email</td>" +
        "<td>: @email</td>" +
        "</tr>" +
        "<tr>" +
        "<td>no hp</td>" +
        "<td>: @no_hp</td>" +
        "</tr>" +
        "<tr>" +
        "<td>username</td>" +
        "<td>: @username</td>" +
        "</tr>" +
        "<tr>" +
        "<td>password</td>" +
        "<td>: @password</td>" +
        "</tr>" +
        "</table>" +
        "</p>" +
        "<p style='margin-top: 30px;font-weight: bold'>jika anda tidak merasa melakukan permintaan reset password segera hubungi Admin Plesiran.</p>" +
        "<p>email ini dikirim otomatis, anda tidak perlu menjawabnya.</p>" +
        "</div>";
    const teks_orders_offline = "<div style='background: white;height:100px;'>" +
        '<img src="' +
        config.get_domain_admin()+"logo.png" +
        '" height="100" align="left" />' +
        '<img src="' +
        config.get_domain_admin()+"logo_teks.png" +
        '" height="100" align="left" />' +
        "</div>" +
        "<div style='clear: both'>" +
        "<p>Pemesanan berhasil, selanjutnya silahkan lakukan pembayaran ke kasir dengan menunjukan kode pembayaran melalui link dibawah ini.<br />" +
        "link kode bayar :<br /><br />" +
        '<a href="@link" target="_blank">' +
        '<button type="button" style="background: #01ADEE; color: #fff;border-radius: 10px;border: none;padding: 10px;">' +
        "klik untuk melihat kode pembayaran" +
        "</button>" +
        "</a>" +
        "</p>" +
        "<p style='margin-top: 30px;font-weight: bold'>jika anda tidak merasa melakukan transaksi tersebut abaikan email ini.</p>" +
        "<p>email ini dikirim otomatis, anda tidak perlu menjawabnya.</p>" +
        "</div>";
    const teks_etiket = "<div style='background: white;height:100px;'>" +
        '<img src="' +
        config.get_domain_admin()+"logo.png" +
        '" height="100" align="left" />' +
        '<img src="' +
        config.get_domain_admin()+"logo_teks.png" +
        '" height="100" align="left" />' +
        "</div>" +
        "<div style='clear: both'>" +
        "<p>Pembayaran berhasil diproses, silahkan tunjukkan kode E-Tiket anda ke Petugas Loket Masuk jika anda melakukan pembayaran untuk kategori wisata. Berikut adalah link E-Tiket Plesiran anda." +
        "<br /><br />" +
        '<a href="@link" target="_blank">' +
        '<button type="button" style="background: #01ADEE; color: #fff;border-radius: 10px;border: none;padding: 10px;">' +
        "klik untuk melihat E-Tiket" +
        "</button>" +
        "</a></p>" +
        "<p style='margin-top: 30px;font-weight: bold'>jika anda tidak merasa melakukan transaksi tersebut abaikan email ini.</p>" +
        "<p>email ini dikirim otomatis, anda tidak perlu menjawabnya.</p>" +
        "</div>";




    this.formRef.current.setFieldsValue({
      register: teks_register,
      forget_password: teks_forget_password,
      verify_forget: teks_verify_forget,
      orders_offline: teks_orders_offline,
      etiket: teks_etiket,
    });
    this.setState({
    	register: teks_register,
    	forget_password: teks_forget_password,
    	verify_forget: teks_verify_forget,
    	orders_offline: teks_orders_offline,
    	etiket: teks_etiket
    });
  }

  async preview(id) {
    message.loading({
      content: "Menampilkan data sebelumnya...",
      duration: 0,
      key: "toast",
    });

    this.props
      .ajaxViewHandler("get", configClass.apiUrl.preview)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (data.results.length > 0) {
            // this.formRef.current.setFieldsValue(data.results[0]);
            // this.setState({
            //     	aksi: 'edit',
            //     	method: 'put',
            //     	idLama: data.results[0].id+'/'
            //     });
            this.formRef.current.setFieldsValue(data.results[0].config);
            this.setState({
            	register: data.results[0].config.register,
            	forget_password: data.results[0].config.forget_password,
            	orders_offline: data.results[0].config.orders_offline,
            	verify_forget: data.results[0].config.verify_forget,
            	etiket: data.results[0].config.etiket,
            })
          } else {
            this.setState({
              aksi: "tambah",
              method: "post",
              idLama: "",
            });
          }
          message.destroy();
        } else {
          const errTitle = "error preview data lama";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  handleSubmit = (values) => {
    // var params = new FormData();
    // for ( var key in values ) {
    // 	if(key == 'gudang'){
    // 		params.append(key, (values[key]).join());
    // 	}else{
    // 		params.append(key, (values[key]).toString());
    // 	}
    //    }

    // params.append("tipe", "1");
    // params.append("config", JSON.stringify({

    // }));

    var params = {
      tipe: "2",
      config: {
      	register: this.state.register,
	      forget_password: this.state.forget_password,
	      orders_offline: this.state.orders_offline,
	      verify_forget: this.state.verify_forget,
	      etiket: this.state.etiket
      },
    };

    this.postData(params);
  };

  async postData(datas) {
    message.loading({ content: "Memproses...", key: "toast" });
    this.setState({
      errMsg: "",
    });

    this.props
      .ajaxHandler("post", configClass.apiUrl.preview, datas, false, true)
      .then(() => {
        this.setState({
          loadingButton: false,
        });
        if (this.props.isSuccess) {
          message.success({
            content: "berhasil menyimpan data",
            duration: 2,
            key: "toast",
          });
          // this.setState({
          //      aksi: 'edit',
          //      method: 'put',
          //      idLama: this.props.responseMsg.id+'/'
          //    });
        } else {
          const errTitle = "gagal menyimpan data";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
        }
      })
      .catch((response) => {
        console.log("gagal_post", response);
        this.setState({
          loadingButton: false,
        });
        message.error({
          content: "kesalahan pengiriman data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  render() {
    // if(this.props.isSuperadmin == false){
    // 	return <Redirect to="/admin/forbidden" />
    // }
    // if(this.props.isRedirect){
    // 	return <Redirect to={configClass.pageUrl.data} />
    // }

    return (
      <div>
        <Helmet>
          <title>
            {configClass.title + " | " + config.get_site_info().name}
          </title>
        </Helmet>
        <Breadcrumb>
          {configClass.breadCrumb.map((item) => (
            <Breadcrumb.Item>{item}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div className="card">
          <Row className="isi">
            <Col lg={24} sm={24} xs={24}>
              <Card className="konten">
                {this.state.errMsg != "" && (
                  <Alert
                    message={this.state.errTitle}
                    description={JSON.stringify(this.state.errMsg)}
                    type="error"
                    showIcon
                    style={{
                      marginBottom: 10,
                    }}
                  />
                )}{" "}
                <Form
                  layout="vertical"
                  name="fdata"
                  onFinish={this.handleSubmit}
                  size="small"
                  ref={this.formRef}
                  initialValues={{}}
                >
                  <Collapse accordion defaultActiveKey="registrasi">
                    <Collapse.Panel header="Registrasi" key="registrasi">
                      <Row justify="space-between">
                        <Col lg={24} sm={24} xs={24}>
                          <Form.Item
                            label="ISI EMAIL REGISTRASI"
                            name="register"
                            rules={[
                              {
                                required: true,
                                message: "harus diisi",
                              },
                            ]}
                          >
                            <Editor
                              apiKey="8x2j9jm48a5i0twky98gt5cdzb9q1d6xl9wreep58ab8ogr7"
                              init={{
                                height: 350,
                                menubar: true,
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help wordcount",
                                ],
                                toolbar:
                                  "undo redo | formatselect | bold italic backcolor | \
															             alignleft aligncenter alignright alignjustify | \
															             bullist numlist outdent indent | removeformat | help",
                                //toolbar2: "print preview media | forecolor backcolor emoticons",
                                image_advtab: true,
                                file_picker_callback: function (
                                  callback,
                                  value,
                                  meta
                                ) {
                                  if (meta.filetype == "image") {
                                    document.getElementById("upload").click();
                                    document
                                      .getElementById("upload")
                                      .addEventListener("change", function () {
                                        var file = this.files[0];
                                        var reader = new FileReader();
                                        reader.onload = function (e) {
                                          callback(e.target.result, {
                                            alt: "",
                                          });
                                        };
                                        reader.readAsDataURL(file);
                                      });
                                  }
                                },
                              }}
                              onEditorChange={(content, editor) => {
                                this.setState({
                                  register: content,
                                });
                              }}
                            />
                          </Form.Item>
                          <input
                            name="image"
                            type="file"
                            id="upload"
                            class="hidden"
                            onchange=""
                            style={{ display: "none" }}
                          />
                        </Col>
                      </Row>
                    </Collapse.Panel>
                    <Collapse.Panel header="Lupa Password" key="lupa_pwd">
                      <Row justify="space-between">
                        <Col lg={24} sm={24} xs={24}>
                          <Form.Item
                            label="ISI EMAIL LUPA PASSWORD"
                            name="forget_password"
                            rules={[
                              {
                                required: true,
                                message: "harus diisi",
                              },
                            ]}
                          >
                            <Editor
                              apiKey="8x2j9jm48a5i0twky98gt5cdzb9q1d6xl9wreep58ab8ogr7"
                              init={{
                                height: 350,
                                menubar: true,
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help wordcount",
                                ],
                                toolbar:
                                  "undo redo | formatselect | bold italic backcolor | \
															             alignleft aligncenter alignright alignjustify | \
															             bullist numlist outdent indent | removeformat | help",
                                //toolbar2: "print preview media | forecolor backcolor emoticons",
                                image_advtab: true,
                                file_picker_callback: function (
                                  callback,
                                  value,
                                  meta
                                ) {
                                  if (meta.filetype == "image") {
                                    document.getElementById("upload2").click();
                                    document
                                      .getElementById("upload2")
                                      .addEventListener("change", function () {
                                        var file = this.files[0];
                                        var reader = new FileReader();
                                        reader.onload = function (e) {
                                          callback(e.target.result, {
                                            alt: "",
                                          });
                                        };
                                        reader.readAsDataURL(file);
                                      });
                                  }
                                },
                              }}
                              onEditorChange={(content, editor) => {
                                this.setState({
                                  forget_password: content,
                                });
                              }}
                            />
                          </Form.Item>
                          <input
                            name="image"
                            type="file"
                            id="upload2"
                            class="hidden"
                            onchange=""
                            style={{ display: "none" }}
                          />
                        </Col>
                      </Row>
                    </Collapse.Panel>
                    <Collapse.Panel
                      header="Verifiy Forget Password"
                      key="verify_forget"
                    >
                      <Row justify="space-between">
                        <Col lg={24} sm={24} xs={24}>
                          <Form.Item
                            label="ISI EMAIL VERIFIED RESET PASSWORD"
                            name="verify_forget"
                            rules={[
                              {
                                required: true,
                                message: "harus diisi",
                              },
                            ]}
                          >
                            <Editor
                              // initialValue={

                              // }
                              apiKey="8x2j9jm48a5i0twky98gt5cdzb9q1d6xl9wreep58ab8ogr7"
                              init={{
                                height: 350,
                                menubar: true,
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help wordcount",
                                ],
                                toolbar:
                                  "undo redo | formatselect | bold italic backcolor | \
															             alignleft aligncenter alignright alignjustify | \
															             bullist numlist outdent indent | removeformat | help",
                                //toolbar2: "print preview media | forecolor backcolor emoticons",
                                image_advtab: true,
                                file_picker_callback: function (
                                  callback,
                                  value,
                                  meta
                                ) {
                                  if (meta.filetype == "image") {
                                    document.getElementById("upload3").click();
                                    document
                                      .getElementById("upload3")
                                      .addEventListener("change", function () {
                                        var file = this.files[0];
                                        var reader = new FileReader();
                                        reader.onload = function (e) {
                                          callback(e.target.result, {
                                            alt: "",
                                          });
                                        };
                                        reader.readAsDataURL(file);
                                      });
                                  }
                                },
                              }}
                              onEditorChange={(content, editor) => {
                                this.setState({
                                  verify_forget: content,
                                });
                              }}
                            />
                          </Form.Item>
                          <input
                            name="image"
                            type="file"
                            id="upload3"
                            class="hidden"
                            onchange=""
                            style={{ display: "none" }}
                          />
                        </Col>
                      </Row>
                    </Collapse.Panel>
                    <Collapse.Panel
                      header="Payment Order Offline"
                      key="orders_offline"
                    >
                      <Row justify="space-between">
                        <Col lg={24} sm={24} xs={24}>
                          <Form.Item
                            label="ISI EMAIL PEMBAYARAN ORDER OFFLINE"
                            name="orders_offline"
                            rules={[
                              {
                                required: true,
                                message: "harus diisi",
                              },
                            ]}
                          >
                            <Editor
                              // initialValue={

                              // }
                              apiKey="8x2j9jm48a5i0twky98gt5cdzb9q1d6xl9wreep58ab8ogr7"
                              init={{
                                height: 350,
                                menubar: true,
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help wordcount",
                                ],
                                toolbar:
                                  "undo redo | formatselect | bold italic backcolor | \
															             alignleft aligncenter alignright alignjustify | \
															             bullist numlist outdent indent | removeformat | help",
                                //toolbar2: "print preview media | forecolor backcolor emoticons",
                                image_advtab: true,
                                file_picker_callback: function (
                                  callback,
                                  value,
                                  meta
                                ) {
                                  if (meta.filetype == "image") {
                                    document.getElementById("upload4").click();
                                    document
                                      .getElementById("upload4")
                                      .addEventListener("change", function () {
                                        var file = this.files[0];
                                        var reader = new FileReader();
                                        reader.onload = function (e) {
                                          callback(e.target.result, {
                                            alt: "",
                                          });
                                        };
                                        reader.readAsDataURL(file);
                                      });
                                  }
                                },
                              }}
                              onEditorChange={(content, editor) => {
                                this.setState({
                                  orders_offline: content,
                                });
                              }}
                            />
                          </Form.Item>
                          <input
                            name="image"
                            type="file"
                            id="upload4"
                            class="hidden"
                            onchange=""
                            style={{ display: "none" }}
                          />
                        </Col>
                      </Row>
                    </Collapse.Panel>
                    <Collapse.Panel header="E-Tiket Order Offline" key="etiket">
                      <Row justify="space-between">
                        <Col lg={24} sm={24} xs={24}>
                          <Form.Item
                            label="ISI EMAIL E-TIKET ORDER OFFLINE"
                            name="etiket"
                            rules={[
                              {
                                required: true,
                                message: "harus diisi",
                              },
                            ]}
                          >
                            <Editor
                              // initialValue={

                              // }
                              apiKey="8x2j9jm48a5i0twky98gt5cdzb9q1d6xl9wreep58ab8ogr7"
                              init={{
                                height: 350,
                                menubar: true,
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help wordcount",
                                ],
                                toolbar:
                                  "undo redo | formatselect | bold italic backcolor | \
															             alignleft aligncenter alignright alignjustify | \
															             bullist numlist outdent indent | removeformat | help",
                                //toolbar2: "print preview media | forecolor backcolor emoticons",
                                image_advtab: true,
                                file_picker_callback: function (
                                  callback,
                                  value,
                                  meta
                                ) {
                                  if (meta.filetype == "image") {
                                    document.getElementById("upload5").click();
                                    document
                                      .getElementById("upload5")
                                      .addEventListener("change", function () {
                                        var file = this.files[0];
                                        var reader = new FileReader();
                                        reader.onload = function (e) {
                                          callback(e.target.result, {
                                            alt: "",
                                          });
                                        };
                                        reader.readAsDataURL(file);
                                      });
                                  }
                                },
                              }}
                              onEditorChange={(content, editor) => {
                                this.setState({
                                  orders_offline: content,
                                });
                              }}
                            />
                          </Form.Item>
                          <input
                            name="image"
                            type="file"
                            id="upload5"
                            class="hidden"
                            onchange=""
                            style={{ display: "none" }}
                          />
                        </Col>
                      </Row>
                    </Collapse.Panel>
                  </Collapse>
                  <Row justify="end">
                    <Col style={{ padding: 5 }}>
                      <Button
                        key="btn-simpan"
                        loading={this.state.loadingButton}
                        type="primary"
                        htmlType="submit"
                        disabled={this.state.errComponent ? true : false}
                        size="small"
                      >
                        {!this.state.loadingButton && <CheckCircleOutlined />}
                        Simpan
                      </Button>
                    </Col>
                    <Col style={{ padding: 5 }}>
                      <Button
                      	htmlType="button"
                        type="danger"
                        key="btn-cancel"
                        disabled={this.state.loadingButton ? true : false}
                        size="small"
                        onClick={() => this.preview()}
                      >
                        <CloseCircleOutlined /> Cancel
                      </Button>
                    </Col>
                    <Col style={{ padding: 5 }}>
                      <Button
                      	className="btn-default"
                      	htmlType="button"
                        // type="default"
                        key="btn-default"
                        disabled={this.state.loadingButton ? true : false}
                        size="small"
                        onClick={() => this.loadDefaultTemplate()}
                      >
                        Load Default Template
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  initHandler,
  setConfig,
  ajaxViewHandler,
  ajaxHandler,
})(FormPengaturanEmail);
