import React from 'react';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import {
    initHandler,
    ajaxViewHandler,
    ajaxHandler,
    setConfig,
} from "../store/actions";
import {
    Breadcrumb,
    Card,
    Row,
    Col,
    Input,
    Button,
    Table,
    Modal,
    message,
    Alert,
    Select,
    Tooltip,
    Spin,
    Form,
    Skeleton
} from 'antd';

import moment from 'moment';
import QRCode from "qrcode.react";

import Config from '../Config';
import { Helmet } from "react-helmet";
import queryString from 'query-string';
import jsPDF from 'jspdf';
import "../assets/tiket.css";




const configClass = {
    title: 'cetak tiket',
    apiUrl: {
        tiket: "transaksi/api/qrgate/",
        usaha: "mitra/api/usaha/",
    },

}

class CetakTiket extends React.Component {
    config;

    constructor(props) {
        super(props);
        this.config = new Config();
        this.state = {
            loading: false,
            data_tiket: [],

            data_usaha: null,
            not_found: false,
            dataResult: null,

        }

        // this.preview(this.props.match.params.id);

    }

    componentDidMount() {
        this.getData(this.props.match.params.id);
    }

    async getData(id) {
        this.setState({ loading: true });
        //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

        this.props
            .ajaxViewHandler("get", configClass.apiUrl.usaha + id)
            .then(() => {
                const data = this.props.responseMsg;
                if (this.props.isSuccess) {
                    if (data == null) {
                        this.setState({
                            not_found: true
                        });
                    } else {
                        this.setState({
                            data_usaha: data,
                        }, () => {
                            this.getTiket(id)
                            // setTimeout(function(){
                            // 	window.print();
                            // }, 2000);
                        });
                    }
                } else {
                    const errTitle = "error get data";
                    this.setState({
                        errTitle: errTitle,
                        errMsg: this.props.responseMsg,
                    });
                    message.error({ content: errTitle, duration: 2, key: "toast" });
                    this.setState({ loading: false });
                }
            })
            .catch((response) => {
                console.log("gagal_get_preview", response);
                message.error({
                    content: "gagal request data, coba lagi",
                    duration: 2,
                    key: "toast",
                });
                this.setState({ loading: false });
            });
    }

    async getTiket(id) {
        // this.setState({ loading: true });
        //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

        this.props
            .ajaxViewHandler("get", configClass.apiUrl.tiket + "?usaha=" + id + "&limit=1000000")
            .then(() => {
                const data = this.props.responseMsg;
                if (this.props.isSuccess) {
                    if (data == null) {
                        this.setState({
                            not_found: true
                        });
                    } else {
                        this.setState({
                            data_tiket: data.results.data,
                        }, () => {
                            // setTimeout(function(){
                            // 	window.print();
                            // }, 2000);
                            var that = this;
                            setTimeout(function () {
                                // window.print();
                                that.jsPdfGenerator();

                            }, 100);
                        });
                    }
                } else {
                    const errTitle = "error get data";
                    this.setState({
                        errTitle: errTitle,
                        errMsg: this.props.responseMsg,
                    });
                    message.error({ content: errTitle, duration: 2, key: "toast" });
                }
                this.setState({ loading: false });
            })
            .catch((response) => {
                console.log("gagal_get_preview", response);
                message.error({
                    content: "gagal request data, coba lagi",
                    duration: 2,
                    key: "toast",
                });
                this.setState({ loading: false });
            });
    }

    generateBarcode(val) {
        return (
            <QRCode
                value={val}
                size={30}
                renderAs="canvas"
            />
        );
    }
    jsPdfGenerator = () => {
        // Example From https://parall.ax/products/jspdf
        const pjg = this.config.mm_to_pixel(20);
        const lbr = this.config.mm_to_pixel(30);
        var doc = new jsPDF('p', 'pt', [pjg, lbr]);

        var counter = 1;
        this.state.data_tiket.map((item, idx) => {
            let x = this.config.cm_to_pixel(0.1);
            let y = this.config.cm_to_pixel(0.36);


            if (counter > 1) {
                doc.addPage();
                // x = this.config.cm_to_pixel(0.36);
                //     		y = this.config.cm_to_pixel(0.36);
            }

            // const splitNamaPasien = doc.splitTextToSize(this.state.data_registrasi.pasien_nama.substring(0, 50), this.config.cm_to_pixel(5));
            // doc.setFontType('bold');
            doc.setFontSize(8);
            // doc.text(x, y, this.state.data_usaha.nama.substring(0, 30));
            // doc.text(x, y, this.state.data_usaha.nama);
            var splitText = doc.splitTextToSize(this.state.data_usaha.nama, pjg - this.config.cm_to_pixel(0.1));
            // doc.text(x, y, this.state.data_usaha.nama);
            doc.text(x, y, splitText);

            // var canvas = this.generateBarcode(item.qr);
            // var image = canvas.toDataURL('image/png');
            // var oImg = document.createElement("img");
            // oImg.setAttribute('src', canvas);
            // oImg.setAttribute('alt', 'na');
            // oImg.setAttribute('height', '1px');
            // oImg.setAttribute('width', '1px');
            // document.body.appendChild(oImg);

            // img = canvas;
            // image.id = "pic";

            // doc.addImage(<canvas><QRCode
            //     value={item.qr}
            //     size={30}
            //     renderAs="canvas"
            // /></canvas>, 'JPEG', this.config.cm_to_pixel(3 + 0.36), 2.2, this.config.cm_to_pixel(1.5), this.config.cm_to_pixel(1.5));




            y += this.config.cm_to_pixel(0.3);

            counter += 1;

        });
        this.setState({
            dataResult: doc.output('datauristring')
        });
        // doc.save('tiket_' + this.state.data_usaha.nama + '.pdf');
    }

    setCenterText(doc, text, y) {
        var textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
        doc.text(textOffset, y, text);
    }


    render() {
        return (
            <div>
                {/* <React.Fragment>
                    <iframe frameBorder={0} src={this.state.dataResult} width={"100%"} height={780} />
                </React.Fragment> */}
                {this.state.data_tiket.map((item, idx) => (
                    <div className='tiket-panel'>
                        <Row>
                            <Col span={10} className='qr-panel'>
                                <QRCode
                                    value={item.qr}
                                    size={50}
                                />
                                <div className='tiket-footer'>
                                    powered by
                                    <br />
                                    <span className='bold'>
                                        plesiran.co.id
                                    </span>
                                </div>

                            </Col>
                            <Col span={14}>
                                <div className='tiket-title'>{this.state.data_usaha.nama}</div>

                                <div className='tiket-keterangan'>
                                    {item.urutan+" - "+item.nama}
                                </div>
                            </Col>
                        </Row>


                    </div>
                ))}

            </div>

        )
    }

}

const mapStateToProps = function (state) {
    return state.configReducers
}

export default connect(
    mapStateToProps,
    {
        initHandler,
        setConfig,
        ajaxViewHandler,
        ajaxHandler,
        setConfig
    }
)(CetakTiket);