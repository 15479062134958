import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
  Layout,
  Menu,
  Avatar,
  Space,
  BackTop,
  Drawer,
  Button
} from 'antd';
import {
  ClusterOutlined,
  DashboardOutlined,
  HomeOutlined,
  HeartOutlined,
  TeamOutlined,
  SnippetsOutlined,
  MailOutlined,
  GroupOutlined,
  LineChartOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  GlobalOutlined,
  DropboxOutlined,
  BugOutlined,
  LoginOutlined,
  SettingOutlined,
  BankOutlined,
  FolderOpenOutlined,
  SmileOutlined,
  DatabaseOutlined,
  ToolOutlined,
  LayoutOutlined,
  UsergroupAddOutlined,
  ShoppingOutlined,
  DiffOutlined,
  SwapOutlined,
  ShoppingCartOutlined,
  ArrowsAltOutlined,
  CarOutlined,
  DollarOutlined,
  CompressOutlined,
  BorderInnerOutlined,
  ScissorOutlined,
  CreditCardOutlined,
  CloudOutlined,
  ReadOutlined,
  CheckCircleOutlined,
  CloseOutlined,
  PicCenterOutlined
} from '@ant-design/icons';
import Cookies from 'js-cookie';
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import { setConfig, ajaxViewHandler } from "../store/actions";

import Config from '../Config';

const { SubMenu } = Menu;
const { Sider } = Layout;

const configClass = {
  apiUrl: {
    sider: ""
  }
}

class AdminSider extends PureComponent {
  config;

  constructor(props) {
    super(props);
    this.config = new Config();

    this.state = {
      menuAktif: "",
      menuExpand: "",
      openKeys: [],

      showMenu: true,
    };
  }

  componentDidMount() {
    //this.getInfoSidebar();
  }

  onMenuExpand = (listOpen) => {
    console.log('expanded', listOpen);
    if (listOpen.length == 1) {
      console.log('kode1')
      this.setState({
        menuExpand: listOpen[0]
      })
    } else {
      console.log('kode2', listOpen[listOpen.length - 1])
      this.setState({
        menuExpand: listOpen[listOpen.length - 1]
      })
    }
    // if (selectionToolIsDisable) {
    //   const openedKeyIndex = openKeys.indexOf('menu1')
    //     if (openedKeyIndex !== -1) {
    //         const _openKeys = [...openKeys]
    //         _openKeys.splice(openedKeyIndex, 1)
    //         setOpenKeys(_openKeys)
    //     }
    // } else {
    //     setOpenKeys([...openKeys, 'menu1'])
    // }
    // this.setState({
    //   defaultOpenKeys: ["mn_task"]
    // })
  };

  notifyMe(msg) {
    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
      // If it's okay let's create a notification
      var notification = new Notification(msg);
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          var notification = new Notification(msg);
        }
      });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
  }

  async getInfoSidebar() {
    this.props.ajaxViewHandler('get', configClass.apiUrl.sider)
      .then(() => {
        const data = this.props.responseMsg.results[0];
        if (this.props.isSuccess) {
          /*
          this.setState({
            logoDesa: data.logo!=null&&data.logo!=''?data.logo:process.env.PUBLIC_URL + "/logo_landscape.png",
            namaDesa: data.name,
          });
          */
        } else {
          /*
          this.setState({
            namaDesa: 'gagal menampilkan info desa',
          });
          */
        }
      }).catch((response) => {
        /*
        this.setState({
            namaDesa: 'gagal menampilkan info desa',
        });
        */
      });
  }

  render() {
    return (
      <PerfectScrollbar>
        <Helmet>
          <title>{"admin | " + (this.props.nama != undefined ? this.props.nama : this.config.get_site_info().name)}</title>
        </Helmet>
        <Sider
          breakpoint="lg"
          width={this.props.isMobile ? '100%' : 256}

          trigger={null}
          collapsible
          collapsed={this.props.collapsed}
          className="sider"

          style={{
            height: this.state.showMenu == true ? "100vh" : 74
          }}
        /*
        onCollapse={(collapsed, type) => {
          this.props.setConfig({
            collapsed: collapsed
          });
        }}
        */
        /*
        onBreakpoint={
          console.log('break')
          //!this.props.isMobile && this.props.setConfig({collapsed:!this.props.collapsed})
        }
        */
        >
          <div className="brand">
            <div className="logo">
              <img alt="logo" src={process.env.PUBLIC_URL + "/logo-putih.png"} height="50" />
              {!this.props.collapsed && <h1 style={{ fontSize: 30, color: '#FFFFFF' }}>Plesiran</h1>}
              <span style={{
                color: "#fff",
                alignItems: "bottom"
              }}
              >
                v2.0
              </span>
            </div>
            {(this.props.isMobile == true &&
              (this.state.showMenu == true
                ?
                <Button
                  type="text"
                  shape="circle"
                  icon={<CloseOutlined />}
                  style={{ position: "absolute", right: 50 }}
                  onClick={() => {
                    this.setState({
                      showMenu: !this.state.showMenu
                    })

                  }}
                />
                :
                <Button
                  type="text"
                  icon={<PicCenterOutlined />}
                  style={{ position: "absolute", right: 50 }}
                  onClick={() => {
                    this.setState({
                      showMenu: !this.state.showMenu
                    })

                  }}
                />
              )
            )}
          </div>
          {(this.state.showMenu == true &&
            <Menu mode="inline"
              defaultSelectedKeys={
                this.state.menuAktif
              }
              className="sidebar"
              onOpenChange={this.onMenuExpand}
              defaultOpenKeys={this.state.menuExpand}
              openKeys={[this.state.menuExpand]}
            >
              <Menu.Item key="1">
                <DashboardOutlined />
                <Link to="/admin/index">
                  {(!this.props.collapsed && 'Dashboard')}
                </Link>
              </Menu.Item>
              <SubMenu
                key="menuadmin"
                title={
                  <span>
                    <UserOutlined />
                    <span>Administrator</span>
                  </span>
                }
              >
                <Menu.Item key="admin">
                  <Link to="/admin/admin">
                    Admin
                  </Link>
                </Menu.Item>
                <Menu.Item key="log">
                  <Link to="/admin/log">
                    Log Activity
                  </Link>
                </Menu.Item>
              </SubMenu>
              {(this.props.access != null && this.props.access.includes("master_data") &&
                <SubMenu
                  key="menumaster"
                  title={
                    <span>
                      <DiffOutlined />
                      <span>Master Data</span>
                    </span>
                  }
                >
                  {(this.props.access != null && this.props.access.includes("kategori") &&
                    <Menu.Item key="kategori">
                      <Link to="/admin/kategori">
                        Kategori
                      </Link>
                    </Menu.Item>
                  )}
                  {(this.props.access != null && this.props.access.includes("bagi_hasil") &&
                    <Menu.Item key="bagi_hasil">
                      <Link to="/admin/bagi-hasil">
                        Bagi Hasil
                      </Link>
                    </Menu.Item>
                  )}
                  <Menu.Item key="pembayaran">
                    <Link to="/admin/pembayaran">
                      Pembayaran
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="pengaturan_fee">
                    <Link to="/admin/pengaturan-fee">
                      Pengaturan Fee
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="pengaturan_email">
                    <Link to="/admin/pengaturan-email">
                      Pengaturan Email
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="pengaturan_telegram">
                    <Link to="/admin/pengaturan-telegram">
                      Pengaturan Telegram
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="pengaturan_point">
                    <Link to="/admin/pengaturan-point">
                      Pengaturan Point
                    </Link>
                  </Menu.Item>
                </SubMenu>
              )}
              {(this.props.access != null && this.props.access.includes("approvement") &&
                <SubMenu
                  key="mn_approvement"
                  title={
                    <span>
                      <CheckCircleOutlined />
                      <span>Approvement</span>
                    </span>
                  }
                >
                  {/*<Menu.Item key="mitra">
                            <Link to="/admin/mitra-usaha">
                              Mitra Usaha
                            </Link>
                        </Menu.Item>*/}
                  {(this.props.access != null && this.props.access.includes("perusahaan") &&
                    <Menu.Item key="perusahaan">
                      <Link to="/admin/perusahaan">
                        Perusahaan
                      </Link>
                    </Menu.Item>
                  )}
                  {/*<Menu.Item key="pembayaran">
                            <Link to="#">
                              Pembayaran
                            </Link>
                        </Menu.Item>*/}
                  {(this.props.access != null && this.props.access.includes("withdraw") &&
                    <Menu.Item key="withdraw">
                      <Link to="/admin/withdraw">
                        Withdraw
                      </Link>
                    </Menu.Item>
                  )}
                </SubMenu>
              )}
              {(this.props.access != null && this.props.access.includes("konten") &&
                <SubMenu
                  key="mn_web"
                  title={
                    <span>
                      <GlobalOutlined />
                      <span>Konten</span>
                    </span>
                  }
                >
                  {(this.props.access != null && this.props.access.includes("artikel") &&
                    <Menu.Item key="artikel">
                      <Link to="/admin/artikel">
                        Artikel
                      </Link>
                    </Menu.Item>
                  )}
                  {(this.props.access != null && this.props.access.includes("slider") &&
                    <Menu.Item key="forum">
                      <Link to="/admin/slider">
                        Slider
                      </Link>
                    </Menu.Item>
                  )}
                  {(this.props.access != null && this.props.access.includes("artikel") &&
                    <Menu.Item key="term_condition">
                      <Link to="/admin/term-condition">
                        Term Condition
                      </Link>
                    </Menu.Item>
                  )}
                  {(this.props.access != null && this.props.access.includes("artikel") &&
                    <Menu.Item key="tentang_plesiran">
                      <Link to="/admin/tentang-plesiran">
                        Tentang Plesiran
                      </Link>
                    </Menu.Item>
                  )}
                </SubMenu>
              )}
              {(this.props.access != null && this.props.access.includes("transaksi_parent") &&
                <SubMenu
                  key="transaksi"
                  title={
                    <span>
                      <ShoppingOutlined />
                      <span>Transaksi</span>
                    </span>
                  }
                >
                  {(this.props.access != null && this.props.access.includes("transaksi") &&
                    <Menu.Item key="transaksi">
                      <Link to="/admin/transaksi">
                        Transaksi
                      </Link>
                    </Menu.Item>
                  )}
                </SubMenu>
              )}
              {(this.props.access != null && this.props.access.includes("laporan") &&
                <SubMenu
                  key="laporan"
                  title={
                    <span>
                      <ReadOutlined />
                      <span>Laporan</span>
                    </span>
                  }
                >
                  {(this.props.access != null && this.props.access.includes("laporan_pendaftaran_usaha") &&
                    <Menu.Item key="pendaftaran_usaha">
                      <Link to="/admin/laporan/pendaftaran-usaha">
                        Pendaftaran Usaha
                      </Link>
                    </Menu.Item>
                  )}
                  {(this.props.access != null && this.props.access.includes("laporan_transaksi") &&
                    <Menu.Item key="lap_transaksi">
                      <Link to="/admin/laporan/transaksi">
                        Transaksi
                      </Link>
                    </Menu.Item>
                  )}

                  <Menu.Item key="lap_dettransaksi">
                    <Link to="/admin/laporan/detail-transaksi">
                      Detail Transaksi
                    </Link>
                  </Menu.Item>

                  {(this.props.access != null && this.props.access.includes("laporan_withdraw") &&
                    <Menu.Item key="lap_withdraw">
                      <Link to="/admin/laporan/withdraw">
                        Withdraw
                      </Link>
                    </Menu.Item>
                  )}
                </SubMenu>
              )}

            </Menu>
          )}
        </Sider>
      </PerfectScrollbar>
    )
  }
}

const mapStateToProps = function (state) {
  return state.configReducers
}

export default connect(mapStateToProps, { setConfig, ajaxViewHandler })(AdminSider);
