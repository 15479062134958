import React from 'react';
import { Link, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import {
    Breadcrumb,
    Card,
    Row,
    Col,
    Avatar,
    Form,
    Input,
    Button,
    Space,
    Select,
    DatePicker,
    message,
    Alert,
    Spin,
    Modal,
    Table
} from 'antd';
import {
    DiffOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    PlusOutlined,
    PrinterOutlined
} from '@ant-design/icons';
import moment from 'moment';

import axios from 'axios';
import Config from '../../Config';

import { connect } from 'react-redux';
import {
    initHandler,
    ajaxViewHandler,
    ajaxHandler,
    setConfig,
} from "../../store/actions";
import { Helmet } from "react-helmet";

import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import QRCode from "qrcode.react";

import LocationPicker from 'react-location-picker';

const FormItem = Form.Item

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 19,
            offset: 5,
        },
        lg: {
            span: 19,
            offset: 5,
        }
    },
};

const configClass = {
    title: 'form buat tiket',
    apiUrl: {
        tiket: "transaksi/api/qrgate/",
        usaha: "mitra/api/usaha/",
        download: "master/api/asset/image/"
    },
    pageUrl: {
        data: '/admin/perusahaan/'
    },
    breadCrumb: [
        (
            <span>
                <DiffOutlined /> perusahaan
            </span>
        ),
        'tiket',
        'form'
    ]

}

const config = new Config();

class FormBuatTiket extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            errComponent: false,
            errTitle: '',
            errMsg: '',
            loadingButton: false,

            aksi: 'tambah',
            method: 'post',
            idLama: '',

            modal_form: false,

            data_usaha: null,
            data_report: null,

            pagination: { pageSize: 10 },

            data_download: [],
            loading_download: false,
            err_download: null,

            data_tiket: [],
            loading_tiket: false,
            columns: [
                {
                    title: 'QR',
                    width: 90,
                    dataIndex: 'qr',
                    key: 'qr',
                    align: "center",
                    fixed: 'left',
                    render: (value, row, index) => {
                        return (
                            <div>
                                <QRCode
                                    value={value}
                                    size={50}
                                // className={this.state.qrValue == '' ? 'blur-qr' : ''}
                                // imageSettings={{
                                //     src: this.config.get_site_info().logo,
                                //     height: 100,
                                //     width: 100,
                                // }}
                                />
                            </div>
                        )
                    },
                },
                {
                    title: 'NAMA',
                    width: 150,
                    dataIndex: 'nama',
                    key: 'nama',
                    align: 'center',
                    render: (value, row, index) => {
                        return (
                            value != null ? value : "-"

                        )
                    },
                },
                {
                    title: 'IS CHECKIN',
                    width: 90,
                    dataIndex: 'is_checkin',
                    key: 'is_checkin',
                    align: 'center',
                    render: (value, row, index) => {
                        return (
                            value
                                ?
                                <CheckCircleOutlined />
                                :
                                "-"

                        )
                    },
                },
                {
                    title: 'CHECKIN AT',
                    width: 100,
                    dataIndex: 'checkin_at',
                    key: 'checkin_at',
                    render: (value, row, index) => {
                        return (
                            value != null
                                ?
                                moment(value).format("DD-MM-YYYY HH:mm")
                                :
                                <div style={{ textAlign: "center" }}>-</div>

                        )
                    },
                },
                {
                    title: 'CHECKIN BY',
                    width: 100,
                    dataIndex: 'user_nama',
                    key: 'user_nama',
                    render: (value, row, index) => {
                        return (
                            value != null
                                ?
                                value
                                :
                                <div style={{ textAlign: "center" }}>
                                    -
                                </div>

                        )
                    },
                },
            ]
        }
    }

    UNSAFE_componentWillMount() {
        this.props.initHandler();
    }

    componentDidMount() {
        this.previewUsaha();
    }

    async downloadTiket() {
        this.setState({
            loading_download: true,
            err_download: null,
            data_download: [],
        })

        this.props.ajaxViewHandler('get', configClass.apiUrl.download + "?reference=" + this.props.match.params.id + "&limit=1000000")
            .then(() => {
                const data = this.props.responseMsg;
                if (this.props.isSuccess) {
                    this.setState({
                        loading_download: false,
                        data_download: data.results,
                    });
                } else {
                    this.setState({
                        loading_download: false,
                        err_download: JSON.stringify(data)
                    });
                }
            }).catch((response) => {
                this.setState({
                    loading_download: false,
                    err_download: JSON.stringify(response)
                });
            });
    }

    async previewUsaha() {
        message.loading({ content: 'Menampilkan data..', duration: 2, key: 'toast' });

        this.props.ajaxViewHandler('get', configClass.apiUrl.usaha + this.props.match.params.id)
            .then(() => {
                const data = this.props.responseMsg;
                if (this.props.isSuccess) {
                    this.setState({
                        data_usaha: data,
                        // modal_form: true
                    })
                    this.previewTiket();
                } else {
                    const errTitle = 'error preview data usaha';
                    this.setState({
                        errTitle: errTitle,
                        errMsg: this.props.responseMsg
                    });
                    message.error({ content: errTitle, duration: 2, key: 'toast' });
                }
            }).catch((response) => {
                console.log('gagal_get_preview', response);
                message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
            });
    }

    async previewTiket(limit = this.state.pagination.pageSize, offset = 0, search = this.state.searchText) {
        this.setState({
            loadingData: true
        });

        this.props.ajaxViewHandler('get', configClass.apiUrl.tiket + "?usaha=" + this.props.match.params.id + '&limit=' + limit + '&offset=' + offset + '&search=' + search)
            .then(() => {
                const data = this.props.responseMsg;
                if (this.props.isSuccess) {
                    const pagination = { ...this.state.pagination };
                    pagination.total = data.count;
                    this.setState({
                        loadingData: false,
                        data_tiket: data.results.data,
                        data_report: data.results.attribut,
                        pagination
                        // modal_form: true
                    })
                } else {
                    const errTitle = 'error preview data usaha';
                    this.setState({
                        loadingData: false,
                        errTitle: errTitle,
                        errMsg: this.props.responseMsg
                    });
                    message.error({ content: errTitle, duration: 2, key: 'toast' });
                }
            }).catch((response) => {
                this.setState({
                    loadingData: false
                })
                console.log('gagal_get_preview', response);
                message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
            });
    }

    handleTableChange = async (pagination, filters, sorter) => {
        const newPagination = { ...this.state.pagination };
        newPagination.pageSize = pagination.pageSize;
        this.setState({
            loadingData: true,
            pagination
        });
        if (pagination.current == 1) {
            this.previewTiket(pagination.pageSize);
        } else {
            this.previewTiket(pagination.pageSize, (pagination.current - 1) * pagination.pageSize);
        }


    }

    handleSubmit = (values) => {
        var datas = new FormData();
        for (var key in values) {
            if (values[key] == undefined) {
                datas.append(key, '');
            } else {
                datas.append(key, values[key].toString());
            }
        }
        datas.append("usaha", this.props.match.params.id);
        this.postData(datas);
    }

    async postData(datas) {
        this.setState({
            loadingButton: true,
            errMsg: "",
            errTitle: ""
        });
        // message.loading({ content: 'Memproses...', key: 'toast' });

        this.props.ajaxHandler(this.state.method, configClass.apiUrl.tiket + "generate/", datas, true, true)
            .then(() => {
                this.setState({
                    loadingButton: false
                });
                if (this.props.isSuccess) {
                    message.success({ content: 'berhasil menyimpan data', duration: 2, key: 'toast' });
                    this.setState({
                        modal_form: false
                    });
                    //getdata disini
                    this.previewTiket();
                } else {
                    const errTitle = 'gagal menyimpan data'
                    message.error({ content: errTitle, duration: 2, key: 'toast' });
                    this.setState({
                        errTitle: errTitle,
                        errMsg: this.props.responseMsg
                    });
                }
            }).catch((response) => {
                console.log('gagal_post', response);
                this.setState({
                    loadingButton: false
                });
                message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
            });
    }

    generateSelect(stateName, url, key, value) {
        console.log('tes', url);
        return (
            this.state['loading' + stateName] ? (
                <img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
            ) : (
                <Select
                    showSearch
                    placeholder="pilih"
                    optionFilterProp="children"
                    onSearch={val => {
                        const rule = {
                            destination: {
                                stateProgressName: 'loading' + stateName + '_searching',
                                endPoint: url + '?search=' + val,
                                stateDestination: 'list' + stateName
                            },
                            nextDestination: null
                        }
                        this.getMasterList(rule);
                    }}
                    onPopupScroll={e => {
                        e.persist();
                        let target = e.target;
                        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
                            if (this.state['list' + stateName].next != undefined && this.state['list' + stateName].next != null) {
                                const rule = {
                                    destination: {
                                        stateProgressName: 'loading' + stateName + 'Paging',
                                        endPoint: this.state['list' + stateName].next,
                                        stateDestination: 'list' + stateName
                                    },
                                    nextDestination: null
                                }
                                this.getMasterList(rule);
                            }
                        }
                    }}
                >
                    {(this.state['loading_' + stateName + 'searching'] &&
                        <Select.Option value=""><Spin size="small" /></Select.Option>
                    )}
                    {this.state['list' + stateName].count != undefined ? (
                        this.state['list' + stateName].results.map((item) => (
                            <Select.Option value={item[key]}>{item[key] + " - " + item[value] + ""}</Select.Option>
                        ))
                    ) : (
                        this.state['list' + stateName].map((item) => (
                            <Select.Option value={item[key]}>{item[key] + " - " + item[value] + ""}</Select.Option>
                        ))
                    )}
                    {(this.state['list' + stateName] != undefined && this.state['list' + stateName].next != undefined &&
                        <Select.Option value=""><Spin size="small" /></Select.Option>
                    )}
                </Select>
            )
        );
    }

    render() {
        // if(this.props.isSuperadmin == false){
        // 	return <Redirect to="/admin/forbidden" />
        // }
        if (this.props.isRedirect) {
            // return <Redirect to={configClass.pageUrl.data} />
        }

        const { crop, croppedImageUrl, src } = this.state;

        return (
            <div>
                <Helmet>
                    <title>{configClass.title + " | " + config.get_site_info().name}</title>
                </Helmet>
                <Breadcrumb>
                    {configClass.breadCrumb.map(item => (
                        <Breadcrumb.Item>
                            {item}
                        </Breadcrumb.Item>
                    ))}
                </Breadcrumb>
                <div className="card">
                    <Card
                        className="konten"
                        title={
                            <span className="konten-judul">
                                {configClass.title}
                                {(this.state.loadingButton &&
                                    <img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
                                )}
                                {this.state.data_usaha != null && " : " + this.state.data_usaha.nama}
                            </span>
                        }
                    >
                        {(this.state.errMsg != '' &&
                            <Alert
                                message={this.state.errTitle}
                                description={JSON.stringify(this.state.errMsg)}
                                type="error"
                                showIcon
                                style={{
                                    marginBottom: 10
                                }}
                            />
                        )}
                        {this.state.loadingData == false && this.state.data_report != null && this.state.data_report.checkin != undefined &&
                            <Alert
                                message={"Checkin"}
                                description={
                                    this.state.data_report.checkin.map((item, idx) => (
                                        <Row style={{ borderBottom: "1px solid #ccc" }} justify="space-between" >
                                            <Col span={10}>
                                                {item.nama}
                                            </Col>
                                            <Col span={14}>
                                                {item.count}
                                            </Col>
                                        </Row>
                                    ))
                                }
                                type="info"
                                showIcon
                                style={{
                                    marginBottom: 10
                                }}
                            />
                        }
                        <Table
                            title={() =>
                                <Row>
                                    <Col lg={12} sm={12} xs={24}><span className="konten-judul">Total {this.state.pagination.total} record</span></Col>
                                    <Col lg={12} sm={12} xs={24} style={{ textAlign: "right" }}>
                                        {/* <Input.Search
                                            size="small"
                                            placeholder="ketikkan pencarian"
                                            onSearch={value => {
                                                this.setState({
                                                    searchText: value
                                                }, () => {
                                                    this.getData();
                                                });
                                            }}
                                            allowClear
                                        /> */}
                                        <Space>
                                            <Button
                                                htmlType='button'
                                                type="primary"
                                                style={{ borderRadius: 10 }}
                                                onClick={() => {
                                                    this.setState({
                                                        modal_form: true
                                                    })
                                                }}
                                            >
                                                <PlusOutlined />
                                                Tambah Tiket
                                            </Button>
                                            <Link to={"/addon/cetak-tiket/" + this.props.match.params.id}>
                                                <Button
                                                    htmlType='button'
                                                    type="default"
                                                    style={{ borderRadius: 10 }}
                                                >
                                                    <PrinterOutlined />
                                                    Darurat
                                                </Button>
                                            </Link>
                                            <Button
                                                htmlType='button'
                                                type="default"
                                                style={{ borderRadius: 10 }}
                                                danger
                                                onClick={() => {
                                                    this.setState({
                                                        modal_download: true
                                                    }, () => {
                                                        this.downloadTiket();
                                                    })
                                                }}
                                            >
                                                <PrinterOutlined />
                                                Download Tiket
                                            </Button>
                                        </Space>
                                    </Col>
                                </Row>
                            }
                            bordered
                            columns={this.state.columns}
                            dataSource={this.state.data_tiket}
                            loading={this.state.loadingData}
                            scroll={{ x: 240, y: 450 }}
                            size="small"
                            pagination={this.state.pagination}
                            onChange={this.handleTableChange}
                        />
                    </Card>
                </div>
                <Modal
                    centered
                    visible={this.state.modal_form}
                    onCancel={() =>
                        this.setState({
                            modal_form: false,
                        })
                    }
                    // width={"50%"}
                    footer={[]}
                    title={this.state.data_usaha != null ? "Buat Tiket : " + this.state.data_usaha.nama : "Buat Tiket"}
                >
                    <Spin
                        size="large"
                        spinning={this.state.loadingButton}
                        tip={
                            <span style={{ color: "#000" }}>
                                sedang memproses, mohon menunggu...
                            </span>
                        }
                    >
                        <Form
                            layout="vertical"
                            name="fdata"
                            onFinish={this.handleSubmit}
                            size="small"
                            ref={this.formRef}
                        >
                            {/*data dasar*/}
                            <Row className="isi">
                                {(this.state.errMsg != '' &&
                                    <Col span={24}>
                                        <Alert
                                            message={this.state.errTitle}
                                            description={JSON.stringify(this.state.errMsg)}
                                            type="error"
                                            showIcon
                                            style={{
                                                marginBottom: 10
                                            }}
                                        />
                                    </Col>
                                )}
                                <Col lg={24} sm={24} xs={24}>

                                    <Row justify="space-between">
                                        <Col lg={24} sm={24} xs={24}>
                                            <Form.Item
                                                label="Jumlah Tiket"
                                                name="quantity"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'harus diisi'
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    style={{ textTransform: 'none' }}
                                                    placeholder="input angka"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={24} sm={24} xs={24}>
                                            <Form.Item
                                                label="Nama"
                                                name="nama"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'harus diisi'
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    style={{ textTransform: 'none' }}
                                                    placeholder="input nama"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify="end">
                                        <Col style={{ padding: 5 }}>
                                            <Button
                                                key="btn-simpan"
                                                type="primary"
                                                htmlType="submit"
                                                size="small"
                                            >
                                                {(!this.state.loadingButton && <CheckCircleOutlined />)}
                                                Simpan
                                            </Button>
                                        </Col>
                                        <Col style={{ padding: 5 }}>
                                            <Link to={configClass.pageUrl.data}>
                                                <Button
                                                    type="danger"
                                                    key="btn-cancel"
                                                    size="small"
                                                >
                                                    <CloseCircleOutlined /> Cancel
                                                </Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Modal>
                <Modal
                    centered
                    visible={this.state.modal_download}
                    onCancel={() =>
                        this.setState({
                            modal_download: false,
                        })
                    }
                    // width={"50%"}
                    footer={[]}
                    title={"Download Tiket"}
                >
                    <Spin
                        size="large"
                        spinning={this.state.loading_download}
                        tip={
                            <span style={{ color: "#000" }}>
                                sedang memproses, mohon menunggu...
                            </span>
                        }
                    >
                        <Row gutter={[12, 12]}>
                            {this.state.data_download.map((item, idx) => (
                                <Col span={12} key={"download" + idx}>
                                    <a
                                        href={item.gambar}
                                        target="_blank"
                                    >
                                        <Button
                                            style={{ width: "100%" }}
                                            htmlType='button'
                                            type='primary'
                                            danger
                                        >
                                            <PrinterOutlined />{item.nama != undefined && item.nama != null ? item.nama : " " + moment(item.created_at).format("DD-MM-YYYY H:m:s")}
                                        </Button>
                                    </a>
                                </Col>
                            ))}
                        </Row>
                    </Spin>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return state.configReducers
}

export default connect(
    mapStateToProps,
    {
        initHandler,
        setConfig,
        ajaxViewHandler,
        ajaxHandler
    }
)(FormBuatTiket);