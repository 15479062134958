import React from 'react';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../store/actions";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert,
	Select,
	Tooltip,
	Spin,
	Form,
	Skeleton
} from 'antd';

import moment from 'moment';
import QRCode from "qrcode.react";

import Config from '../Config';
import {Helmet} from "react-helmet";
import queryString from 'query-string';


const configClass = {
	title: 'cetak qr perusahaan',
	apiUrl: {
		data: 'mitra/api/usaha/'
	},

}

class CetakQrCode extends React.Component {
	config;
	
	constructor(props){
	    super(props);
	    this.config = new Config();
	    this.state = {
	    	loading: false,
		    data: null,

		    not_found: false,
	    }

	    // this.preview(this.props.match.params.id);
	    
	}

	componentDidMount(){
		this.getData(this.props.match.params.id);
	}

	async getData(id) {
	    this.setState({ loading: true });
	    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

	    this.props
	      .ajaxViewHandler("get", configClass.apiUrl.data + id)
	      .then(() => {
	        const data = this.props.responseMsg;
	        if (this.props.isSuccess) {
	          if(data == null){
	            this.setState({
	              not_found: true
	            });
	          }else{
	            this.setState({
	              data: data,
	            }, () =>{
	            	setTimeout(function(){
	            		window.print();
	            	}, 2000);
	            });
	          }
	        } else {
	          const errTitle = "error get data";
	          this.setState({
	            errTitle: errTitle,
	            errMsg: this.props.responseMsg,
	          });
	          message.error({ content: errTitle, duration: 2, key: "toast" });
	        }
	        this.setState({ loading: false });
	      })
	      .catch((response) => {
	        console.log("gagal_get_preview", response);
	        message.error({
	          content: "gagal request data, coba lagi",
	          duration: 2,
	          key: "toast",
	        });
	        this.setState({ loading: false });
	      });
	  }

	render() {		
    	return (
			<div>
		        <Row justify="center" style={{padding: 30}}>
		          <Col span={24} className="nama-app" style={{textAlign: "center"}}>
		            <img src={process.env.PUBLIC_URL + "/logo_teks.png"} height={80} />
		          </Col>
		          <Col span={24} className="judul" style={{textAlign: "center", fontSize: 20, fontWeight: "bold"}}>
		            QR MITRA USAHA
		            {(this.state.data != null &&
		            	<>
		            		<br />
		            		{this.state.data.nama}
		            	</>
		            )}
		          </Col>
		          <Col span={24} className="label-petunjuk" style={{textAlign: "center"}}>
		            <Alert 
		              style={{display: "inline-block", margin: "0px auto"}}
		              message={
		                <div>
		                  berikut ini adalah QR Mitra Usaha untuk transaksi offline
		                </div>
		              }
		              type="info" 
		            />
		          </Col>
		          {(this.state.data != null &&
		          	<>
		          		<Col span={24} style={{textAlign: "center", marginBottom: 10}}>
			              {this.config.get_domain()+this.state.data.id}			              
			            </Col>
			          	<QRCode 
				            value={this.config.get_domain()+this.state.data.id} 
				            size={500}
				            className={this.state.qrValue==''?'blur-qr':''}
				            imageSettings={{
				              src: this.config.get_site_info().logo,
				              height: 100,
				              width: 100,
				            }}
				        />
		            </>
		          )}
		        </Row>
		      </div>

    	)
    }

}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler,
			setConfig
		}
	)(CetakQrCode);