import React from 'react';
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import Highlighter from 'react-highlight-words';
import { Link } from "react-router-dom";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert,
	Divider,
	Select,
	Tag,
	Form,
	Spin,
	DatePicker,
} from 'antd';
import {
  TeamOutlined,
  SearchOutlined,
  PlusOutlined,
  FormOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  DiffOutlined,
  RedoOutlined,
  ClusterOutlined,
  GlobalOutlined, 
  CommentOutlined,
  FileExcelOutlined,
  LikeOutlined,
  ShareAltOutlined,
  ShoppingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EyeOutlined
} from '@ant-design/icons';

import moment from "moment";

import { LazyLoadImage } from 'react-lazy-load-image-component';

import axios from 'axios';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";

import Config from '../../Config';
import {Helmet} from "react-helmet";

const configClass = {
	title: 'data transaksi',
	apiUrl: {
		data: 'transaksi/api/transaksi/',
		komentar: 'web/api/komentar/forum/',
	},
	pageUrl: {
		form: '/admin/forum/form/'
	},
	breadCrumb: [
		(
			<span>
				<ShoppingOutlined /> Transaksi
			</span>
		),
		'data transaksi'
	]

}

const config = new Config();

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 23 },
  },
};

class DataTransaksi extends React.Component {
	formRef = React.createRef();

	formRefKeterangan = React.createRef();

	constructor(props){
		super(props);
		this.state = {
			errMsg: '',
			loadingData: false,
			pagination: {pageSize: 25},
			searchText: '',
			searchColumn: '',
			dataSource: [],
			filterData: '',
			columns: [
				{
					title: 'STATUS',
					width: 150,
					dataIndex: 'status',
					key: 'status',
					fixed: 'left',
					render: (value, row, index) => {
						return (
							<div>
								{(value == 1 &&
									<Tag color="warning">pending</Tag>
								)}
								{(value == 2 &&
									<Tag color="processing">diterima</Tag>
								)}
								{(value == 3 &&
									<>
										<Tag color="error">
											dibatalkan
										</Tag>
										<div style={{fontSize: 12, background: "#F8656C", padding: 3, marginTop: 2, color: "#fff"}}>
											{row.catatan}
										</div>
									</>
								)}
								{(value == 4 &&
									<Tag color="gray">expired</Tag>
								)}
							</div>
						)
					},
				},
				{
					title: 'DETAIL',
					width: 90,
					dataIndex: 'id',
					key: 'details',
					align: 'center',
					render: (value, row, index) => {
						return (
							<Button 
								shape="circle"
								type="primary"
								title="lihat detail transaksi"
								onClick={() => this.showDetailTransaksi(row.details)}
							>
								<EyeOutlined />
							</Button>
						)
					}
				},
				{
					title: 'ID',
					width: 90,
					dataIndex: 'id',
					key: 'id',
				},
				{
					title: 'KODE',
					width: 150,
					dataIndex: 'kode',
					key: 'kode',
				},
				{
					title: 'CUSTOMER',
					width: 150,
					dataIndex: 'customer',
					key: 'customer',
				},
				{
					title: 'KODE UNIK',
					width: 120,
					dataIndex: 'harga_unique',
					key: 'harga_unique',
					render: (value, row, index) => {
						return value.toLocaleString('id')
					},
				},
				{
					title: 'TOTAL BAYAR',
					width: 120,
					dataIndex: 'total_bayar',
					key: 'total_bayar',
					render: (value, row, index) => {
						return value.toLocaleString('id')
					},
				},
				{
					title: 'REFERENCE',
					width: 150,
					dataIndex: 'reference',
					key: 'reference',
				},
				{
					title: 'PAYMENT TYPE',
					width: 150,
					dataIndex: 'payment_type',
					key: 'payment_type',
					align: 'center',
					render: (value, row, index) => {
						return(
							<div>
								{row.payment_detail.icon != null 
								? 
									<>
										<LazyLoadImage
											key={"payment"+row.id}
									    alt={"payment"}
									    effect="blur"
									    src={row.payment_detail.icon} 
									    height={20} 
									    placeholder={
									    	<Spin />
									    }
									    onClick={() => {
												this.setState({
													image_focus_url: row.payment_detail.icon,
													visible_image_focus: true,
													focus_title: "ICON"
												})
											}}
											style={{cursor: "pointer"}}
									  />
										<br />
										{row.payment_detail.nama_acount}										
										<br />
										{row.payment_detail.nomor}										
									</>
								: 
									<>
										{row.payment_detail.nama_ccount}										
										<br />
										{row.payment_detail.nomor}										
									</>
								}
							</div>
						)
					},
				},
				{
					title: 'BUKTI',
					width: 120,
					dataIndex: 'bukti',
					key: 'bukti',
					align: 'center',
					render: (value, row, index) => {
						return value != null && 
							<LazyLoadImage
								key={"bukti"+row.id}
						    alt={"gambar"}
						    effect="blur"
						    src={value} 
						    height={50} 
						    placeholder={
						    	<Spin />
						    }
						    onClick={() => {
									this.setState({
										image_focus_url: value,
										visible_image_focus: true,
										focus_title: "BUKTI BAYAR"
									})
								}}
								style={{cursor: "pointer"}}
						  />
							// <img 
							// 	style={{cursor: "pointer"}}
							// 	src={value} 
							// 	height={50} 
							// 	onClick={() => {
							// 		this.setState({
							// 			image_focus_url: value,
							// 			visible_image_focus: true,
							// 			focus_title: "BUKTI BAYAR"
							// 		})
							// 	}}
							// />
					},
				},
				{
					title: 'CREATED',
					width: 150,
					dataIndex: 'created',
					key: 'created',
					render: (value, row, index) => {
						return moment(value).format("DD-MM-YYYY")
					},
				},
				{
					title: 'APPROVED AT',
					width: 150,
					dataIndex: 'approved_at',
					key: 'approved_at',
					render: (value, row, index) => {
						return value != null ? moment(value).format("DD-MM-YYYY HH:mm") : '-'
					},
				},
				{
					title: 'APPROVED BY',
					width: 150,
					dataIndex: 'approved_by_nama',
					key: 'approved_by_nama',
					render: (value, row, index) => {
						return value != null ? value : '-'
					},
				},
				{
					title: 'AKSI',
					width: 120,
					dataIndex: 'id',
					key: 'aksi',
					fixed: 'right',
					render: (value, row, index) => {
						return (
							<div>
									{row.status != 2 && row.status !=4
										?
											<Select
												style={{width: '100%'}}
												key={"aksi-"+value}
												onChange={(val) => this.prosesUpdate(value, val)}
												placeholder={"pilih aksi"}
												value={row.status}
											>
												<Select.Option value={1}>pending</Select.Option>
												<Select.Option value={2}>diterima</Select.Option>
												<Select.Option value={3}>dibatalkan</Select.Option>
												<Select.Option value={4}>expired</Select.Option>
											</Select>
										:
											(
												row.status == 2
												?
													<Tag color="success">sudah berhasil</Tag>
												:

													<Tag color="gray">sudah expired</Tag>
											)
									}
								
							</div>

							// <div>
							// 	<Select
							// 			style={{width: '100%'}}
							// 			key={"aksi-"+value}
							// 			onChange={(val) => this.prosesUpdate(value, val)}
							// 			placeholder={"pilih aksi"}
							// 			value={row.status}
							// 		>
							// 			<Select.Option value={1}>pending</Select.Option>
							// 			<Select.Option value={2}>diterima</Select.Option>
							// 			<Select.Option value={3}>dibatalkan</Select.Option>										
							// 	</Select>
								
							// </div>
						)
					},
				}
			],
			visible: false,
			loadingModal: false,
			dataketerangan: null,
			errTitleModal: '',
			dataDetail: null,
			idDipilih: null,
			loadingButton: false,
			visibleDetail: false,
			dataDetailTransaksi: null,

			visible_image_focus: false,
			image_focus_url: null,
			focus_title: "",
		}
	}

	UNSAFE_componentWillMount(){
		this.props.initHandler();
	}

	componentDidMount(){
		this.getData();
	}

	componentDidUpdate(prevProps) {
		// console.log('prevProps', prevProps);
		// console.log('props', this.props);
		//console.log('proper', prevProps);
	    if (prevProps.latestNofif !== this.props.latestNofif) {
	    	this.getData();
	    }
	}


	showDetailTransaksi = (detail) =>{
		this.setState({
			visibleDetail: true,
			dataDetailTransaksi: detail,
		});
	};

	// async showModal(detail){
	// 	this.setState({
	// 		loadingModal: true,
	// 		errTitleModal: '',
	// 		errMsgModal: '',
	// 		visible: true,
	// 		dataKomentar: null,
	// 	}, () =>{
	// 		this.props.ajaxViewHandler('get', configClass.apiUrl.komentar+'?komentar='+detail.id)
	// 			.then(() =>{
	// 				const data = this.props.responseMsg;
	// 				if(this.props.isSuccess){
	// 					this.setState({
	// 						dataKomentar: data,
	// 						dataDetail: detail,
	// 					});
	// 		    }else{
	// 		      const errTitle = 'error get data';
	// 		      this.setState({
	// 		        errTitleModal: errTitle,
	// 		        errMsgModal: this.props.responseMsg
	// 		      });
	// 		      message.error({ content: errTitle, duration: 2, key: 'toast' });
	// 		    }
	// 		    this.setState({loadingModal: false});
	// 			}).catch((response) => {
	// 				message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
	// 				this.setState({
	// 					loadingModal: false,
	// 					errTitleModal: "unknown error",
	// 		      errMsgModal: "failed unknown error get data"
	// 				});
	// 		});
	// 	});
	// }

	setVisible(val){
		this.setState({
			visible: val
		});
	}

	setVisibleDetail(val){
		this.setState({
			visibleDetail: val
		});
	}

	handleSubmitKeterangan = (values) =>{
		var datas = new FormData();
		datas.append("status", "3");
		datas.append("catatan", values.keterangan);

		this.setState({
			loadingButton: true,
		});

		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler('post', configClass.apiUrl.data+this.state.idDipilih+'/approved/', datas, true, true)
			.then(() =>{
				if(this.props.isSuccess){
					message.success({ content: 'berhasil mengubah status', key: 'toast' });
					this.getData();
				}else{
					const errTitle = 'gagal mengubah status'
					message.error({ content: errTitle, key: 'toast' });
					this.setState({
						errTitle: errTitle,
						errMsg: this.props.responseMsg
					});
				}
				this.setState({
					loadingButton: false,
				});
		}).catch((response) => {
				console.log('gagal_post', response);
				message.error({ content: 'kesalahan mengubah status data, coba lagi', key: 'toast' });
				this.setState({
					loadingButton: false,
				});
		});
	}

	async prosesUpdate(id, statusValue){
		if(statusValue == "3"){
			this.showModal(id)
		}else{
			var datas = new FormData();
			datas.append("status", statusValue);

			message.loading({ content: 'Memproses...', key: 'toast' });

			this.props.ajaxHandler('post', configClass.apiUrl.data+id+'/approved/', datas, true, true)
			.then(() =>{
				if(this.props.isSuccess){
					message.success({ content: 'berhasil mengubah status', key: 'toast' });
					this.getData();
				}else{
					const errTitle = 'gagal mengubah status'
					message.error({ content: errTitle, key: 'toast' });
					this.setState({
						errTitle: errTitle,
						errMsg: this.props.responseMsg
					});
				}
			}).catch((response) => {
				console.log('gagal_post', response);
				message.error({ content: 'kesalahan mengubah status data, coba lagi', key: 'toast' });
			});
		}
	}

	async showModal(detail){
		this.setState({
			loadingModal: true,
			errTitleModal: '',
			errMsgModal: '',
			visible: true,
			dataketerangan: null,
		}, () =>{
			this.props.ajaxViewHandler('get', configClass.apiUrl.data+detail)
				.then(() =>{
					const data = this.props.responseMsg;
					if(this.props.isSuccess){
						this.setState({
							dataketerangan: data.keterangan,
							dataDetail: detail,
							idDipilih: detail,
						});
			    }else{
			      const errTitle = 'error get data';
			      this.setState({
			        errTitleModal: errTitle,
			        errMsgModal: this.props.responseMsg
			      });
			      message.error({ content: errTitle, duration: 2, key: 'toast' });
			    }
			    this.setState({loadingModal: false});
				}).catch((response) => {
					message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
					this.setState({
						loadingModal: false,
						errTitleModal: "unknown error",
			      errMsgModal: "failed unknown error get data"
					});
			});
		});
	}

	showDeleteConfirm(id) {
	  Modal.confirm({
	    title: 'Konfirmasi Hapus?',
	    icon: <ExclamationCircleOutlined />,
	    content: 'yakin menghapus data?',
	    okText: 'Ya',
	    okType: 'danger',
	    cancelText: 'Tidak',
	    onOk: () => this.prosesHapus(id),
	    onCancel() {
	      //console.log('Cancel');
	    },
	  });
	}

	prosesHapus(id) {
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler('delete', configClass.apiUrl.data+id+'/')
		.then(() =>{
			this.setState({
				loadingButton: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil menghapus data', duration: 2, key: 'toast' });
				this.getData();
			}else{
				const errTitle = 'gagal menghapus data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_delete', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	getColumnSearchProps = dataIndex => ({
	    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
	      <div style={{ padding: 8 }}>
	        <Input
	          ref={node => {
	            this.searchInput = node;
	          }}
	          placeholder={`Search ${dataIndex}`}
	          value={selectedKeys[0]}
	          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
	          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
	          style={{ width: 188, marginBottom: 8, display: 'block' }}
	        />
	        <Button
	          type="primary"
	          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
	          icon={<SearchOutlined />}
	          size="small"
	          style={{ width: 90, marginRight: 8 }}
	        >
	          Search
	        </Button>
	        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
	          Reset
	        </Button>
	      </div>
	    ),
	    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	    onFilter: (value, record) =>
	      record[dataIndex]
	        .toString()
	        .toLowerCase()
	        .includes(value.toLowerCase()),
	    onFilterDropdownVisibleChange: visible => {
	      if (visible) {
	        setTimeout(() => this.searchInput.select());
	      }
	    },
	    render: text =>
	      this.state.searchedColumn === dataIndex ? (
	        <Highlighter
	          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
	          searchWords={[this.state.searchText]}
	          autoEscape
	          textToHighlight={text.toString()}
	        />
	      ) : (
	        text
	      ),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
	    confirm();
	    this.setState({
	      searchText: selectedKeys[0],
	      searchedColumn: dataIndex,
	    });
	};

	handleReset = clearFilters => {
	    clearFilters();
	    this.setState({ searchText: '' });
	};

	async getData(limit=this.state.pagination.pageSize, offset=0, search=this.state.searchText){
		this.setState({loadingData: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.data+'?limit='+limit+'&offset='+offset+'&search='+search+'&'+this.state.filterData)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				const pagination = { ...this.state.pagination };
				pagination.total = data.count;
				this.setState({
					dataSource: data.results,
					pagination
				});
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({loadingData: false});
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingData: false});
		});
	}

	handleTableChange = async (pagination, filters, sorter) =>{
    	const newPagination = { ...this.state.pagination };
    	newPagination.pageSize = pagination.pageSize;
		this.setState({
			loadingData: true,
			pagination
		});
		if(pagination.current==1){
			this.getData(pagination.pageSize);
		}else{
			this.getData(pagination.pageSize, (pagination.current - 1)*pagination.pageSize);
		}

		
	}

	handleReset = clearFilters => {
	    clearFilters();
	    this.setState({ searchText: '' });
	};

	handleSubmit = (values) =>{
		var datas = '';
		for ( var key in values ) {
			if(values[key] != undefined){
				if(datas == ''){
					datas = key+'='+values[key];
				}else{
					datas += '&'+key+'='+values[key];
				}
			}
		}
		this.setState({
			filterData: datas
		});

		this.getData();
	}

	render() {
		// if(this.props.isSuperadmin == false){
		// 	return <Redirect to="/admin/forbidden" />
		// }
		return(
			<div>
				<Helmet>
					<title>{configClass.title+" | "+config.get_site_info().name}</title>
				</Helmet>
				<Breadcrumb>
					{configClass.breadCrumb.map(item => (
						<Breadcrumb.Item>
							{item}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
				<div className="card">
					<Row className="isi">
				       	<Col span={24}>
				        	<Card
				        		className="konten"
					            title={
					            	<Row>
					            		<Col lg={{ span: 20 }} xs={{ span:24 }}>
							            	<span className="konten-judul">
							            		{configClass.title}
							            		{(this.state.loadingData &&
									        		<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="30" alt="loading" />
									        	)}
							            	</span>
							            </Col>
						            	<Col lg={{ span: 4 }} sm={{ span: 24 }} xs={{ span:24 }} style={{textAlign: 'right'}}>
						            		<Button 
										        	loading={this.state.loadingButton}
										        	className="btn-refresh"
										        	size="small"
										        	onClick={() => this.getData()} 
										        >
										          <RedoOutlined />
										    </Button>
										    {/*
						            		<Link to={configClass.pageUrl.form}>
							            		<Button 
										        	loading={this.state.loadingButton}
										        	className="btn-primary"
										        	size="small" 
										        >
										          <PlusOutlined /> Tambah
										        </Button>
										    </Link>
										  */}
						            	</Col>
						            </Row>
					            }
					        >
					        	{(this.state.errMsg!='' &&
						        	<Alert
								      message={this.state.errTitle}
								      description={JSON.stringify(this.state.errMsg)}
								      type="error"
								      showIcon
								      style={{
								      	marginBottom: 10
								      }}
								    />
								)}
					        	<Form 
											layout="horizontal"
											name="fdata"
										    onFinish={this.handleSubmit}
										    size="small"
										    ref={this.formRef}
										    {...formItemLayout}
										>
											<Row>
												<Col md={12} sm={12} xs={24}>
													<Form.Item name="tanggal_mulai_picker">
												        <DatePicker 
												        autocomplete="off"
														    placeholder="Dari Tgl (dd-mm-yyyy)" 
														    format="DD-MM-YYYY"
														    style={{width: '100%'}}
														    onChange={(date, dateString) =>{
														        this.formRef.current.setFieldsValue({
																	tanggal_mulai: dateString,
																});
														    }}
														/>
													</Form.Item>
													<Form.Item
														name="tanggal_mulai"
														style={{
															display: 'none'
														}}
													>
														<Input type="hidden" />
												    </Form.Item>
												</Col>
												<Col  md={12} sm={12} xs={24}>
												    <Form.Item name="tanggal_akhir_picker">
												        <DatePicker 
												        autocomplete="off"
														    placeholder="Sampai Tgl (dd-mm-yyyy)" 
														    format="DD-MM-YYYY"
														    style={{width: '100%'}}
														    onChange={(date, dateString) =>{
														        this.formRef.current.setFieldsValue({
																	tanggal_akhir: dateString,
																});
														    }}
														/>
													</Form.Item>
													<Form.Item
														name="tanggal_akhir"
														style={{
															display: 'none'
														}}
													>
														<Input type="hidden" />
												    </Form.Item>
												</Col>
											
												
											
										
												<Col md={12} sm={12} xs={24}>
												    <Form.Item name="status">
														<Select
															allowClear
															showSearch
															placeholder={'Pilih Jenis Transaksi'}
															optionFilterProp="children"
														>
															<Select.Option value={1}>pending</Select.Option>
															<Select.Option value={2}>diterima</Select.Option>
															<Select.Option value={3}>dibatalkan</Select.Option>
														</Select>
													</Form.Item>
												</Col>
												
											</Row>
											<Row style={{marginBottom: 20}} justify="space-between">
												<Col md={12} sm={24} xs={24}>
													<Row>
														<Col span={12}>
															<Button
																style={{width: '100%'}}
																key="btn-view"
																loading={this.state.loadingData}
																type="primary"
																htmlType="submit"
																size="small"
															>
																{(!this.state.loadingData && <EyeOutlined />)}
																	View Filter
															</Button>
														</Col>
														<Col span={12} style={{paddingLeft: 10}}>
															<Button
																style={{width: '100%'}}
																key="btn-view"
																loading={this.state.loadingData}
																type="danger"
																htmlType="button"
																size="small"
																onClick={() => this.postDataWithDownload()}
															>
																{(!this.state.loadingData && <FileExcelOutlined />)}
																	Export
															</Button>
														</Col>
													</Row>
												</Col>
											</Row>
										</Form>

					        	<Table 
					        		title={() => 
					        			<Row>
					        				<Col lg={8} sm={24} xs={24}><span className="konten-judul">Total {this.state.pagination.total} record</span></Col>
											<Col lg={{span: 8, offset: 8}} sm={24} xs={24}>
												<Input.Search 
													size="small"
													placeholder="ketikkan pencarian" 
													onSearch={value => {
														this.setState({
															searchText: value
														}, () =>{
																this.getData();
														});
													}} 
													allowClear
												/>										
											</Col>
										</Row>					        			
					        		}
					        		bordered
					        		columns={this.state.columns} 
					        		dataSource={this.state.dataSource} 
					        		loading={this.state.loadingData} 
					        		scroll={{ x: 240, y: 450 }}
					        		size="small"
					        		pagination={this.state.pagination}
					        		onChange={this.handleTableChange}
					        	/>
					        </Card>
				        </Col>
				    </Row>
				</div>

				<Modal
	        title="Komentar"
	        centered
	        visible={this.state.visible}
	        // onOk={() => this.setVisible(false)}
	        onCancel={() => this.setVisible(false)}
	        width={1000}
	        footer={[]}
	      >
	      	{(this.state.errTitleModal!='' &&
						        	<Alert
								      message={this.state.errTitleModal}
								      description={JSON.stringify(this.state.errMsgModal)}
								      type="error"
								      showIcon
								      style={{
								      	marginBottom: 10
								      }}
								    />
					)}

	      	{(this.state.dataDetail !=null &&
	      		<Row>
	      			<Col span={4}>Jenis Tanaman</Col>
	      			<Col span={20}>{this.state.dataDetail.jenis_tanaman_nama}</Col>
	      			<Col span={4}>Konten</Col>
	      			<Col span={20} dangerouslySetInnerHTML={{__html: this.state.dataDetail.content}} />
	      			<Col span={24}>
	      				<b>{this.state.dataDetail.count_comment+" KOMENTAR"}</b>
	      			</Col>
	      		</Row>
	      	)}
	        {this.state.dataKomentar != null && this.state.dataKomentar.results.map((item, idx) => (
	        	<>
		        	<Divider orientation="left" orientationMargin="0">{item.user_nama != undefined ? item.user_nama : 'Nama User'}</Divider>
					    <p>
					    	<i>{moment(item.created_at).format("DD-MM-YYYY HH:mm:ss")}</i>
					    	<br />
					      {item.komentar}
					    </p>
				    </>
	        ))}
	      </Modal>


	      <Modal
	        title="Alasan Penolakan"
	        centered
	        visible={this.state.visible}
	        // onOk={() => this.setVisible(false)}
	        onCancel={() => this.setVisible(false)}
	        width={1000}
	        footer={[]}
	      >
	      	{(this.state.errTitleModal!='' &&
						        	<Alert
								      message={this.state.errTitleModal}
								      description={JSON.stringify(this.state.errMsgModal)}
								      type="error"
								      showIcon
								      style={{
								      	marginBottom: 10
								      }}
								    />
					)}
					<Row>
						<Col span={24}>
							<Form 
								layout="vertical"
								name="fketerangan"
							 	onFinish={this.handleSubmitKeterangan}
							  size="small"
							  ref={this.formRefKeterangan}
							>
								<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="ALASAN PEMBATALAN"
										            name="keterangan"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            <Input
										            	style={{textTransform: 'none'}}
										                placeholder="input alasan pembatalan"
										            />
										        </Form.Item>
										    </Col>
								</Row>
								<Row justify="end">
									        <Col style={{padding: 5}}>
									        	<Button 
													key="btn-simpan"
													loading={this.state.loadingButton}
													type="primary" 
													htmlType="submit"
													disabled={this.state.errComponent?true:false}
													size="small"
												>
													{(!this.state.loadingButton && <CheckCircleOutlined />)}
													Simpan
												</Button>
											</Col>
											<Col style={{padding: 5}}>
												
													<Button 
														type="danger"
														key="btn-cancel"
														disabled={this.state.loadingButton?true:false}
													    size="small"
													  onClick={() => this.setState({visible: false})}
													>
														<CloseCircleOutlined /> Cancel
													</Button>
												
										    </Col>
										</Row>
							</Form>
						</Col>
					</Row>

	      	
	      </Modal>



	      <Modal
	        title="DETAIL TRANSAKSI"
	        centered
	        visible={this.state.visibleDetail}
	        // onOk={() => this.setVisible(false)}
	        onCancel={() => this.setVisibleDetail(false)}
	        width={700}
	        footer={[]}
	      >
	      	{(this.state.errTitleModal!='' &&
						        	<Alert
								      message={this.state.errTitleModal}
								      description={JSON.stringify(this.state.errMsgModal)}
								      type="error"
								      showIcon
								      style={{
								      	marginBottom: 10
								      }}
								    />
					)}
					<Row>
						<Col span={24}>
								{(this.state.visibleDetail == true && this.state.dataDetailTransaksi != null &&
									this.state.dataDetailTransaksi.map((item, idx) => (
										<Row justify="space-between" style={{borderBottom: "1px solid #cccccc"}}>
											      <Col lg={6} sm={6} xs={6}>
											        	Produk
												    </Col>
												    <Col lg={18} sm={18} xs={18}>
											        	{item.produk_nama}
												    </Col>
												    <Col lg={6} sm={6} xs={6}>
											        	Harga Awal
												    </Col>
												    <Col lg={18} sm={18} xs={18}>
											        	{item.harga_awal.toLocaleString('ID')}
												    </Col>
												    <Col lg={6} sm={6} xs={6}>
											        	Harga Akhir
												    </Col>
												    <Col lg={18} sm={18} xs={18}>
											        	{item.harga_akhir.toLocaleString('ID')}
												    </Col>
												    <Col lg={6} sm={6} xs={6}>
											        	Qty
												    </Col>
												    <Col lg={18} sm={18} xs={18}>
											        	{item.quantity}
												    </Col>
												    <Col lg={6} sm={6} xs={6}>
											        	Sub Total
												    </Col>
												    <Col lg={18} sm={18} xs={18}>
											        	{(item.harga_akhir * item.quantity).toLocaleString('ID')}
												    </Col>

										</Row>
									))
								)}
								<Row justify="end">
											<Col style={{padding: 5}}>
												
													<Button 
														type="danger"
														key="btn-cancel"
													  size="small"
													  onClick={() => this.setState({visibleDetail: false})}
													>
														<CloseCircleOutlined /> Tutup
													</Button>
												
										    </Col>
										</Row>
						</Col>
					</Row>

	      	
	      </Modal>


	      <Modal
		        centered
		        visible={this.state.visible_image_focus}
		        // onOk={() => this.setVisible(false)}
		        onCancel={() => {
		        	this.setState({
		        		visible_image_focus: false,
		        		image_focus_url: null,
		        		focus_title: "",
		        	});
		        }}
		        width={700}
		        footer={[]}
		     >
		      	<div style={{textAlign: "center"}}>
		      		<h1>{this.state.focus_title}</h1>
		      		<br />
		      		<img src={this.state.image_focus_url} style={{width: "100%"}} />
		      	</div>
		     </Modal>

			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler
		}
	)(DataTransaksi);